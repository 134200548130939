import { useCallback, useContext, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Typography } from '@aq_mobile/ui-kit';
import {
  DualColorCircleIcon,
  ExitIcon,
  MoonIconUrlEncoded,
  SunIconUrlEncoded,
} from '@aq_mobile/ui-kit/icons';
import { BreakPoints, Z_INDEXES } from '@aq_mobile/ui-kit/utils';
import styled from '@emotion/styled';
import { Avatar, Flex, Layout, Switch } from 'antd';

import { Menu, MenuLogo } from '@/components';
import { NO_ICON } from '@/constants';
import useAppToken from '@/themes/useAppToken';
import { ThemeContext } from '@/utils/theme-context';

import { SiderMode, SiderProps } from './Sider.types';

const SiderStyled = styled(Layout.Sider, {
  shouldForwardProp: (prop) => !['headerBg', 'siderBg'].includes(prop),
})<{ headerBg?: string; siderBg?: string }>`
  overflow: hidden;
  border-bottom-right-radius: 16px;
  height: 100vh;
  height: 100svh;

  && {
    background-color: ${(props) =>
      props.headerBg ? props.headerBg : 'transparent'};
  }

  & .ant-layout-sider-children {
    border-top-right-radius: 16px;
    background-color: ${(props) => (props.siderBg ? props.siderBg : 'white')};
    width: 312px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  && {
    @media ${BreakPoints.mdMax} {
      position: fixed;
      height: 100vh;
      height: 100svh;
      left: 0;
      top: 0;
      bottom: 0;
      border-top-right-radius: 16px;
      z-index: ${Z_INDEXES.sider};
      box-shadow: 8px 0 20px 8px rgba(22, 23, 26, 0.1);
    }
  }
`;

const ButtonCollapseMobileStyled = styled(Button)`
  display: none;
  padding: 0 !important;
  width: 40px;
  height: 40px;
  @media ${BreakPoints.mdMax} {
    display: inline-block;
  }
`;

const NavLinkStyled = styled(NavLink)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  color: inherit;
  font-weight: 500;

  &::before {
    width: 0;
  }
`;

const StyledThemeSwitch = styled(Switch)`
  & .ant-switch-handle::before {
    font-size: 16px;
    content: url('${MoonIconUrlEncoded}');
  }
  &.ant-switch-checked .ant-switch-handle::before {
    content: url('${SunIconUrlEncoded}');
  }
`;

const FooterStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
`;

const FooterControlStyled = styled.div`
  width: 100%;
  display: flex;
  padding: 12.5px 50px;
  justify-content: space-between;
  align-items: center;
`;

const MenuContainerStyled = styled.div`
  padding-left: 36px;
  padding-right: 48px;
  flex: 1 1 auto;
  overflow: auto;
`;

const SubMenuStyled = styled.div`
  margin: 16px 0;
  padding: 16px 0 0;
  border-top: 1px solid #c0c6d6;
`;

const AppMenuHeaderStyled = styled.div`
  padding-block: 12px;
  padding-inline-start: 20px;
  & .ant-avatar {
    margin-inline-end: 8px;
  }
`;

/**
 * Компонент панели основного меню приложения
 */
function Sider({
  collapsed,
  collapsedType,
  onCollapse,
  items,
  appName,
  appIconLink,
  subItems,
}: SiderProps) {
  const { theme, setTheme } = useContext(ThemeContext);
  const [siderMode, setSiderMode] = useState<SiderMode>('desktop');
  const swipeableHandlers = useSwipeable({
    onSwipedLeft: () => onCollapse(!collapsed, collapsedType),
  });
  const { token } = useAppToken();
  const iconColor = token.colorPrimary;
  const { headerBg, siderBg } = token?.Layout || {
    headerBg: '#fff',
    siderBg: '#fff',
  };

  const isThemeSwitchOn = theme === 'light' ? true : false;

  // Поддержка автоматического скрытия меню в мобильном режиме.
  const handleMenuClick = useCallback(() => {
    if (siderMode === 'desktop') {
      return;
    }

    onCollapse(true, 'responsive');
  }, [onCollapse, siderMode]);

  const handleBreakpoint = useCallback(
    (broken: boolean) => {
      /** При пересечении брейкпойнта в указываем, что режим панели меню сменился. */
      setSiderMode(broken ? 'mobile' : 'desktop');

      // Если пересекли брейкпойнт в меньшую сторону и коллапс открыт, то закрываем.
      // Отмечаем, что коллапс закрыт по брейкпойнту
      if (broken && !collapsed) {
        onCollapse(true, 'responsive');
        return;
      }

      // Если пересекли брейкпойнт в большую сторону и коллапс закрыт, но закрыт от брейкпойнта
      // то открываем его. Если закрыт был вручную, то не делаем ничего.
      if (collapsed && collapsedType === 'responsive') {
        onCollapse(false, 'responsive');
        return;
      }
    },
    [collapsed, collapsedType, onCollapse],
  );

  const appIcon = useMemo(
    () => (appIconLink ? appIconLink : NO_ICON),
    [appIconLink],
  );

  return (
    <SiderStyled
      breakpoint="lg"
      collapsedWidth="0"
      width="312px"
      headerBg={headerBg}
      siderBg={siderBg}
      onBreakpoint={handleBreakpoint}
      trigger={null}
      collapsible
      collapsed={collapsed}
      {...swipeableHandlers}
    >
      <Flex
        justify="space-between"
        align="center"
        style={{
          height: '96px',
          paddingLeft: '50px',
          paddingRight: '12px',
          flex: '0 0 auto',
        }}
      >
        <MenuLogo hidden={collapsed} />
        <ButtonCollapseMobileStyled
          type="primary"
          icon={<CloseOutlined />}
          size="large"
          onClick={() => onCollapse(!collapsed, 'manual')}
        />
      </Flex>

      <MenuContainerStyled>
        <Menu items={items} onClick={handleMenuClick} />

        {!!subItems?.length && (
          <SubMenuStyled>
            {appName && (
              <AppMenuHeaderStyled>
                <Avatar size="small" src={appIcon} shape="square" />
                <Typography.TextS>{appName}</Typography.TextS>
              </AppMenuHeaderStyled>
            )}

            <Menu items={subItems} onClick={handleMenuClick} />
          </SubMenuStyled>
        )}
      </MenuContainerStyled>

      <FooterStyled>
        <FooterControlStyled>
          <NavLinkStyled to="/logout">
            <ExitIcon style={{ fontSize: '24px', color: iconColor }} />
            <span>Выйти</span>
          </NavLinkStyled>
        </FooterControlStyled>
        <FooterControlStyled>
          <span style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <DualColorCircleIcon
              style={{ fontSize: '24px', color: iconColor }}
            />
            <span style={{ fontWeight: 500 }}>Тема</span>
          </span>
          <StyledThemeSwitch
            checked={isThemeSwitchOn}
            onChange={(checked) => setTheme(checked ? 'light' : 'dark')}
          />
        </FooterControlStyled>
      </FooterStyled>
    </SiderStyled>
  );
}

export default Sider;
