import { useContext, useEffect } from 'react';

import { ServerError, useGetUserQuery } from '@/features/api';
import { getServerErrorDescriptions } from '@/features/api/api.utils';
import { NotificationContext } from '@/utils/notification-context';

/**
 * Возвращает информацию о пользователе системы по его идентификатору.
 */
function useUser(id?: number) {
  const notificationContext = useContext(NotificationContext);
  const { data, isLoading, error } = useGetUserQuery(id || 0, {
    skip: !Boolean(id),
  });
  useEffect(() => {
    if (!error) {
      return;
    }

    const errorMessages = getServerErrorDescriptions(
      error as unknown as ServerError,
    );

    notificationContext.showError(
      `Ошибка получения пользователя с идентификатором ${id}`,
      errorMessages,
    );
    console.error(
      `Ошибка получения пользователя с идентификатором ${id}`,
      errorMessages,
    );
  }, [error, notificationContext, id]);

  return {
    user: data,
    isLoading,
    error,
  };
}

export default useUser;
