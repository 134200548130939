import { useContext, useEffect } from 'react';

import {
  useReadNotificationMutation,
  useReadNotificationsMutation,
} from '@/features/api';
import { NotificationContext } from '@/utils/notification-context';

/** Функции для управления уведомлениями */
function useNotificationEdit() {
  const notificationContext = useContext(NotificationContext);
  const [
    readNotification,
    { isLoading: isReadNotificationLoading, error: readNotificationError },
  ] = useReadNotificationMutation();
  const [
    readNotifications,
    { isLoading: isReadNotificationsLoading, error: readNotificationsError },
  ] = useReadNotificationsMutation();

  useEffect(() => {
    if (!readNotificationError) {
      return;
    }

    notificationContext.showError('Ошибка при отметке уведомления прочитанным');
  }, [readNotificationError, notificationContext]);

  useEffect(() => {
    if (!readNotificationsError) {
      return;
    }

    notificationContext.showError(
      'Ошибка при отметке уведомлений прочитанными',
    );
  }, [readNotificationsError, notificationContext]);

  return {
    readNotification,
    readNotifications,
    isReadNotificationLoading,
    isReadNotificationsLoading,
    readNotificationError,
  };
}

export default useNotificationEdit;
