/** Текст описания ошибки протухшего токена. */
export const NO_ACCOUNT_ERROR_MESSAGE =
  'Given token not valid for any token type';

/**
 * Описывает форму данных ошибки при запросе.
 */
export type ErrorRequestData = {
  detail: string;
};

/**
 * Описывает структуру запроса обновления токена.
 * Если есть, тогда он аналогичен запросу пользователя.
 */
export type RefreshTokenResult = {
  access: string;
  refresh: string;
};
