import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ContentCard, FileInput } from '@aq_mobile/ui-kit';
import { fileTypeToMime } from '@aq_mobile/ui-kit/utils';
import { Checkbox, Form, Input } from 'antd';

import { RELEASE_NOTES_LENGTH, useStoreAPKEdit } from '@/features/storeApk';

type NewVersionForm = {
  file?: File;
  approved?: boolean;
  optional?: boolean;
  release_notes?: string;
};

/**
 * Страница загрузки новой версии магазина приложений
 */
export default function StoreNewVersion() {
  const navigate = useNavigate();
  const { uploadStoreAPK, isStoreAPKUploading } = useStoreAPKEdit();
  const [form] = Form.useForm<NewVersionForm>();

  const handleFormFinish = useCallback(
    async (formValues: NewVersionForm) => {
      await uploadStoreAPK({
        file: formValues.file!,
        approved: Boolean(formValues.approved),
        optional: Boolean(formValues.optional),
        release_notes: formValues['release_notes'] as string,
      })
        .unwrap()
        .then((result) => {
          navigate(`/store-updates/`);
        });
    },
    [navigate, uploadStoreAPK],
  );

  const handleCancel = useCallback(() => {
    navigate('/store-updates');
  }, [navigate]);

  return (
    <ContentCard>
      <ContentCard.Header title="Загрузка обновления" />
      <ContentCard.Body>
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          onFinish={handleFormFinish}
          disabled={isStoreAPKUploading}
          labelWrap
          colon={false}
          requiredMark={false}
          labelAlign="left"
          scrollToFirstError
        >
          <Form.Item wrapperCol={{ span: 24 }}>
            <Form.Item<NewVersionForm>
              name="approved"
              noStyle
              valuePropName="enabled"
            >
              <Checkbox
                style={{
                  marginInlineEnd: '2em',
                }}
              >
                Одобрено к публикации
              </Checkbox>
            </Form.Item>
            <Form.Item<NewVersionForm>
              name="optional"
              noStyle
              valuePropName="enabled"
            >
              <Checkbox>Не является обязательным</Checkbox>
            </Form.Item>
          </Form.Item>
          <Form.Item<NewVersionForm>
            label="Выберите .apk"
            name="file"
            rules={[
              {
                required: true,
                message: 'Пожалуйста, выберите файл релиза магазина приложений',
              },
            ]}
          >
            <FileInput accept={fileTypeToMime(['apk']).join(',')} />
          </Form.Item>
          <Form.Item<NewVersionForm>
            label="Описание изменений в текущей версии приложения"
            name="release_notes"
            rules={[
              {
                required: true,
                message:
                  'Пожалуйста, укажите описание изменений в текущей версии',
              },
            ]}
          >
            <Input.TextArea showCount maxLength={RELEASE_NOTES_LENGTH} />
          </Form.Item>
        </Form>
      </ContentCard.Body>

      <ContentCard.Footer
        buttons={
          <>
            <Button type="default" onClick={handleCancel}>
              Отменить
            </Button>
            <Button
              type="primary"
              onClick={form.submit}
              disabled={isStoreAPKUploading}
            >
              Сохранить
            </Button>
          </>
        }
      ></ContentCard.Footer>
    </ContentCard>
  );
}
