import { Category } from '@/features/category';

export type CategoryCardProps = {
  category: Category;
  onEdit?: (category: Category) => void;
  onDelete?: (id: number) => void;
  onActiveChange?: (id: number, isActive: boolean) => void;
  style?: React.CSSProperties;
  className?: string;
};

export const CATEGORY_SORT_DIRECTION_SETTING = 'categorySortDirection';
