import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, ContentCard } from '@aq_mobile/ui-kit';
import {
  dateToLocaleString,
  getFileName,
  strftimeToJsDate,
} from '@aq_mobile/ui-kit/utils';
import { Checkbox, Col, Form, Input, Row, Space, Spin } from 'antd';

import { ApkField } from '@/components';
import { NO_VALUE_SYMBOL } from '@/constants';
import {
  RELEASE_NOTES_LENGTH,
  StoreAPK,
  useStoreAPK,
  useStoreAPKEdit,
} from '@/features/storeApk';

type NewVersionForm = {
  release_notes?: string;
};

/**
 * Страница загрузки новой версии магазина приложений
 */
export default function StoreEdit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [apk, setApk] = useState<StoreAPK>();
  const { storeAPK, isStoreAPKLoading } = useStoreAPK(id!);
  const { updateStoreAPK, isStoreAPKUpdating } = useStoreAPKEdit();
  const [form] = Form.useForm<NewVersionForm>();

  useEffect(() => {
    // Когда появляется или обновляется storeAPK, делаем его
    // данные доступными для редактирования.
    if (!storeAPK) {
      return;
    }

    setApk(storeAPK);
  }, [storeAPK]);

  const handleFormFinish = useCallback(
    async (formValues: { [key: string]: unknown }) => {
      if (!apk) {
        return;
      }

      await updateStoreAPK({
        id: apk.id,
        approved: apk.approved,
        optional: apk.optional,
        release_notes: formValues['release_notes'] as string,
      }).unwrap();
      navigate('/store-updates');
    },
    [apk, navigate, updateStoreAPK],
  );

  const handleCancel = useCallback(() => {
    navigate('/store-updates');
  }, [navigate]);

  const apkName = apk ? getFileName(apk.file) : NO_VALUE_SYMBOL;

  const releaseDate = apk
    ? dateToLocaleString(strftimeToJsDate(apk.release_date))
    : NO_VALUE_SYMBOL;

  const isFormLocked = isStoreAPKLoading || isStoreAPKUpdating;

  return (
    <ContentCard>
      <ContentCard.Header title="Редактирование обновления" />
      <ContentCard.Body>
        <Spin spinning={isFormLocked}>
          {apk ? (
            <>
              <Row gutter={22} style={{ marginBlockEnd: 32 }}>
                <Col span={24}>
                  <Space wrap>
                    <Checkbox
                      checked={apk.approved}
                      onChange={(e) => {
                        setApk({
                          ...apk,
                          approved: e.target.checked,
                        });
                      }}
                    >
                      Одобрено к публикации
                    </Checkbox>
                    <Checkbox
                      checked={apk.optional}
                      onChange={(e) => {
                        setApk({
                          ...apk,
                          optional: e.target.checked,
                        });
                      }}
                    >
                      Не является обязательным
                    </Checkbox>
                  </Space>
                </Col>
              </Row>

              <Row gutter={22} style={{ marginBlockEnd: 32 }}>
                <Col xs={24} sm={24}>
                  <Space>
                    Файл:
                    <a href={apk.file}>{apkName}</a>
                  </Space>
                </Col>
              </Row>

              <Row
                gutter={[{ xs: 16, sm: 32, md: 64 }, 32]}
                style={{ marginBlockEnd: 32 }}
              >
                <Col xs={24} sm={12} md={8}>
                  <ApkField
                    title="Идентификатор приложения (android application ID)"
                    value={apk.app_id || NO_VALUE_SYMBOL}
                  />
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <ApkField
                    title="Название версии приложения (android application version name)"
                    value={apk.release_name || NO_VALUE_SYMBOL}
                  />
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <ApkField
                    title="Дата выпуска текущей версии приложения"
                    value={releaseDate || NO_VALUE_SYMBOL}
                  />
                </Col>
              </Row>
              <Row
                gutter={[{ xs: 16, sm: 32, md: 64 }, 32]}
                style={{ marginBlockEnd: 64 }}
              >
                <Col xs={24} sm={12} md={8}>
                  <ApkField
                    title="Код версии приложения (android application version code)"
                    value={apk.version || NO_VALUE_SYMBOL}
                  />
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <ApkField
                    title="Размер бинарного файла приложения в байтах"
                    value={apk.size || NO_VALUE_SYMBOL}
                  />
                </Col>
              </Row>

              <Form
                form={form}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ release_notes: apk.release_notes }}
                onFinish={handleFormFinish}
                labelWrap
                colon={false}
                requiredMark={false}
                labelAlign="left"
                scrollToFirstError
              >
                <Form.Item
                  label="Описание изменений в текущей версии приложения"
                  name="release_notes"
                  rules={[
                    {
                      required: true,
                      message:
                        'Пожалуйста, укажите описание изменений в текущей версии',
                    },
                  ]}
                >
                  <Input.TextArea showCount maxLength={RELEASE_NOTES_LENGTH} />
                </Form.Item>
              </Form>
            </>
          ) : (
            <Row>
              <Col>Информация об обновлении магазина не загружена.</Col>
            </Row>
          )}
        </Spin>
      </ContentCard.Body>
      <ContentCard.Footer
        buttons={
          <>
            <Button
              type="primary"
              onClick={form.submit}
              disabled={isFormLocked}
            >
              Сохранить
            </Button>
            <Button type="default" onClick={handleCancel}>
              Отменить
            </Button>
          </>
        }
      ></ContentCard.Footer>
    </ContentCard>
  );
}
