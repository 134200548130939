export const fontFamily = 'Golos';

export const themeSizes = {
  borderRadius: 8,
  borderRadiusLG: 16,
  fontSizeHeading1: 24,
  fontSizeHeading2: 16,
  fontSizeHeading3: 14,
  fontSizeHeading4: 14,
  fontSizeHeading5: 14,
  lineHeightHeading1: 1.4,
  lineHeightHeading2: 1.5,
  lineHeightHeading5: 1.4,
  fontWeightStrong: 500,
};

export const componentSizes = {
  token: {
    fontFamily: fontFamily,
  },
  components: {
    Button: {
      borderRadiusLG: 8,
      paddingXS: 14,
      paddingContentHorizontal: 32,
      controlOutlineWidth: 2,
    },
    Layout: {
      headerHeight: 96,
      headerPadding: 0,
    },
    Menu: {
      itemBorderRadius: 6,
      iconSize: 24,
      itemHeight: 48,
      activeBarBorderWidth: 0,
    },
    Card: {
      lineWidth: 0,
    },
    Table: {
      headerSplitColor: 'transparent',
      cellFontSize: 12,
    },
    Pagination: {
      borderRadius: 2,
      itemSize: 28,
    },
    Checkbox: {
      borderRadiusSM: 2,
    },
    Radio: {
      buttonPaddingInline: 15,
      lineWidth: 2,
      borderRadiusSM: 8,
    },
  },
};
