import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/features/store';

export const applicationSelector = createSelector(
  (state: RootState) => state.application,
  (application) => application,
);

export const isApplicationSelectedSelector = createSelector(
  (state: RootState) => state.application,
  (application) => Boolean(application.id || application.id === 0),
);
