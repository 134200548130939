import { settings } from '@aq_mobile/ui-kit/utils';

import { UserState } from '@/features/user';

/**
 * Вспомогательная функция, для сохранения данных пользователя.
 * ToDo: переделать авторизацию на HttpOnly cookie для большей безопасности.
 */
export function persistUser(user: UserState) {
  settings.set('user', user);
}

/**
 * Возвращает прежде сохраненного пользователя.
 * @returns возвращает объект UserState или null, если предыдущий логин не сохранен.
 */
export function getPersistedUser() {
  return settings.get<UserState | null>('user');
}

/**
 * Сбрасывает сохраненного пользователя.
 */
export function resetUser() {
  settings.set('user', null);
}
