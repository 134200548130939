import { mergeDeep } from '@aq_mobile/ui-kit/utils';
import { theme as antTheme } from 'antd';

import { componentSizes } from './theme-common';

export const theme = {
  token: {
    colorPrimary: '#3396ff',
    colorInfo: '#3396ff',
    colorBgLayout: '#101518',
    colorBgBase: '#1b222b',
    colorSuccess: '#00d856',
    colorError: '#f03333',
  },
  components: {
    Button: {
      defaultBg: '#4b5055',
    },
    Checkbox: {
      colorBorder: '#ededed',
    },
    Layout: {
      siderBg: '#1A2840',
      headerBg: '#1b222b',
    },
    Menu: {
      darkItemBg: 'transparent',
      itemBg: 'transparent',
      itemColor: '#ededed',
      itemSelectedBg: '#dfeeff',
      itemSelectedColor: '#1c1c1e',
    },
    Card: {
      colorBgContainer: '#1b222b',
    },
    Table: {
      colorBgContainer: '#161B20',
      bodySortBg: '#161B20',
      borderColor: '#252930',
      headerBg: '#1B222B',
      headerSortActiveBg: '#1B222B',
      headerColor: '#808192',
    },
    Radio: {
      buttonBg: '#282F37',
      buttonCheckedBg: '#000000',
      buttonCheckedBgDisabled: '#EDEDED',
      buttonCheckedColorDisabled: '#EDEDED',
      buttonColor: '#FFFFFFA6',
      buttonSolidCheckedActiveBg: '#000000',
      buttonSolidCheckedBg: '#000000',
      buttonSolidCheckedColor: '#EDEDED',
      buttonSolidCheckedHoverBg: '#161616',
      colorPrimaryHover: '#161616',
      colorPrimary: '#EDEDED',
      colorText: '#EDEDED',
      colorBorder: '#282F37',
    },
    Collapse: {
      headerBg: '#161B20',
      contentBg: '#000000',
      colorBorder: '#434242',
    },
    // Custom Components
    AppAddReleaseButton: {
      borderColor: '#EDEDED',
    },
    AppCard: {
      colorBgContainer: '#1b222b',
    },
    AppCategoryCard: {
      borderColor: '#474747',
      subtitleColor: '#949494',
      activeBackgroundColor: '#52C41A',
      inactiveBackgroundColor: '#EDEDED',
    },
    AppChip: {
      successColor: '#528E6A',
      successBackgroundColor: '#162312',
      errorColor: '#CC6F7F',
      errorBackgroundColor: '#581203',
      infoColor: '#001B39',
      infoBackgroundColor: '#5578A3',
      defaultColor: '#949494',
      defaultBackgroundColor: '#434242',
    },
    AppCommentModal: {
      borderColor: '#474747',
    },
    AppImageUpload: {
      descriptionColor: '#949494',
      enabledBorderColor: '#1D3348',
      disabledBorderColor: '#434242',
      enabledColor: '#3396ff',
      disabledColor: '#ffffff40',
    },
    AppImagePreview: {
      borderColor: '#1D3348',
      backgroundColor: '#2E333C',
    },
    AppNoImages: {
      borderColor: '#1D3348',
      backgroundColor: '#transparent',
      color: '#949494',
      iconSrc: '/images/lens-and-bubbles-dark.svg',
    },
    AppNotifications: {
      backgroundColor: '#2E333C',
      borderColor: '#474747',
      readIconColor: '#162312',
      hoverColor: '#323840',
      stateSuccessColor: '#00D856',
      stateErrorColor: '#ED6B6B',
      stateWarningColor: '#ECAF13',
      stateInfoColor: '#DFEEFF',
    },
    AppReviewCard: {
      backgroundColor:
        'linear-gradient(101deg, #1B222B 3.01%, rgba(13, 13, 13, 0.00) 102.88%)',
      emptyImageSrc: '/images/lens-and-bubbles-dark.svg',
      errorImageSrc: '/images/multiply-circle-fill-dark.svg',
    },
    AppReleaseCard: {
      backgroundColor: '#161B20',
    },
    AppSortDirection: {
      iconColor: '#949494',
    },
    AppSlider: {
      buttonBackgroundColor: '#000',
      buttonBorderColor: '#363636',
      buttonBoxShadowColor: 'rgba(0, 0, 0, 0.04)',
      buttonColor: '#EDEDED',
    },
    AppTypography: {
      secondaryColor: '#949494',
    },
  },
  algorithm: antTheme.darkAlgorithm,
};

const darkTheme = mergeDeep(componentSizes, theme);

export default darkTheme;
