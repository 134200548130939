import { useContext, useEffect } from 'react';

import {
  ServerError,
  useAppCreateMutation,
  useAppDeleteMutation,
} from '@/features/api';
import { getServerErrorDescriptions } from '@/features/api/api.utils';
import { APPLICATION_FIELDS } from '@/features/application';
import { NotificationContext } from '@/utils/notification-context';

/**
 * Хук для редактирования приложения.
 */
function useApplicationEdit() {
  const notificationContext = useContext(NotificationContext);
  const [
    deleteApplication,
    {
      isLoading: isApplicationDeleting,
      error: applicationDeleteError,
      isSuccess: isApplicationDeleteSuccess,
    },
  ] = useAppDeleteMutation();
  const [
    createApplication,
    {
      isLoading: isApplicationCreating,
      error: applicationCreateError,
      isSuccess: isApplicationCreateSuccess,
    },
  ] = useAppCreateMutation();

  useEffect(() => {
    if (!applicationDeleteError) {
      return;
    }

    notificationContext.showError(
      'Ошибка удаления приложения',
      'Произошла ошибка во время удаления приложения.',
    );
    console.error('Ошибка удаления приложения: ', applicationDeleteError);
  }, [applicationDeleteError, notificationContext]);

  useEffect(() => {
    if (!applicationCreateError) {
      return;
    }

    const errors = getServerErrorDescriptions(
      applicationCreateError as unknown as ServerError,
      APPLICATION_FIELDS,
    );

    notificationContext.showError(
      'Ошибка во время добавления приложения',
      errors,
    );

    console.error('Ошибка добавления приложения: ', applicationCreateError);
  }, [applicationCreateError, notificationContext]);

  return {
    createApplication,
    applicationCreateError,
    isApplicationCreateSuccess,
    isApplicationCreating,
    deleteApplication,
    applicationDeleteError,
    isApplicationDeleting,
    isApplicationDeleteSuccess,
  };
}

export default useApplicationEdit;
