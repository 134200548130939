import { useContext, useEffect } from 'react';

import {
  useDeleteScreenshotMutation,
  useLazyGetScreenshotQuery,
  useUploadScreenshotMutation,
} from '@/features/api';
import { NotificationContext } from '@/utils/notification-context';

/**
 * Хук для работы со скриншотами релиза.
 */
function useScreenshots() {
  const notificationContext = useContext(NotificationContext);
  const [
    getScreenshot,
    { isLoading: isScreenshotLoading, error: screenshotGetError },
  ] = useLazyGetScreenshotQuery();

  const [
    uploadScreenshot,
    { isLoading: isScreenshotUploading, error: screenshotUploadError },
  ] = useUploadScreenshotMutation();

  const [
    deleteScreenshot,
    { isLoading: isScreenshotDeleting, error: screenshotDeleteError },
  ] = useDeleteScreenshotMutation();

  useEffect(() => {
    if (!screenshotGetError) {
      return;
    }

    notificationContext.showError('Произошла ошибка при получении скриншота');
  }, [screenshotGetError, notificationContext]);

  useEffect(() => {
    if (!screenshotUploadError) {
      return;
    }

    notificationContext.showError(
      'Произошла ошибка при загрузке скриншота на сервер',
    );
  }, [notificationContext, screenshotUploadError]);

  /** true, когда производятся асинхронные операции над скриншотами */
  const isScreenshotsInProgress =
    isScreenshotLoading || isScreenshotUploading || isScreenshotDeleting;

  return {
    isScreenshotUploading,
    isScreenshotDeleting,
    isScreenshotsInProgress,
    getScreenshot,
    uploadScreenshot,
    deleteScreenshot,
    screenshotGetError,
    screenshotUploadError,
    screenshotDeleteError,
  };
}

export default useScreenshots;
