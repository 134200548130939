import { useGetAPKQuery } from '@/features/api';

/**
 * Хук, для получения информации о релизе
 * @param applicationId идентификатор приложения
 * @param apkId идентификатор релиза
 */
function useAPK(applicationId: string | undefined, apkId: number | undefined) {
  const isSkipRequest = apkId === undefined || applicationId === undefined;

  const {
    isLoading: isAPKLoading,
    data: apkData,
    error: getAPKError,
  } = useGetAPKQuery(
    { apkId: apkId || 0, applicationId: applicationId || '' },
    { skip: isSkipRequest },
  );

  return {
    isAPKLoading,
    apkData: isSkipRequest ? undefined : apkData,
    getAPKError,
  };
}

export default useAPK;
