import settingsReducer, { settingsSlice } from './settings.reducer';
import {
  selectApplicationsStatusFilter,
  selectApplicationsTypeFilter,
} from './settings.selectors';
export * from './settings.types';

const settingsActions = {
  ...settingsSlice.actions,
};

const settingsSelectors = {
  selectApplicationsStatusFilter,
  selectApplicationsTypeFilter,
};

export { settingsActions, settingsReducer, settingsSelectors };
