import {
  BooksIcon,
  DocumentAndGearIcon,
  HouseIcon,
  NoteIcon,
  PaintPaletteIcon,
  PlusIcon,
  SquareOnSquareIcon,
  TwoPersonIcon,
  WrenchAndScrewIcon,
} from '@aq_mobile/ui-kit/icons';

import { MenuItem } from '@/components';

/**
 * Доступные пункты меню системы.
 */
export const MENU_ITEMS: Array<MenuItem> = [
  {
    key: '/review',
    icon: <HouseIcon />,
    path: '/review',
    label: 'Начало',
    roles: ['moderator'],
  },
  {
    key: '/review-releases',
    icon: <SquareOnSquareIcon />,
    path: '/review-releases',
    label: 'В работе',
    roles: ['moderator'],
  },
  {
    key: '/store-updates',
    icon: <WrenchAndScrewIcon />,
    path: '/store-updates',
    label: 'Обновления',
    subPath: '/store-updates/new',
    subIcon: <PlusIcon />,
    roles: ['admin'],
  },
  {
    key: '/users',
    icon: <TwoPersonIcon />,
    path: '/users',
    label: 'Пользователи',
    roles: ['admin'],
  },
  {
    key: '/apps',
    icon: <SquareOnSquareIcon />,
    path: '/apps',
    label: 'Приложения',
    roles: ['admin', 'developer'],
  },
  {
    key: '/categories',
    icon: <BooksIcon />,
    path: '/categories',
    label: 'Категории',
    roles: ['admin'],
  },
];

/** Пункты меню выбранного приложения */
export const APP_MENU_ITEMS: Array<MenuItem> = [
  {
    key: '/apps/:id',
    icon: <PaintPaletteIcon />,
    path: '/apps/:id',
    label: 'Страница приложения',
  },
  {
    key: '/apps/:id/releases',
    icon: <DocumentAndGearIcon />,
    path: '/apps/:id/releases',
    label: 'Релизы',
    roles: ['developer'],
  },
  {
    key: '/apps/:id/releases',
    icon: <DocumentAndGearIcon />,
    path: '/apps/:id/releases-admin',
    label: 'Релизы',
    roles: ['admin'],
  },
  {
    key: '/apps/:id/release',
    icon: <DocumentAndGearIcon />,
    path: '/apps/:id/release',
    label: 'Релиз',
    roles: ['moderator'],
  },
  {
    key: '/apps/:id/history',
    icon: <NoteIcon />,
    path: '/apps/:id/history',
    label: 'История',
  },
];
