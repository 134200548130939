import { useNavigate } from 'react-router-dom';
import { Button, ContentCard, Typography } from '@aq_mobile/ui-kit';
import { MARGINS } from '@aq_mobile/ui-kit/utils';
import { Col, Row } from 'antd';

import { useAppSelector } from '@/features/hooks';
import { USER_ROLE_LABEL } from '@/features/user';
import {
  userMailSelector,
  userRoleSelector,
} from '@/features/user/user.selectors';

/**
 * Раздел профиля пользователя
 */
export default function Profile() {
  const navigate = useNavigate();
  const email = useAppSelector(userMailSelector);
  const role = useAppSelector(userRoleSelector);

  return (
    <ContentCard>
      <ContentCard.Header title="Пользователь" />
      <ContentCard.Body>
        <Row style={{ marginBlockStart: MARGINS.section }}>
          <Col xs={24} md={4}>
            <Typography.TextXS>Электронная почта:</Typography.TextXS>
          </Col>
          <Col md={18}>
            <Typography.TextS>{email}</Typography.TextS>
          </Col>
        </Row>
        <Row style={{ marginBlockStart: MARGINS.section }}>
          <Col xs={24} md={4}>
            <Typography.TextXS>Роль:</Typography.TextXS>
          </Col>
          <Col md={18}>
            <Typography.TextS>{USER_ROLE_LABEL[role]}</Typography.TextS>
          </Col>
        </Row>
      </ContentCard.Body>
      <ContentCard.Footer>
        <Button type="default" onClick={() => navigate('/dashboard')}>
          Ок
        </Button>
      </ContentCard.Footer>
    </ContentCard>
  );
}
