import type { AgeRating } from '@/features/ageRating/ageRating.types';

/** Таблица возрастного рейтинга */
export const ageRating: Array<AgeRating> = [
  {
    label: '0+',
    value: '0+',
  },
  {
    label: '6+',
    value: '6+',
  },
  {
    label: '12+',
    value: '12+',
  },
  {
    label: '16+',
    value: '16+',
  },
  {
    label: '18+',
    value: '18+',
  },
];
