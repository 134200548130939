import { Typography } from '@aq_mobile/ui-kit';
import styled from '@emotion/styled';

const TitleStyled = styled(Typography.Heading5, {
  shouldForwardProp: (prop) => prop !== 'hidden',
})<{ hidden: boolean }>`
  opacity: ${(props) => (props.hidden ? '0' : '1')};
  transition: opacity 0.3s 0s ease-in-out;
`;

/**
 * Текстовый логотип приложения
 */
export default function MenuLogo({
  hidden = false,
  className,
}: {
  hidden?: boolean;
  className?: string;
}) {
  return (
    <>
      <TitleStyled hidden={hidden} className={className}>
        AQUARIUS CONSOLE
      </TitleStyled>
    </>
  );
}
