import { useContext, useEffect } from 'react';

import { ServerError, useGetUsersQuery } from '@/features/api';
import { getServerErrorDescriptions } from '@/features/api/api.utils';
import { NotificationContext } from '@/utils/notification-context';

/**
 * Возвращает список пользователей системы.
 */
function useUsers() {
  const notificationContext = useContext(NotificationContext);
  const { data, isLoading, error } = useGetUsersQuery();
  useEffect(() => {
    if (!error) {
      return;
    }

    const errorMessages = getServerErrorDescriptions(
      error as unknown as ServerError,
    );

    notificationContext.showError(
      'Ошибка получения списка пользователей',
      errorMessages,
    );
    console.error('Ошибка получения списка пользователей: ', errorMessages);
  }, [error, notificationContext]);

  const users = data?.results ? data.results : [];

  return {
    users,
    isLoading,
    error,
  };
}

export default useUsers;
