import { createApi } from '@reduxjs/toolkit/query/react';

import { APK } from '@/features/apk';
import { ApplicationInfo } from '@/features/application';
import { baseQuery } from '@/features/baseQuery';
import { Category } from '@/features/category';
import { ChangelogRecord } from '@/features/changelog';
import { Release, ReleaseState } from '@/features/release';
import { Screenshot } from '@/features/screenshots';
import { StoreAPK, StoreAPKUpload } from '@/features/storeApk';
import { User } from '@/features/user';

import {
  APKFileUploadRequest,
  APKRequest,
  AppDescription,
  AppDescriptionRequest,
  AppIcon,
  ApplicationImageDeleteRequest,
  ApplicationImageRequest,
  ApplicationImageUploadRequest,
  ApplicationsRecord,
  ChangelogRequest,
  CreateDescriptionRequest,
  CreateIconRequest,
  CreateReleaseRequest,
  DeleteReleaseRequest,
  DeviceGroup,
  EditReleaseStateRequest,
  GetReleaseRequest,
  GetReleaseStateRequest,
  NewApplicationRequest,
  NewApplicationResponse,
  PaginatedResponse,
  ReleaseStateRequest,
  TokenRequest,
  TokenResponse,
  UpdateCategoryRequest,
} from './api.types';

export const appConsoleApi = createApi({
  reducerPath: 'appConsoleApi',
  baseQuery: baseQuery,
  tagTypes: [
    'Screenshots',
    'Screenshot',
    'Application',
    'Releases',
    'Applications',
    'APKs',
    'Categories',
    'Store',
    'Notifications',
  ],
  endpoints: (builder) => ({
    login: builder.mutation<TokenResponse, TokenRequest>({
      query: (data) => ({
        url: 'token/',
        method: 'POST',
        body: data,
      }),
    }),
    getApps: builder.query<PaginatedResponse<ApplicationsRecord>, void>({
      query: () => ({
        url: 'admin/applications/',
        method: 'GET',
      }),
      providesTags: ['Applications'],
    }),
    getApp: builder.query<ApplicationInfo, string>({
      query: (id) => ({
        url: `admin/applications/${id}/`,
        method: 'GET',
      }),
      providesTags: ['Application'],
    }),
    appCreate: builder.mutation<NewApplicationResponse, NewApplicationRequest>({
      query: (data) => ({
        url: 'admin/applications/',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Applications'],
    }),
    appDelete: builder.mutation<any, string>({
      query: (appId) => ({
        url: `admin/applications/${appId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Applications'],
    }),
    getReview: builder.query<Release, void>({
      query: (appId) => ({
        url: `admin/applications/review/`,
        method: 'GET',
      }),
    }),
    getReviewReleases: builder.query<PaginatedResponse<Release>, void>({
      query: () => ({
        url: `admin/applications/review/my/`,
        method: 'GET',
      }),
      providesTags: ['Releases'],
    }),
    getReleases: builder.query<PaginatedResponse<Release>, string>({
      query: (appId) => ({
        url: `admin/applications/${appId}/releases/`,
        method: 'GET',
      }),
      providesTags: ['Releases'],
    }),
    getRelease: builder.query<Release, GetReleaseRequest>({
      query: ({ application_id: appId, id }) => ({
        url: `admin/applications/${appId}/releases/${id}/`,
        method: 'GET',
      }),
      providesTags: ['Releases'],
    }),
    createRelease: builder.mutation<Release, CreateReleaseRequest>({
      query: ({ application_id, ...restParams }) => ({
        url: `admin/applications/${application_id}/releases/`,
        method: 'POST',
        body: restParams,
      }),
      invalidatesTags: ['Applications', 'Releases'],
    }),
    deleteRelease: builder.mutation<any, DeleteReleaseRequest>({
      query: ({ application_id, id }) => ({
        url: `admin/applications/${application_id}/releases/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Releases'],
    }),
    approveRelease: builder.mutation<ReleaseState, ReleaseStateRequest>({
      query: ({ application_id, id, text }) => ({
        url: `admin/applications/${application_id}/releases/${id}/approve/`,
        method: 'POST',
        body: text ? { text: text } : {},
      }),
      invalidatesTags: ['Releases'],
    }),
    banRelease: builder.mutation<ReleaseState, ReleaseStateRequest>({
      query: ({ application_id, id, text }) => ({
        url: `admin/applications/${application_id}/releases/${id}/ban/`,
        method: 'POST',
        body: text ? { text: text } : {},
      }),
      invalidatesTags: ['Releases'],
    }),
    publishRelease: builder.mutation<ReleaseState, ReleaseStateRequest>({
      query: ({ application_id, id, text }) => ({
        url: `admin/applications/${application_id}/releases/${id}/publish/`,
        method: 'POST',
        body: text ? { text: text } : {},
      }),
      invalidatesTags: ['Applications', 'Releases'],
    }),
    rejectRelease: builder.mutation<ReleaseState, ReleaseStateRequest>({
      query: ({ application_id, id, text }) => ({
        url: `admin/applications/${application_id}/releases/${id}/reject/`,
        method: 'POST',
        body: text ? { text: text } : {},
      }),
      invalidatesTags: ['Releases'],
    }),
    sendForReviewRelease: builder.mutation<ReleaseState, ReleaseStateRequest>({
      query: ({ application_id, id, text }) => ({
        url: `admin/applications/${application_id}/releases/${id}/send_for_review/`,
        method: 'POST',
        body: text ? { text: text } : {},
      }),
      invalidatesTags: ['Applications', 'Releases'],
    }),
    takeForReviewRelease: builder.mutation<ReleaseState, ReleaseStateRequest>({
      query: ({ application_id, id, text }) => ({
        url: `admin/applications/${application_id}/releases/${id}/take_for_review/`,
        method: 'POST',
        body: text ? { text: text } : {},
      }),
      invalidatesTags: ['Releases'],
    }),
    editReleaseState: builder.mutation<ReleaseState, EditReleaseStateRequest>({
      query: ({ application_id, id, state }) => ({
        url: `admin/applications/${application_id}/releases/${id}/edit_state/`,
        method: 'POST',
        body: state,
      }),
      invalidatesTags: ['Releases', 'Applications'],
    }),
    getReleaseState: builder.query<ReleaseState, GetReleaseStateRequest>({
      query: ({ application_id, id }) => ({
        url: `admin/applications/${application_id}/releases/${id}/state/`,
        method: 'GET',
      }),
      providesTags: ['Releases'],
    }),
    getAppDescription: builder.query<AppDescription, AppDescriptionRequest>({
      query: ({ application_id, id }) => ({
        url: `admin/applications/${application_id}/descriptions/${id}/`,
        method: 'GET',
      }),
      providesTags: ['Application'],
    }),
    createAppDescription: builder.mutation<
      AppDescription,
      CreateDescriptionRequest
    >({
      query: ({ application_id, ...restParams }) => ({
        url: `admin/applications/${application_id}/descriptions/`,
        method: 'POST',
        body: restParams,
      }),
      invalidatesTags: ['Applications'],
    }),
    getIcon: builder.query<AppIcon, GetReleaseRequest>({
      query: ({ application_id, id }) => ({
        url: `admin/applications/${application_id}/icons/${id}/`,
        method: 'GET',
      }),
      providesTags: ['Application'],
    }),
    uploadIcon: builder.mutation<AppIcon, CreateIconRequest>({
      query: (data) => {
        const formData = new FormData();
        formData.append('application_id', data.application_id);
        formData.append('file', data.file);

        return {
          url: `admin/applications/${data.application_id}/icons/`,
          method: 'POST',
          body: formData,
          formData: true,
        };
      },
      invalidatesTags: ['Applications'],
    }),
    getScreenshot: builder.query<Screenshot, ApplicationImageRequest>({
      query: ({ applicationId, id }) => ({
        url: `admin/applications/${applicationId}/screenshots/${id}/`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) =>
        result ? [{ type: 'Screenshot', id: arg.id }] : [],
    }),
    uploadScreenshot: builder.mutation<
      Screenshot,
      ApplicationImageUploadRequest
    >({
      query: (data) => {
        const formData = new FormData();
        formData.append('device_type', data.device_type);
        formData.append('application_id', data.application_id.toString());
        formData.append('file', data.image);

        return {
          url: `admin/applications/${data.application_id.toString()}/screenshots/`,
          method: 'POST',
          body: formData,
          formData: true,
        };
      },
      invalidatesTags: ['Screenshots'],
    }),
    deleteScreenshot: builder.mutation<any, ApplicationImageDeleteRequest>({
      query: (params) => ({
        url: `admin/applications/${params.application_id}/screenshots/${params.id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) =>
        result ? [{ type: 'Screenshot', id: arg.id }, 'Screenshots'] : [],
    }),
    getAPKs: builder.query<PaginatedResponse<APK>, string>({
      query: (applicationId) => ({
        url: `admin/applications/${applicationId}/apk/`,
        method: 'GET',
      }),
      providesTags: ['APKs'],
    }),
    getAPK: builder.query<APK, APKRequest>({
      query: (params) => ({
        url: `admin/applications/${params.applicationId}/apk/${params.apkId}/`,
        method: 'GET',
      }),
      providesTags: ['APKs'],
    }),
    uploadAPK: builder.mutation<APK, APKFileUploadRequest>({
      query: (params) => {
        const formData = new FormData();
        formData.append('file', params.file);

        return {
          url: `admin/applications/${params.applicationId}/apk/`,
          method: 'POST',
          body: formData,
          formData: true,
        };
      },
      invalidatesTags: ['APKs'],
    }),
    deleteAPK: builder.mutation<any, APKRequest>({
      query: (params) => ({
        url: `admin/applications/${params.applicationId}/apk/${params.apkId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['APKs'],
    }),
    getCategories: builder.query<PaginatedResponse<Category>, void>({
      query: () => ({
        url: `admin/categories/`,
        method: 'GET',
      }),
      providesTags: ['Categories'],
    }),
    getCategory: builder.query<Category, number>({
      query: (id) => ({
        url: `admin/categories/${id}`,
        method: 'GET',
      }),
      providesTags: ['Categories'],
    }),
    addCategory: builder.mutation<
      Category,
      Omit<Category, 'id' | 'applications_count'>
    >({
      query: (params) => {
        const formData = new FormData();
        formData.append('name', params.name);
        formData.append('is_active', String(params.is_active));
        return {
          url: `admin/categories/`,
          method: 'POST',
          body: formData,
          formData: true,
        };
      },
      invalidatesTags: ['Categories', 'Applications'],
    }),
    deleteCategory: builder.mutation<any, number>({
      query: (id) => ({
        url: `admin/categories/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Categories', 'Applications', 'Application'],
    }),
    updateCategory: builder.mutation<Category, UpdateCategoryRequest>({
      query: (params) => {
        const formData = new FormData();
        if (params.name) {
          formData.append('name', params.name);
        }

        if ('is_active' in params) {
          formData.append('is_active', String(params.is_active));
        }

        return {
          url: `admin/categories/${params.id}/`,
          method: 'PATCH',
          body: formData,
          formData: true,
        };
      },
      invalidatesTags: ['Categories', 'Applications', 'Application'],
    }),
    getChangelog: builder.query<
      PaginatedResponse<ChangelogRecord>,
      ChangelogRequest
    >({
      query: (params) => ({
        url: `admin/releases-changelog/`,
        params,
        method: 'GET',
      }),
      providesTags: ['Releases'],
    }),
    getChangelogRecord: builder.query<ChangelogRecord, number>({
      query: (id) => ({
        url: `admin/releases-changelog/${id}/`,
        method: 'GET',
      }),
    }),
    getNotifications: builder.query<
      PaginatedResponse<ChangelogRecord>,
      ChangelogRequest
    >({
      query: (params) => ({
        url: `admin/releases-changelog/`,
        params,
        method: 'GET',
      }),
      providesTags: ['Notifications'],
    }),
    readNotification: builder.mutation<ChangelogRecord, number>({
      query: (id) => ({
        url: `admin/releases-changelog/${id}/read/`,
        method: 'POST',
        body: {},
      }),
      invalidatesTags: ['Notifications'],
    }),
    readNotifications: builder.mutation<void, Array<number>>({
      query: (ids) => ({
        url: `admin/releases-changelog/read_many/`,
        method: 'POST',
        body: { ids },
      }),
      invalidatesTags: ['Notifications'],
    }),
    storeList: builder.query<PaginatedResponse<StoreAPK>, void>({
      query: () => ({
        url: 'admin/store',
        method: 'GET',
      }),
      providesTags: ['Store'],
    }),
    storeGet: builder.query<StoreAPK, string | undefined>({
      query: (id) => ({
        url: `admin/store/${id}`,
        method: 'GET',
      }),
      providesTags: ['Store'],
    }),
    storeUpload: builder.mutation<StoreAPK, StoreAPKUpload>({
      query: (data) => {
        const formData = new FormData();
        if (data.approved !== undefined) {
          formData.append('approved', data.approved.toString());
        }

        if (data.optional !== undefined) {
          formData.append('optional', data.optional.toString());
        }

        if (data.release_notes) {
          formData.append('release_notes', data.release_notes);
        }

        if (data.file) {
          formData.append('file', data.file);
        }

        return {
          url: 'admin/store/',
          method: 'POST',
          body: formData,
          formData: true,
        };
      },
      invalidatesTags: ['Store'],
    }),
    storeUpdate: builder.mutation<StoreAPK, Partial<StoreAPK>>({
      query: (data) => {
        return {
          url: `/admin/store/${data.id}/`,
          method: 'PATCH',
          body: data,
          formData: true,
        };
      },
      invalidatesTags: ['Store'],
    }),
    storeDelete: builder.mutation<any, string>({
      query: (appId) => ({
        url: `admin/store/${appId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Store'],
    }),
    getUsers: builder.query<PaginatedResponse<User>, void>({
      query: () => ({
        url: `users/users/`,
        method: 'GET',
      }),
    }),
    getUser: builder.query<User, number>({
      query: (id) => ({
        url: `users/users/${id}/`,
        method: 'GET',
      }),
    }),
    getDeviceGroups: builder.query<PaginatedResponse<DeviceGroup>, void>({
      query: () => ({
        url: `/device-groups/`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useGetDeviceGroupsQuery,
  useAppCreateMutation,
  useAppDeleteMutation,
  useGetAppsQuery,
  useGetAppQuery,
  useGetReleaseQuery,
  useGetReleasesQuery,
  useGetReviewReleasesQuery,
  useGetReleaseStateQuery,
  useGetChangelogQuery,
  useLazyGetReviewQuery,
  useCreateReleaseMutation,
  useDeleteReleaseMutation,
  useApproveReleaseMutation,
  useBanReleaseMutation,
  usePublishReleaseMutation,
  useRejectReleaseMutation,
  useEditReleaseStateMutation,
  useSendForReviewReleaseMutation,
  useTakeForReviewReleaseMutation,
  useGetAppDescriptionQuery,
  useLazyGetAppDescriptionQuery,
  useCreateAppDescriptionMutation,
  useGetIconQuery,
  useUploadIconMutation,
  useLazyGetScreenshotQuery,
  useUploadScreenshotMutation,
  useDeleteScreenshotMutation,
  useGetAPKsQuery,
  useGetAPKQuery,
  useLazyGetAPKQuery,
  useDeleteAPKMutation,
  useUploadAPKMutation,
  useGetCategoriesQuery,
  useGetCategoryQuery,
  useAddCategoryMutation,
  useDeleteCategoryMutation,
  useUpdateCategoryMutation,
  useStoreGetQuery,
  useStoreListQuery,
  useStoreUploadMutation,
  useStoreUpdateMutation,
  useStoreDeleteMutation,
  useGetUsersQuery,
  useGetUserQuery,
  useGetNotificationsQuery,
  useReadNotificationMutation,
  useReadNotificationsMutation,
} = appConsoleApi;
