import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/features/store';

export const selectApplicationsStatusFilter = createSelector(
  (state: RootState) => state.settings,
  (settings) => {
    return settings.applicationsStatusFilter;
  },
);

export const selectApplicationsTypeFilter = createSelector(
  (state: RootState) => state.settings,
  (settings) => {
    return settings.applicationsTypeFilter;
  },
);
