import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Accordion,
  Button,
  CommentModal,
  ContentCard,
  NoImages,
  NoRelease,
  RadioGroup,
  Slider,
  Typography,
} from '@aq_mobile/ui-kit';
import { PhoneIcon, TabletLandscapeIcon } from '@aq_mobile/ui-kit/icons';
import {
  dateToLocaleString,
  fileSizeFormatter,
  MARGINS,
  strftimeToJsDate,
} from '@aq_mobile/ui-kit/utils';
import styled from '@emotion/styled';
import { Col, Image, Popconfirm, Row, Spin, SpinProps } from 'antd';

import { APKInfo, ApplicationCard } from '@/components';
import { useApplication } from '@/features/application';
import { useCategories } from '@/features/category';
import usePermission from '@/features/permissions/hooks/usePermission';
import {
  ReleaseStatus,
  useModifyReleaseState,
  useReleases,
} from '@/features/release';
import useAppToken from '@/themes/useAppToken';
import getDeviceScreenshots from '@/utils/getDeviceScreenshots';

const DescriptionStyled = styled.pre`
  margin: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: Golos;
`;

const SpinStyled = styled(Spin, {
  shouldForwardProp: (props) => props !== 'backgroundColor',
})<SpinProps & { backgroundColor: string }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${(props) => (props.spinning ? 1 : -1)};
  background-color: ${(props) => props.backgroundColor};
`;

const screenShotTypes = [
  {
    label: (
      <RadioGroup.RadioGroupItemWithIcon
        icon={<PhoneIcon />}
        label="Скриншоты для смартфона"
      />
    ),
    value: 'phone',
  },
  {
    label: (
      <RadioGroup.RadioGroupItemWithIcon
        icon={<TabletLandscapeIcon />}
        label="Скриншоты для планшета"
      />
    ),
    value: 'tablet',
  },
];

const SliderStyled = styled(Slider)`
  height: 355px;
`;

/**
 * Страница основной информации по приложению.
 */
function Application() {
  const navigate = useNavigate();
  const { id } = useParams();
  const application_id = String(id!);
  const {
    publishedRelease,
    isLoading: isReleaseLoading,
    isSuccess: isReleaseLoadingSuccess,
  } = useReleases(application_id);
  const release =
    publishedRelease?.releasestate.state === ReleaseStatus.published
      ? publishedRelease
      : null;
  const { ban, isBanLoading } = useModifyReleaseState();
  const {
    description,
    icon,
    apk,
    screenshots,
    release_notes,
    id: releaseId,
    category,
  } = release || {};
  const { isApplicationLoading, applicationData } =
    useApplication(application_id);
  const { categories, isCategoriesLoading } = useCategories();
  const { name, app_id } = applicationData || {};
  const [screenshotType, setScreenshotType] = useState<'phone' | 'tablet'>(
    'phone',
  );
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const canUnpublish = usePermission('releaseUnpublish');
  const canAddRelease = usePermission('releaseAdd');
  const { token } = useAppToken();
  const spinBg = token.AppCard.colorBgContainer;

  const isUnpublishAvailable = canUnpublish && Boolean(release);
  const isNoPublishedRelease = !release && isReleaseLoadingSuccess;

  const handleBan = useCallback(
    (comment?: string) => {
      if (!releaseId) {
        return;
      }

      ban({
        application_id: application_id,
        id: releaseId,
        text: comment,
      });
    },
    [application_id, ban, releaseId],
  );

  const handleGoToReleases = useCallback(() => {
    navigate('./releases');
  }, [navigate]);

  const appCardCategories = useMemo(() => {
    if (!category || !categories) {
      return [];
    }

    return categories.filter((c) => category.includes(c.id));
  }, [categories, category]);

  const descriptionParagraphs = useMemo(() => {
    return description?.description ? (
      <DescriptionStyled>{description.description}</DescriptionStyled>
    ) : (
      <Typography.Heading5 type="secondary">
        Описание приложения отсутствует
      </Typography.Heading5>
    );
  }, [description]);

  const releaseTemplate = useMemo(() => {
    if (!apk) {
      return [];
    }

    const releaseDate = dateToLocaleString(strftimeToJsDate(apk.release_date));
    const releaseLabel = `Релиз ${apk.release_name}`;
    const releaseSize = fileSizeFormatter(apk.size);

    return [
      {
        key: apk.id,
        label: releaseLabel,
        children: (
          <APKInfo
            applicationId={app_id}
            file={apk.file}
            version={apk.release_name}
            date={releaseDate}
            changes={release_notes}
            versionCode={apk.version}
            size={releaseSize}
          />
        ),
      },
    ];
  }, [apk, app_id, release_notes]);

  const phoneScreenshotsTemplate = useMemo(() => {
    const phoneScreenshots = getDeviceScreenshots(screenshots, 'phone');

    if (!phoneScreenshots.length) {
      return <NoImages title="Скриншоты для смартфона не предоставлены" />;
    }

    const slides = phoneScreenshots.map((screenshot) => ({
      key: screenshot.id,
      component: (
        <Image
          src={screenshot.file}
          height={'100%'}
          preview={{ toolbarRender: () => null }}
          style={{
            borderRadius: 16,
          }}
        />
      ),
    }));

    return (
      <Image.PreviewGroup preview={{ toolbarRender: () => null }}>
        <SliderStyled slides={slides} />
      </Image.PreviewGroup>
    );
  }, [screenshots]);

  const tabletScreenshotsTemplate = useMemo(() => {
    const tabletScreenshots = getDeviceScreenshots(screenshots, 'tablet');

    if (!tabletScreenshots.length) {
      return <NoImages title="Скриншоты для планшета не предоставлены" />;
    }

    const slides = tabletScreenshots.map((screenshot) => ({
      key: screenshot.id,
      component: (
        <Image
          src={screenshot.file}
          height={'100%'}
          preview={{ toolbarRender: () => null }}
          style={{
            borderRadius: 16,
          }}
        />
      ),
    }));

    return (
      <Image.PreviewGroup preview={{ toolbarRender: () => null }}>
        <SliderStyled slides={slides} />
      </Image.PreviewGroup>
    );
  }, [screenshots]);

  const screenshotsTemplate =
    screenshotType === 'phone'
      ? phoneScreenshotsTemplate
      : tabletScreenshotsTemplate;

  const unpublishTemplate = useMemo(() => {
    if (!isUnpublishAvailable) {
      return null;
    }

    return (
      <Popconfirm
        title="Подтвердите снятие с публикации"
        description={
          <>
            <Typography.ParagraphS>
              Приложение будет снято с публикации и не будет доступно в поиске.
            </Typography.ParagraphS>
            <Typography.ParagraphS>
              Для возвращения в публикацию, добавьте новый релиз приложения.
            </Typography.ParagraphS>
          </>
        }
        okText="Подтвердить"
        cancelText="Отмена"
        onConfirm={() => setIsCommentModalOpen(true)}
      >
        <Button type="primary">Снять с публикации</Button>
      </Popconfirm>
    );
  }, [isUnpublishAvailable]);

  const idPageLoading =
    isReleaseLoading ||
    isReleaseLoading ||
    isApplicationLoading ||
    isCategoriesLoading ||
    isBanLoading;

  const infoTemplate = useMemo(
    () => (
      <>
        <Row>
          <Col>
            <Typography.Heading5 style={{ marginBlockEnd: MARGINS.subtitle }}>
              Описание
            </Typography.Heading5>
          </Col>
        </Row>

        <Row>
          <Col>{descriptionParagraphs}</Col>
        </Row>

        <Row style={{ marginBlockStart: MARGINS.section }}>
          <Col>
            <Typography.Heading5 style={{ marginBlockEnd: MARGINS.subtitle }}>
              Графика
            </Typography.Heading5>
          </Col>
        </Row>

        <Row>
          <Col>
            <RadioGroup
              options={screenShotTypes}
              onChange={(e) => setScreenshotType(e.target.value)}
              value={screenshotType}
              style={{ marginBlockEnd: MARGINS.section }}
            />
          </Col>
        </Row>
        <Row style={{ marginBlockEnd: MARGINS.section }}>
          <Col span="24">{screenshotsTemplate}</Col>
        </Row>

        <Row style={{ marginBlockEnd: MARGINS.subtitle }}>
          <Col>
            <Typography.Heading5>Релиз</Typography.Heading5>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            {releaseTemplate.length ? (
              <Accordion items={releaseTemplate} />
            ) : (
              <Typography.Heading5
                type="secondary"
                style={{ textAlign: 'center' }}
              >
                На данный момент APK релизы отсутствуют
              </Typography.Heading5>
            )}
          </Col>
        </Row>
      </>
    ),
    [
      descriptionParagraphs,
      releaseTemplate,
      screenshotType,
      screenshotsTemplate,
    ],
  );

  const noPublishedReleaseTemplate = useMemo(
    () => (
      <NoRelease
        isAllowAdd={canAddRelease}
        onButtonClick={handleGoToReleases}
      />
    ),
    [canAddRelease, handleGoToReleases],
  );

  return (
    <>
      <ContentCard>
        <ContentCard.Header title="Информация" buttons={unpublishTemplate} />
        <ContentCard.Body
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            gap: 24,
          }}
        >
          <SpinStyled
            spinning={idPageLoading}
            backgroundColor={spinBg}
          ></SpinStyled>
          <ApplicationCard
            icon={icon?.file}
            name={name}
            categories={appCardCategories}
            rating={description?.rating}
          />
          {isNoPublishedRelease ? noPublishedReleaseTemplate : infoTemplate}
        </ContentCard.Body>
      </ContentCard>

      <CommentModal
        title="Снятие релиза"
        label="Причина снятия"
        open={isCommentModalOpen}
        onOk={(comment) => {
          handleBan(comment);
          setIsCommentModalOpen(false);
        }}
        onCancel={() => setIsCommentModalOpen(false)}
      />
    </>
  );
}

export default Application;
