export const RELEASE_NOTES_LENGTH = 500;

export type StoreAPK = {
  id: number | null;
  approved: boolean;
  optional?: boolean;
  image?: string | null;
  app_id: string;
  file: string;
  release_name: string;
  release_date: string;
  release_notes?: string;
  permissions?: Array<string>;
  version: number;
  size: number;
  developer: number | null;
};

/** Тип, использующийся при загрузке APK файла магазина */
export type StoreAPKUpload = Pick<
  StoreAPK,
  'approved' | 'optional' | 'release_notes'
> & { file: File | null };

/**
 * Имя настройки, в которой хранится колонка сортировки таблицы обновлений магазина приложений
 */
export const APP_STORE_SORT_COLUMN_SETTING = 'appStoreSortColumn';
