import { mergeDeep } from '@aq_mobile/ui-kit/utils';

import { componentSizes } from './theme-common';

export const theme = {
  token: {
    colorPrimary: '#007aff',
    colorInfo: '#007aff',
    colorBgLayout: '#f3f3f3',
  },
  components: {
    Button: {
      defaultBg: '#eaeaea',
    },
    Checkbox: {
      colorBorder: '#d9d9d9',
    },
    Layout: {
      siderBg: '#e0efff',
      headerBg: '#fff',
    },
    Menu: {
      darkItemBg: 'transparent',
      itemBg: 'transparent',
      itemColor: '#1c1c1e',
      itemSelectedBg: '#fff',
      itemSelectedColor: '#1c1c1e',
    },
    Switch: {
      colorText: '#007aff',
    },
    Card: {
      colorBgContainer: '#fff',
    },
    Table: {
      colorBgContainer: '#f9f9fc',
      bodySortBg: '#f9f9fc',
      borderColor: '#eeeef2',
      headerBg: '#fff',
      headerSortActiveBg: '#fff',
      headerColor: '#808192',
    },
    Radio: {
      buttonBg: '#ECECEC',
      buttonCheckedBg: '#FFFFFF',
      buttonCheckedBgDisabled: '#2A3037',
      buttonCheckedColorDisabled: '#2A3037',
      buttonColor: '#2A3037',
      buttonSolidCheckedActiveBg: '#FFFFFF',
      buttonSolidCheckedBg: '#FFFFFF',
      buttonSolidCheckedColor: '#1C1C1E',
      buttonSolidCheckedHoverBg: '#f7f6f6',
      colorPrimaryHover: '#ECECEC',
      colorPrimary: '#2A3037',
      colorText: '#2A3037',
      colorBorder: '#ECECEC',
    },
    Collapse: {
      headerBg: '#fafafa',
      contentBg: '#ffffff',
      colorBorder: '#D9D9D9',
    },
    // Custom components
    AppAddReleaseButton: {
      borderColor: '#D9D9D9',
    },
    AppCard: {
      colorBgContainer: '#fff',
    },
    AppImageUpload: {
      descriptionColor: '#636466',
      enabledBorderColor: '#DFEEFF',
      disabledBorderColor: '#D9D9D9',
      enabledColor: '#007aff',
      disabledColor: '#d9d9d9',
    },
    AppImagePreview: {
      borderColor: '#DFEEFF',
      backgroundColor: '#FAFAFA',
    },
    AppNoImages: {
      borderColor: '#DFEEFF',
      backgroundColor: '#FAFAFA',
      color: '#808192',
      iconSrc: '/images/lens-and-bubbles-light.svg',
    },
    AppNotifications: {
      backgroundColor: '#FFF',
      borderColor: '#F0F0F0',
      readIconColor: '#76EDB4',
      hoverColor: '#f5f5f5',
      stateSuccessColor: '#00BE4C',
      stateErrorColor: '#F03333',
      stateWarningColor: '#FEC32C',
      stateInfoColor: '#94C6FF',
    },
    AppCategoryCard: {
      borderColor: '#F0F0F0',
      subtitleColor: '#808192',
      activeBackgroundColor: '#52C41A',
      inactiveBackgroundColor: '#D9D9D9',
    },
    AppCommentModal: {
      borderColor: '#F0F0F0',
    },
    AppChip: {
      successColor: '#467458',
      successBackgroundColor: '#C0FDD8',
      errorColor: '#8A564B',
      errorBackgroundColor: '#FDCBC0',
      infoColor: '#334A64',
      infoBackgroundColor: '#DFEEFF',
      defaultColor: '#1C1C1E',
      defaultBackgroundColor: '#E3E6E9',
    },
    AppReviewCard: {
      backgroundColor:
        'linear-gradient(101deg, #FFF 3.01%, rgba(255, 255, 255, 0.00) 102.88%)',
      emptyImageSrc: '/images/lens-and-bubbles-light.svg',
      errorImageSrc: '/images/multiply-circle-fill-light.svg',
    },
    AppReleaseCard: {
      backgroundColor: '#FAFAFA',
    },
    AppSortDirection: {
      iconColor: '#808192',
    },
    AppSlider: {
      buttonBackgroundColor: '#FFF',
      buttonBorderColor: '#D9D9D9',
      buttonBoxShadowColor: 'rgba(0, 0, 0, 0.04)',
      buttonColor: '#2A3037',
    },
    AppTypography: {
      secondaryColor: '#808192',
    },
  },
};

const lightTheme = mergeDeep(componentSizes, theme);

export default lightTheme;
