import { useContext, useEffect, useMemo } from 'react';
import { valueExists } from '@aq_mobile/ui-kit/utils';

import { ServerError, useGetReleasesQuery } from '@/features/api';
import { getServerErrorDescriptions } from '@/features/api/api.utils';
import { ReleaseStatus } from '@/features/release';
import { NotificationContext } from '@/utils/notification-context';

/**
 * Хук, возвращающий опубликованный релиз (если он есть)
 * и релиз на основе которого создается новый (также если есть).
 * @param appId идентификатор приложения.
 */
function useReleases(appId?: string) {
  const notificationContext = useContext(NotificationContext);
  const { data, isLoading, isSuccess, error } = useGetReleasesQuery(
    appId ?? '',
    {
      skip: !valueExists(appId),
    },
  );

  useEffect(() => {
    if (!error) {
      return;
    }

    const errorMessages = getServerErrorDescriptions(
      error as unknown as ServerError,
    );

    notificationContext.showError(
      'Произошла ошибка при запросе релизов приложения',
      errorMessages,
    );
  }, [error, notificationContext]);

  let publishedRelease = useMemo(() => {
    if (!data) {
      return undefined;
    }

    return data.results.find((release) => {
      return [ReleaseStatus.published, ReleaseStatus.banned].includes(
        release.releasestate.state,
      );
    });
  }, [data]);

  let editingRelease = useMemo(() => {
    // отсутствуют релизы.
    if (!data?.results.length) {
      return null;
    }

    const filteredReleases = data.results.filter(({ releasestate }) => {
      return ![ReleaseStatus.published, ReleaseStatus.banned].includes(
        releasestate.state,
      );
    });

    if (filteredReleases.length) {
      return filteredReleases[0];
    }

    return null;
  }, [data]);

  let reviewRelease = useMemo(() => {
    if (!data) {
      return undefined;
    }

    // отсутствуют релизы.
    if (!data.results.length) {
      return null;
    }

    const release = data.results.find(
      ({ releasestate }) => releasestate.state === ReleaseStatus.onReview,
    );

    return release;
  }, [data]);

  return {
    /** Список всех релизов приложения */
    releases: data?.results || [],
    /** Опубликованный релиз.
     * Данный релиз может быть как в статусе "Опубликован" так и "Запрещен",
     * что означает, что релиз был в какой-то момент опубликован в каталоге приложений,
     * но в последствии был снят.
     * Если у пользователя на данный момент редактируемого релиза, он имеет возможность создать его от данного релиза.
     */
    publishedRelease,
    /** Редактируемый релиз (может отсутствовать).
     * Релиз, который можно редактировать пользователю.
     * В текущем виде такой релиз может быть только один.
     * Как только этот релиз будет взят в рассмотрение модератором, отредактировать его будет нельзя.
     * После публикации, у пользователя будет возможность создать новый релиз для редактирования,
     * на основе опубликованного.
     */
    editingRelease,
    /**
     * Релиз, находящийся на ревью модератора
     * Данный релиз, если имеется, отображается в разделе "Релиз" у модератора.
     * Такой URL существует.
     */
    reviewRelease,
    isLoading,
    isSuccess,
    error,
  };
}

export default useReleases;
