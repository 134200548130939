/**
 * Возвращает true, если строка value содержит подстроку filter.
 */
export function checkFilterMatch(value: string, filter: string) {
  return value.toLocaleLowerCase().includes(filter.toLocaleLowerCase());
}

/**
 * Возвращает отфильтрованный массив, относительно указанного имени поля и строки поиска.
 * @param ary массив для фильтрации
 * @param searchTerm подстрока для поиска
 * @param propName имя поля (по умолчанию 'name')
 */
function filterByTerm<T extends object>(
  ary: Array<T>,
  searchTerm: string,
  propName: string | Array<string> = 'name',
) {
  return ary.filter((obj) => {
    if (!Array.isArray(propName)) {
      const checkValue = (obj as any)[propName];

      if (typeof checkValue === 'string') {
        return checkFilterMatch(checkValue, searchTerm);
      }
    }

    let propValue = obj;
    let isNotFound = false;

    for (const key of propName) {
      if (key in propValue) {
        propValue = (propValue as any)[key];
        continue;
      }

      isNotFound = true;
      break;
    }

    if (isNotFound) {
      return false;
    }

    if (typeof propValue === 'string') {
      return checkFilterMatch(propValue as string, searchTerm);
    }

    return false;
  });
}

export default filterByTerm;
