import { useContext, useEffect } from 'react';

import { ServerError } from '@/features/api';
import { getServerErrorDescriptions } from '@/features/api/api.utils';
import { useReleases } from '@/features/release';
import { NotificationContext } from '@/utils/notification-context';

/**
 * Используется для создания нового или редактирования предыдущего релиза.
 *
 * Возвращает релиз, на основе которого, будет создан новый.
 * При сохранении новый релиз заменяет предыдущий, если такой был.
 *
 * Если предыдущего релиза не существует то релиз будет создан с нуля.
 */
function useEditingRelease(appId: string) {
  const notificationContext = useContext(NotificationContext);
  const { editingRelease, publishedRelease, isLoading, isSuccess, error } =
    useReleases(appId);

  useEffect(() => {
    if (!error) {
      return;
    }

    const messages = getServerErrorDescriptions(
      error as unknown as ServerError,
    );
    notificationContext.showError(
      'Произошла ошибка при получении данных о предыдущем релизе',
      messages,
    );
  }, [notificationContext, error]);

  return {
    editingRelease: editingRelease ?? publishedRelease,
    isLoading,
    /** True, если загрузка завершена успешно. Не обязательно, что есть данные предыдущего релиза */
    isSuccess,
    error,
  };
}

export default useEditingRelease;
