export type ApplicationState = {
  id: number | null;
  name: string | null;
};

export type ApplicationInfo = {
  id: number;
  app_id: string;
  categories: Array<number>;
  name: string;
};

/**
 * Поля приложения и их названия.
 */
export const APPLICATION_FIELDS = {
  name: 'Название приложения',
  approved: 'Одобрено',
  categories: 'Категории',
  description: 'Описание',
  device_group: 'Группа устройств',
  icon: 'Иконка приложения',
  is_system_app: 'Системное',
  rating: 'Возрастной рейтинг приложения',
};

/**
 * Имя настройки, в которой хранится колонка сортировки таблицы приложений
 */
export const APPLICATIONS_SORT_COLUMN_SETTING = 'applicationsSortColumn';
