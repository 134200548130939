import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Chip,
  CommentModal,
  ContentCard,
  Typography,
} from '@aq_mobile/ui-kit';
import { Form, Input, Select, Spin, Tooltip } from 'antd';

import { ReleaseStateModal, ScreenshotsInput } from '@/components';
import { ServerError, useGetDeviceGroupsQuery } from '@/features/api';
import { getServerErrorDescriptions } from '@/features/api/api.utils';
import { RELEASE_NOTES_LENGTH } from '@/features/apk';
import { useApplication } from '@/features/application';
import { useCategories } from '@/features/category';
import { useAppSelector } from '@/features/hooks';
import {
  RELEASE_STATUS_LABEL,
  ReleaseStatus,
  releaseStatusToChipType,
  releaseStatusToText,
  useModifyReleaseState,
  useRelease,
  useReleases,
} from '@/features/release';
import { Screenshot } from '@/features/screenshots';
import { rootSelectors } from '@/features/store';
import { ReleaseForm } from '@/routes/ReleaseNew/ReleaseNew.types';
import { NotificationContext } from '@/utils/notification-context';

import ReleaseNewApk from './ReleaseNew/ReleaseNewApk';
import ReleaseNewDescription from './ReleaseNew/ReleaseNewDescription';
import ReleaseNewIcon from './ReleaseNew/ReleaseNewIcon';

/**
 * Страница просмотра релиза приложения
 */
function Release() {
  const { id, releaseId } = useParams();
  const application_id = id!;
  const release_id = Number(releaseId!);
  const userRole = useAppSelector(rootSelectors.users.userRoleSelector);
  const navigate = useNavigate();
  const {
    release,
    releaseError,
    isLoading: isReleaseLoading,
  } = useRelease(application_id, release_id);
  const { editingRelease, isLoading: isEditingReleaseLoading } =
    useReleases(application_id);
  const {
    data: deviceGroups,
    isLoading: isDeviceGroupLoading,
    error: deviceGroupError,
  } = useGetDeviceGroupsQuery();
  const notificationContext = useContext(NotificationContext);
  const { isApplicationLoading, applicationData, applicationError } =
    useApplication(application_id);
  const { categories, isCategoriesLoading, categoriesError } = useCategories();
  const {
    sendForReview,
    editReleaseState,
    isSendForReviewLoading,
    isEditReleaseStateLoading,
  } = useModifyReleaseState();
  const [form] = Form.useForm<ReleaseForm>();
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [isStateModalOpen, setIsStateModalOpen] = useState(false);

  const isSendForReviewDisabled = useMemo(() => {
    /** Релиз, можно отправить на модерацию, если это редактируемый релиз
     * и он не находится на модерации уже. */
    if (!editingRelease || !release) {
      return true;
    }

    return !(
      release.id === editingRelease.id &&
      release.releasestate.state === ReleaseStatus.created
    );
  }, [editingRelease, release]);

  const isEditDisabled = useMemo(() => {
    /** Созданный релиз изменять нельзя, вместо этого создается новый.
     * Хотя для пользователя это выглядит, как изменение релиза, т.к.
     * используются данные последнего релиза.
     */

    if (!editingRelease || !release) {
      return true;
    }

    return !(
      editingRelease.id === release.id &&
      release.releasestate.state &&
      [
        ReleaseStatus.approved,
        ReleaseStatus.created,
        ReleaseStatus.sentForReview,
      ].includes(release.releasestate.state)
    );
  }, [editingRelease, release]);

  const statusChipProps = useMemo(() => {
    const state = release?.releasestate.state || ReleaseStatus.created;
    const type = releaseStatusToChipType(state);
    const label = releaseStatusToText(state);

    return { type, label };
  }, [release?.releasestate.state]);

  useEffect(() => {
    // Заполняем форму значениями предыдущего релиза
    // В качестве начальных значений, проставляем значения из предыдущего релиза.
    if (release === null || release === undefined) {
      return;
    }

    const phoneScreenshots: Array<Screenshot> = [];
    const tabletScreenshots: Array<Screenshot> = [];

    release.screenshots.forEach((screenshot) => {
      if (screenshot.device_type === 'phone') {
        phoneScreenshots.push(screenshot);
        return;
      }

      tabletScreenshots.push(screenshot);
    });

    const deviceGroup =
      release.description.device_group?.map((group) => group.id) || [];

    form.setFieldsValue({
      apk: release.apk.id,
      icon: release.icon.id,
      phoneScreenshots: phoneScreenshots,
      tabletScreenshots: tabletScreenshots,
      release_notes: release.release_notes,
      description: {
        description: release.description.description,
        device_group: deviceGroup,
        rating: release.description.rating,
      },
      category: release.category,
    });
  }, [application_id, form, release]);

  useEffect(() => {
    // Обработчик ошибок получения данных
    if (!applicationError && !categoriesError && !releaseError) {
      return;
    }

    navigate('..');
  }, [applicationError, categoriesError, navigate, releaseError]);

  useEffect(() => {
    // Обработчик ошибки при загрузке списка групп устройств
    if (!deviceGroupError) {
      return;
    }

    const errors = getServerErrorDescriptions(
      deviceGroupError as unknown as ServerError,
    );
    notificationContext.showError(
      'При загрузке информации о группе устройств',
      errors,
    );
    navigate('..');
  }, [deviceGroupError, navigate, notificationContext]);

  const handleCancel = useCallback(() => {
    setIsCommentModalOpen(false);
    navigate('..');
  }, [navigate]);

  const handleEdit = useCallback(() => {
    navigate('../new');
  }, [navigate]);

  /* Отправляет релиз на модерацию */
  const handleSendOnReview = useCallback(async () => {
    const id = Number(releaseId);

    if (isNaN(id)) {
      notificationContext.showError(
        'Отправка на модерацию',
        'Невозможно отправить релиз на модерацию, т.к. отсутствует числовой идентификатор релиза.',
      );
      return;
    }

    await sendForReview({
      application_id,
      id: id,
    });

    notificationContext.showSuccess('Релиз отправлен на модерацию');
    navigate('..');
  }, [application_id, navigate, notificationContext, releaseId, sendForReview]);

  /* Отменяет отправку релиза на модерацию */
  const handleCancelSendOnReview = useCallback(() => {
    setIsCommentModalOpen(false);

    if (releaseId === undefined) {
      return;
    }

    navigate(`../${releaseId}`);
  }, [navigate, releaseId]);

  /** Изменяет статус релиза (доступно администратору) */
  const handleStatusChange = useCallback(
    (state: ReleaseStatus) => {
      if (!releaseId) {
        return;
      }

      editReleaseState({
        id: Number(releaseId),
        application_id,
        state: {
          state,
        },
      })
        .unwrap()
        .then(() => {
          notificationContext.showSuccess(
            `Статус релиза изменен на ${RELEASE_STATUS_LABEL[state]}`,
          );
        });
    },
    [application_id, editReleaseState, notificationContext, releaseId],
  );

  const sendForReviewTooltip = isSendForReviewDisabled
    ? 'Данный релиз нельзя отправить на модерацию'
    : 'Отправить данные релиз на модерацию';

  const footerButtons = useMemo(() => {
    switch (userRole) {
      case 'developer':
        return (
          <>
            <Button
              type="primary"
              onClick={handleEdit}
              disabled={isEditDisabled}
            >
              Изменить
            </Button>

            <Tooltip title={sendForReviewTooltip}>
              <Button
                type="default"
                onClick={() => setIsCommentModalOpen(true)}
                disabled={isSendForReviewDisabled}
              >
                Отправить на модерацию
              </Button>
            </Tooltip>

            <Button type="text" danger onClick={handleCancel}>
              Отмена
            </Button>
          </>
        );
      case 'admin':
        return (
          <>
            <Button type="primary" onClick={() => setIsStateModalOpen(true)}>
              Управлять
            </Button>

            <Button type="default" onClick={handleCancel}>
              Назад
            </Button>
          </>
        );
      case 'moderator':
        return (
          <Button type="text" danger onClick={handleCancel}>
            Отмена
          </Button>
        );
    }
  }, [
    handleCancel,
    handleEdit,
    isEditDisabled,
    isSendForReviewDisabled,
    sendForReviewTooltip,
    userRole,
  ]);

  const selectedState = useMemo(
    () => release?.releasestate.state || ReleaseStatus.created,
    [release?.releasestate.state],
  );

  const isLoading =
    isApplicationLoading ||
    isCategoriesLoading ||
    isReleaseLoading ||
    isEditingReleaseLoading ||
    isEditReleaseStateLoading ||
    isDeviceGroupLoading ||
    isSendForReviewLoading;

  return (
    <>
      <ContentCard>
        <ContentCard.Header
          title="Релиз"
          chip={
            <>
              <Spin spinning={isApplicationLoading}>
                <Chip type="info">{applicationData?.name}</Chip>
                <Chip type={statusChipProps.type}>{statusChipProps.label}</Chip>
              </Spin>
            </>
          }
        />
        <ContentCard.Body>
          <Spin spinning={isLoading}>
            <Form
              form={form}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
              labelWrap
              colon={false}
              requiredMark={false}
              labelAlign="left"
              disabled={true}
            >
              <Form.Item wrapperCol={{ span: 24 }} name="apk">
                <ReleaseNewApk
                  applicationId={application_id}
                  appId={applicationData?.app_id}
                  disabled={true}
                />
              </Form.Item>
              <Form.Item
                label="Описание изменений в текущей версии приложения"
                name="release_notes"
              >
                <Input.TextArea showCount maxLength={RELEASE_NOTES_LENGTH} />
              </Form.Item>

              <Typography.Heading5
                style={{
                  marginBlockStart: 64,
                  marginBlockEnd: 48,
                }}
              >
                Информация о приложении
              </Typography.Heading5>

              <Form.Item<ReleaseForm>
                label="Категория приложения"
                name="category"
              >
                <Select
                  options={categories}
                  mode="multiple"
                  autoClearSearchValue
                  fieldNames={{
                    label: 'name',
                    value: 'id',
                  }}
                  disabled
                />
              </Form.Item>

              <ReleaseNewDescription deviceGroups={deviceGroups?.results} />

              <Typography.Heading5
                style={{
                  marginBlockStart: 64,
                  marginBlockEnd: 48,
                }}
              >
                Графика
              </Typography.Heading5>
              <Form.Item label="Иконка приложения" name="icon">
                <ReleaseNewIcon applicationId={application_id} disabled />
              </Form.Item>

              <Form.Item label="Скриншоты для телефона" name="phoneScreenshots">
                <ScreenshotsInput
                  applicationId={application_id}
                  deviceType="phone"
                  disabled
                />
              </Form.Item>

              <Form.Item
                label="Скриншоты для планшета"
                name="tabletScreenshots"
              >
                <ScreenshotsInput
                  applicationId={application_id}
                  deviceType="tablet"
                  disabled
                />
              </Form.Item>
            </Form>
          </Spin>
        </ContentCard.Body>
        <ContentCard.Footer buttons={footerButtons}></ContentCard.Footer>
      </ContentCard>

      <CommentModal
        title="Отправка на модерацию"
        label="Комментарий для модератора"
        open={isCommentModalOpen}
        onOk={handleSendOnReview}
        onCancel={handleCancelSendOnReview}
      />

      <ReleaseStateModal
        selectedState={selectedState}
        onOk={handleStatusChange}
        open={isStateModalOpen}
        onOpenChange={setIsStateModalOpen}
      />
    </>
  );
}

export default Release;
