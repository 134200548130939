import { useCallback, useMemo } from 'react';
import React from 'react';
import { Button, Typography } from '@aq_mobile/ui-kit';
import {
  SquareAndPencilIcon,
  ThreeDotsVerticalIcon,
  TrashIcon,
} from '@aq_mobile/ui-kit/icons';
import styled from '@emotion/styled';
import { Dropdown, MenuProps, Popconfirm, Tooltip } from 'antd';

import useAppToken from '@/themes/useAppToken';

import { CategoryCardProps } from './CategoryCard.types';

const CardStyled = styled.div<{ bg: string }>`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => (props.bg ? props.bg : '#fff')};
  border-radius: 8px;
`;

const CardHeaderStyled = styled.div<{ borderColor?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  border-color: ${(props) =>
    props.borderColor ? props.borderColor : '#F0F0F0'};
  border-bottom-width: 1px;
  border-bottom-style: solid;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex: 0 1 auto;
  padding: 16px 24px;
`;

const CardHeaderTitleStyled = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const CardHeaderMarkerStyled = styled.span<{ backgroundColor: string }>`
  display: inline-block;
  border-radius: 50%;
  background-color: ${(props) => props.backgroundColor};
  flex: 0 0 auto;
`;

const CardBodyStyled = styled.div`
  text-align: center;
  flex: 1 0 auto;
  padding: 8px 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
`;

const CardFooterStyled = styled.div<{ borderColor: string }>`
  display: flex;
  justify-content: space-between;
  text-align: center;
  flex: 1 0 auto;
  padding: 12px 0;
  border-color: ${(props) =>
    props.borderColor ? props.borderColor : '#F0F0F0'};
  border-top-width: 1px;
  border-top-style: solid;
`;

const CountStyled = styled.h3`
  text-align: center;
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 40px;
  line-height: 56px;
  margin: 0;
`;

const ButtonWrapper = styled.div<{ borderColor: string }>`
  flex: 1 1 auto;
  &:nth-of-type(2) {
    border-top-width: 0;
    border-right-width: 1px;
    border-bottom-width: 0;
    border-left-width: 1px;
    border-style: solid;
    border-color: ${(props) => props.borderColor};
    text-align: center;
  }
`;

/**
 * Карточка для списка категорий приложений
 */
function CategoryCard({
  category,
  onActiveChange,
  onDelete,
  onEdit,
  style,
  className,
}: CategoryCardProps) {
  const { token } = useAppToken();
  /** Hack: тип token.Card на данный момент
   * не содержит в описании типа свойства colorBgContainer */
  const cardBg = token.Card
    ? (token.Card as unknown as { colorBgContainer: string }).colorBgContainer
    : '#fff';

  const borderColor = token.AppCategoryCard.borderColor;
  const markerColor = category.is_active
    ? token.AppCategoryCard.activeBackgroundColor
    : token.AppCategoryCard.inactiveBackgroundColor;
  const markerTooltip = category.is_active
    ? 'Категория доступна'
    : 'Категория отключена';

  const menuItems: MenuProps['items'] = useMemo(
    () => [
      {
        key: '1',
        label: category.is_active ? 'Отключить' : 'Сделать доступной',
      },
    ],
    [category.is_active],
  );

  const handleMenuClick = useCallback(() => {
    onActiveChange?.(category.id, !category.is_active);
  }, [category.id, category.is_active, onActiveChange]);

  return (
    <CardStyled bg={cardBg} className={className} style={style}>
      <CardHeaderStyled borderColor={borderColor}>
        <CardHeaderTitleStyled>
          <Tooltip title={category.name}>
            <Typography.Heading5>{category.name}</Typography.Heading5>
          </Tooltip>
        </CardHeaderTitleStyled>
        <CardHeaderMarkerStyled backgroundColor={markerColor}>
          <Tooltip title={markerTooltip}>
            <div style={{ width: 6, height: 6 }}></div>
          </Tooltip>
        </CardHeaderMarkerStyled>
      </CardHeaderStyled>
      <CardBodyStyled>
        <CountStyled>{category.applications_count}</CountStyled>
        <Typography.ParagraphS type="secondary">
          Приложений в категории
        </Typography.ParagraphS>
      </CardBodyStyled>
      <CardFooterStyled borderColor={borderColor}>
        <ButtonWrapper borderColor={borderColor}>
          <Popconfirm
            title="Удаление категории"
            description="Удалить выбранную категорию?"
            okText="Удалить"
            cancelText="Отмена"
            onConfirm={() => onDelete?.(category.id)}
          >
            <Button icon={<TrashIcon />} type="text"></Button>
          </Popconfirm>
        </ButtonWrapper>
        <ButtonWrapper borderColor={borderColor}>
          <Button
            icon={<SquareAndPencilIcon />}
            type="text"
            onClick={() => onEdit?.(category)}
          ></Button>
        </ButtonWrapper>
        <ButtonWrapper borderColor={borderColor}>
          <Dropdown
            menu={{ items: menuItems, onClick: handleMenuClick }}
            placement="bottomRight"
          >
            <Button icon={<ThreeDotsVerticalIcon />} type="text"></Button>
          </Dropdown>
        </ButtonWrapper>
      </CardFooterStyled>
    </CardStyled>
  );
}

export default React.memo(CategoryCard);
