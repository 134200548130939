import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';

import { RootState } from '@/features/store';

import { RefreshTokenResult } from './baseQuery.types';
import {
  argsWithHeaders,
  checkIsTokenRefreshRequired,
} from './baseQuery.utils';

const mutex = new Mutex();
const baseQuery = fetchBaseQuery({ baseUrl: __BASE_API_URL__ });

/**
 * Возвращает клиент для для HTTP запросов,
 * с установленным базовым URL,
 * автоматической пере-аутентификацией
 * с повтором упавших запросов.
 */
const appConsoleBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // Мьютекс нужен, чтобы не создавать гонку запросов
  await mutex.waitForUnlock();

  const patchedArgs = argsWithHeaders(args, api);
  // Выполняем запрос
  let result = await baseQuery(patchedArgs, api, extraOptions);

  // Если результат запроса не является ошибкой, о необходимости обновить токен
  // просто возвращаем результат. Он будет обработан в другом месте.
  if (!checkIsTokenRefreshRequired(result)) {
    return result;
  }

  // Если мьютекс захвачен, это означает, что
  // какой-то из запросов обращается за refresh token-ом,
  // поэтому останавливаем текущий запрос
  if (mutex.isLocked()) {
    await mutex.waitForUnlock();
    result = await baseQuery(argsWithHeaders(args, api), api, extraOptions);
    return result;
  }

  // Захватываем мьютекс
  const release = await mutex.acquire();

  try {
    // Выполняем обновление токена
    const refreshToken = (api.getState() as RootState).user.refreshToken;

    const refreshResult = await baseQuery(
      {
        url: '/token/refresh/',
        method: 'POST',
        body: {
          refresh: refreshToken,
        },
      },
      api,
      extraOptions,
    );

    const refreshData = refreshResult.data as RefreshTokenResult;

    if (refreshData) {
      // Сохраняем новые данные токена
      // Используется тип action-а, вместо action creator,
      // это исправляет проблему с импортом зависимостей и использование объекта
      // до его создания.
      api.dispatch({
        type: 'user/refreshToken',
        payload: {
          accessToken: refreshData.access,
          refreshToken: refreshData.refresh,
        },
      });

      // Получим обновленные данные для хедеров.
      const updatedArgs = argsWithHeaders(args, api);
      // Выполняем оригинальный запрос
      result = await baseQuery(updatedArgs, api, extraOptions);
      return result;
    }

    // Если обновленный токен не получен, разлогиниваем пользователя
    api.dispatch({ type: 'user/logOut' });

    return result;
  } finally {
    // Отпускаем мьютекс независимо от результата
    release();
  }
};

export default appConsoleBaseQuery;
