import { createContext } from 'react';
import { NotificationInstance } from 'antd/es/notification/interface';

export type NotificationFunction = (
  message: string | React.ReactElement,
  description?: string | React.ReactElement | Array<string>,
) => void;

export type NotificationContextProps = {
  notificationApi: NotificationInstance | null;
  showError: NotificationFunction;
  showSuccess: NotificationFunction;
  showWarning: NotificationFunction;
};

export const NotificationContext = createContext<NotificationContextProps>({
  notificationApi: null,
  showError: (
    message: string | React.ReactElement,
    description?: string | React.ReactElement | Array<string>,
  ) => {},
  showSuccess: (
    message: string | React.ReactElement,
    description?: string | React.ReactElement | Array<string>,
  ) => {},
  showWarning: (
    message: string | React.ReactElement,
    description?: string | React.ReactElement | Array<string>,
  ) => {},
});
