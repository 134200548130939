import { Navigate } from 'react-router-dom';

import { useAppSelector } from '@/features/hooks';
import { rootSelectors } from '@/features/store';

/**
 * Начальная страница приложения.
 */
export default function Dashboard() {
  // В зависимости от роли пользователя перемещаем его на соответствующую корневую страницу
  const role = useAppSelector(rootSelectors.users.userRoleSelector);

  if (role === 'moderator') {
    return <Navigate to={'/review'} replace={true} />;
  }

  if (role === 'user') {
    return <Navigate to={'/users/profile'} replace={true} />;
  }

  return <Navigate to={'/apps'} replace={true} />;
}
