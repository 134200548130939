import { useContext, useEffect } from 'react';

import {
  ServerError,
  useStoreDeleteMutation,
  useStoreUpdateMutation,
  useStoreUploadMutation,
} from '@/features/api';
import { getServerErrorMessage } from '@/features/api/api.utils';
import { getAPKErrorMessage } from '@/features/apk/apk.utils';
import { NotificationContext } from '@/utils/notification-context';

/**
 * Функционал редактирования APK магазина приложений
 */
function useAPKEdit() {
  const notificationContext = useContext(NotificationContext);
  const [
    deleteStoreAPK,
    {
      isLoading: isStoreAPKDeleting,
      error: deleteStoreAPKError,
      isSuccess: isStoreAPKDeleteSuccess,
    },
  ] = useStoreDeleteMutation();

  const [
    updateStoreAPK,
    {
      isLoading: isStoreAPKUpdating,
      error: updateStoreAPKError,
      isSuccess: isStoreAPKUpdateSuccess,
    },
  ] = useStoreUpdateMutation();
  const [
    uploadStoreAPK,
    {
      isLoading: isStoreAPKUploading,
      error: uploadStoreAPKError,
      isSuccess: isStoreAPKUploadSuccess,
    },
  ] = useStoreUploadMutation();

  useEffect(() => {
    if (!deleteStoreAPKError) {
      return;
    }

    notificationContext.showError(
      'Ошибка удаления',
      'Произошла ошибка во время удаления релиза магазина приложений.',
    );
    console.error(
      'Ошибка удаления релиза магазина приложений: ',
      deleteStoreAPKError,
    );
  }, [deleteStoreAPKError, notificationContext]);

  useEffect(() => {
    if (!updateStoreAPKError) {
      return;
    }

    notificationContext.showError(
      'Ошибка обновления',
      'Произошла ошибка во время обновления релиза магазина приложений.',
    );
    console.error('Ошибка обновление релиза: ', updateStoreAPKError);
  }, [notificationContext, updateStoreAPKError]);

  useEffect(() => {
    if (!uploadStoreAPKError) {
      return;
    }

    const errors = getAPKErrorMessage(
      uploadStoreAPKError as unknown as ServerError,
    );
    const restErrors = getServerErrorMessage(errors.unknownErrors);

    const combinedErrors = [...errors.errorMessages, ...restErrors];

    notificationContext.showError(
      'Произошла ошибка при загрузке APK файла магазина приложений',
      combinedErrors,
    );
    console.error('Ошибка сохранения APK: ', uploadStoreAPKError);
  }, [notificationContext, uploadStoreAPKError]);

  const isStoreAPKInProgress =
    isStoreAPKDeleting || isStoreAPKUpdating || isStoreAPKUploading;

  return {
    deleteStoreAPK,
    isStoreAPKDeleting,
    deleteStoreAPKError,
    isStoreAPKDeleteSuccess,
    updateStoreAPK,
    isStoreAPKUpdating,
    updateStoreAPKError,
    isStoreAPKUpdateSuccess,
    uploadStoreAPK,
    isStoreAPKUploading,
    uploadStoreAPKError,
    isStoreAPKUploadSuccess,
    isStoreAPKInProgress,
  };
}

export default useAPKEdit;
