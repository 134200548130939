import { Device } from '@/features/deviceGroup';
import { Screenshot } from '@/features/screenshots';

/**
 * Возвращает скриншоты для конкретного типа устройств
 * илп пустой массив, если скриншоты для устройства отсутствуют.
 * @param screenshots массив всех скриншотов
 * @param deviceType тип устройства
 */
function getDeviceScreenshots(
  screenshots: Array<Screenshot> | undefined,
  deviceType: Device,
) {
  if (!screenshots) {
    return [];
  }

  return screenshots.filter(
    (screenshot) => screenshot.device_type === deviceType,
  );
}

export default getDeviceScreenshots;
