import { useCallback, useEffect, useState } from 'react';
import { ImageUpload } from '@aq_mobile/ui-kit';

import {
  Screenshot,
  SCREENSHOTS_MAX_COUNT,
  SCREENSHOTS_MAX_RESOLUTION,
  SCREENSHOTS_MAX_SIZE,
  SCREENSHOTS_MIN_COUNT,
  useScreenshots,
} from '@/features/screenshots';

import { ScreenshotsInputProps } from './ScreenshotsInput.type';

/**
 * Элемент формы для загрузки и отображения скриншотов.
 */
function ScreenshotsInput({
  applicationId,
  deviceType,
  value,
  onChange,
  disabled,
}: ScreenshotsInputProps) {
  const [internalValue, setInternalValue] = useState<Array<Screenshot>>();
  const { uploadScreenshot, isScreenshotsInProgress } = useScreenshots();

  /*
   * Сохраняем текущее значение в состоянии.
   * Это нужно для того, чтобы при загрузке сразу нескольких скриншотов
   * после того,  как загружен очередной, иметь актуальное состояние переменной value
   * иначе, оно будет равно значению value на момент вызова функции.
   */
  useEffect(() => {
    // Случай, когда состояние было установлено из компонента после загрузки изображения на сервер.
    if (internalValue === value) {
      return;
    }

    setInternalValue(value);
  }, [internalValue, value]);

  const screenshotUrls = value
    ? value.map((screenshot) => screenshot.file)
    : undefined;

  const handleDelete = useCallback(
    /**
     * Удаление скриншота происходит локально.
     * Сервер сам удалит скриншот, когда это будет необходимо.
     */
    (fileName: string) => {
      if (!value) {
        return;
      }

      const filteredValues = value.filter(
        (screenshot) => screenshot.file !== fileName,
      );

      // Если скриншотов больше нет,
      // устанавливаем значение в undefined, чтобы работало правило валидации required
      if (!filteredValues.length) {
        onChange?.(undefined);
        return;
      }

      onChange?.(filteredValues);
    },
    [onChange, value],
  );

  const handleSelect = useCallback(
    async (image: File) => {
      const result = await uploadScreenshot({
        application_id: applicationId,
        device_type: deviceType,
        image,
      }).unwrap();

      /**
       * Используем внутреннее состояние, чтобы корректно установить измененное значение.
       * Если загружается сразу несколько изображений, то переменная value в асинхронном
       * методе может иметь устаревшее значение. Чтобы гарантированно получить
       * актуальное значение используется внутреннее состояние.
       */
      setInternalValue((internalValue) => {
        const updatedValue = internalValue
          ? [...internalValue, result]
          : [result];
        onChange?.(updatedValue);

        return updatedValue;
      });
    },
    [applicationId, deviceType, onChange, uploadScreenshot],
  );

  return (
    <ImageUpload
      minCount={SCREENSHOTS_MIN_COUNT}
      maxCount={SCREENSHOTS_MAX_COUNT}
      fileType={['png', 'jpg']}
      maxResolution={SCREENSHOTS_MAX_RESOLUTION}
      maxSize={SCREENSHOTS_MAX_SIZE}
      value={screenshotUrls}
      onDelete={handleDelete}
      onSelect={handleSelect}
      isLoading={isScreenshotsInProgress}
      disabled={disabled}
    />
  );
}

export default ScreenshotsInput;
