const packageIdPattern = /^([A-Za-z]{1}[A-Za-z\d_]*\.)+[A-Za-z][A-Za-z\d_]*$/;

/**
 * Возвращает true, если переданная строка соответствует
 * паттерну Android Package name.
 */
function validateAndroidPackageId(value: string) {
  if (!value) {
    return false;
  }

  return packageIdPattern.test(value);
}

export default validateAndroidPackageId;
