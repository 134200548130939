import { useCallback, useMemo, useState } from 'react';
import {
  ContentCard,
  Dropdown,
  PaginationTotal,
  Table,
  Typography,
} from '@aq_mobile/ui-kit';
import { MagnifyingGlassIcon, PlusIcon } from '@aq_mobile/ui-kit/icons';
import { goToURL } from '@aq_mobile/ui-kit/utils';
import {
  Button,
  Col,
  Input,
  Modal,
  Row,
  Space,
  Spin,
  TableColumnsType,
} from 'antd';
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from 'antd/es/table/interface';
import localeRU from 'antd/locale/ru_RU';

import {
  User,
  USER_ROLE_ITEMS,
  USER_ROLE_LABEL,
  UserRole,
  USERS_SORT_COLUMN_SETTING,
  useUsers,
} from '@/features/user';
import { checkFilterMatch } from '@/utils/filterByTerm';
import {
  getSortColumnSettings,
  setSortColumnSettings,
} from '@/utils/sortColumnSettings';

const PAGE_SIZE = 12;

const columnsTemplate: TableColumnsType<User> = [
  {
    key: 'email',
    dataIndex: 'email',
    title: 'Адрес электронной почты',
    defaultSortOrder: getSortColumnSettings(
      USERS_SORT_COLUMN_SETTING,
      'email',
      'ascend',
    ),
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: (a, b) => a.email.localeCompare(b.email),
  },
  {
    key: 'role',
    dataIndex: 'role',
    title: 'Роль',
    defaultSortOrder: getSortColumnSettings(USERS_SORT_COLUMN_SETTING, 'role'),
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: (a, b) => {
      return a.role.toLocaleUpperCase() > b.role.toLocaleUpperCase() ? 1 : -1;
    },
    render: (role: UserRole) => USER_ROLE_LABEL[role],
  },
];

/** Раздел со списком пользователей */
export default function Users() {
  const { users, isLoading } = useUsers();
  const [currentTablePage, setCurrentTablePage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [textFilter, setTextFilter] = useState('');
  const [roleFilter, setRoleFilter] = useState<{ key: string; label: string }>(
    USER_ROLE_ITEMS[USER_ROLE_ITEMS.length - 1],
  );

  const tableData = useMemo(() => {
    if (!users.length) {
      return [];
    }

    let filteredUsers = users;
    if (roleFilter.key !== 'all') {
      filteredUsers = users.filter(
        (user) => user.role.toString() === roleFilter.key,
      );
    }

    if (!textFilter) {
      return filteredUsers;
    }

    filteredUsers = users.filter((user) => {
      const isEmailMatch = checkFilterMatch(user.email, textFilter);
      if (isEmailMatch) {
        return true;
      }

      const isNameMatch = checkFilterMatch(user.first_name, textFilter);
      if (isNameMatch) {
        return true;
      }

      const isLastNameMatch = checkFilterMatch(user.last_name, textFilter);
      if (isLastNameMatch) {
        return true;
      }

      return false;
    });

    return filteredUsers;
  }, [roleFilter.key, textFilter, users]);

  const handleNewButtonClick = useCallback(() => setIsModalOpen(true), []);
  const handleGoToAuthService = useCallback(() => {
    setIsModalOpen(false);
    goToURL(__AUTH_SERVICE_ADDRESS__);
  }, []);
  // Поддержка смены направления и колонки сортировки
  const handleTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<User> | SorterResult<User>[],
    ) => {
      if (Array.isArray(sorter) || !sorter.columnKey) {
        return;
      }

      if (['email', 'role'].includes(sorter.columnKey.toString())) {
        setSortColumnSettings(
          USERS_SORT_COLUMN_SETTING,
          sorter.columnKey.toString(),
          sorter.order ?? null,
        );
      }
    },
    [],
  );

  return (
    <>
      <ContentCard>
        <ContentCard.Header
          title="Пользователи"
          buttons={
            <Button
              type="primary"
              icon={<PlusIcon />}
              onClick={handleNewButtonClick}
            >
              Добавить пользователя
            </Button>
          }
        />
        <ContentCard.Body>
          <Row gutter={24} align={'middle'} style={{ marginBottom: 8 }}>
            <Col>
              <Input
                value={textFilter}
                onChange={(e) => setTextFilter(e.target.value)}
                placeholder="Поиск"
                suffix={<MagnifyingGlassIcon />}
                allowClear
              />
            </Col>
            <Col flex="auto"></Col>
            <Col>
              <Space>
                <Typography.TextS>Роль:</Typography.TextS>
                <Dropdown
                  items={USER_ROLE_ITEMS}
                  selectedText={roleFilter.label}
                  onSelect={setRoleFilter}
                />
              </Space>
            </Col>
            <Col>
              <PaginationTotal
                currentPage={currentTablePage}
                pageSize={PAGE_SIZE}
                total={tableData.length || 0}
              />
            </Col>
          </Row>

          <Spin spinning={isLoading}>
            <Table
              dataSource={tableData}
              columns={columnsTemplate}
              rowKey={'id'}
              pagination={{
                pageSize: PAGE_SIZE,
                hideOnSinglePage: false,
                onChange(page) {
                  setCurrentTablePage(page);
                },
              }}
              onChange={handleTableChange}
              locale={localeRU.Table}
              scroll={{ x: 768 }}
            />
          </Spin>
        </ContentCard.Body>
      </ContentCard>
      <Modal
        title="Добавление пользователя"
        onOk={handleGoToAuthService}
        onCancel={() => setIsModalOpen(false)}
        open={isModalOpen}
      >
        <Typography.TextS>
          На данный момент добавление пользователя происходит в централизованном
          сервисе управления пользователями. Открыть страницу сервиса?
        </Typography.TextS>
      </Modal>
    </>
  );
}
