import { useContext, useEffect } from 'react';

import {
  ServerError,
  useDeleteAPKMutation,
  useUploadAPKMutation,
} from '@/features/api';
import { getServerErrorDescriptions } from '@/features/api/api.utils';
import { NotificationContext } from '@/utils/notification-context';

/**
 * Функционал редактирования приложения
 */
function useAPKEdit() {
  const notificationContext = useContext(NotificationContext);
  const [
    deleteAPK,
    {
      isLoading: isAPKDeleting,
      error: deleteAPKError,
      isSuccess: isAPKDeleteSuccess,
    },
  ] = useDeleteAPKMutation();

  const [
    uploadAPK,
    {
      isLoading: isAPKUploading,
      error: uploadAPKError,
      isSuccess: isAPKUploadSuccess,
    },
  ] = useUploadAPKMutation();

  useEffect(() => {
    if (!deleteAPKError) {
      return;
    }

    notificationContext.showError(
      'Ошибка удаления',
      'Произошла ошибка во время удаления релиза приложения.',
    );
    console.error('Ошибка удаления релиза: ', deleteAPKError);
  }, [deleteAPKError, notificationContext]);

  useEffect(() => {
    if (!uploadAPKError) {
      return;
    }

    const messages = getServerErrorDescriptions(
      uploadAPKError as unknown as ServerError,
    );
    notificationContext.showError(
      'Произошла ошибка при загрузке APK файла',
      messages,
    );
    console.error('Ошибка сохранения APK: ', uploadAPKError);
  }, [notificationContext, uploadAPKError]);

  const isAPKInProgress = isAPKDeleting || isAPKUploading;

  return {
    deleteAPK,
    isAPKDeleting,
    deleteAPKError,
    isAPKDeleteSuccess,
    uploadAPK,
    isAPKUploading,
    uploadAPKError,
    isAPKUploadSuccess,
    isAPKInProgress,
  };
}

export default useAPKEdit;
