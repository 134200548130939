/** Описывает возможные роли пользователя в приложении */
export type UserRole = 'admin' | 'developer' | 'moderator' | 'user';

export const USER_ROLE_LABEL = {
  admin: 'Администратор',
  developer: 'Разработчик',
  moderator: 'Модератор',
  /** Если пользователю не назначена никакая роль */
  user: 'Пользователь',
};

/** Константа, использующаяся как набор возможных значений для списка пользователей */
export const USER_ROLE_ITEMS = [
  {
    key: 'developer',
    label: USER_ROLE_LABEL['developer'],
  },
  {
    key: 'moderator',
    label: USER_ROLE_LABEL['moderator'],
  },
  {
    key: 'admin',
    label: USER_ROLE_LABEL['admin'],
  },
  {
    key: 'all',
    label: 'Все',
  },
];

/** Описывает тип состояния авторизованного пользователя */
export type UserState = {
  email: string | null;
  role: UserRole | null;
  accessToken: string | null;
  refreshToken: string | null;
};

export type RefreshTokenPayload = {
  refreshToken?: string;
  accessToken?: string;
};

/** Описывает пользователя системы */
export type User = {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  role: UserRole;
};

/**
 * Имя настройки, в которой хранится колонка сортировки таблицы пользователей
 */
export const USERS_SORT_COLUMN_SETTING = 'usersSortColumn';
/** Направление сортировки таблицы пользователей */
export const USERS_SORT_DIRECTION_SETTING = 'usersSortDirection';
