import { useContext, useEffect } from 'react';

import { ServerError, useGetDeviceGroupsQuery } from '@/features/api';
import { getServerErrorDescriptions } from '@/features/api/api.utils';
import { NotificationContext } from '@/utils/notification-context';

/**
 * Хук для работы со списком группы устройств.
 */
function useDeviceGroups() {
  const notificationContext = useContext(NotificationContext);
  const { data: deviceGroups, isLoading, error } = useGetDeviceGroupsQuery();

  useEffect(() => {
    if (!error) {
      return;
    }

    const errors = getServerErrorDescriptions(error as unknown as ServerError);
    notificationContext.showError(
      'При загрузке информации о группе устройств',
      errors,
    );
  }, [error, notificationContext]);

  return {
    deviceGroups,
    isLoading,
    error,
  };
}

export default useDeviceGroups;
