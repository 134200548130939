import { ChipType } from '@aq_mobile/ui-kit';

import { RELEASE_STATUS_LABEL, ReleaseStatus } from './release.types';

/** Возвращает текстовое описание статуса */
export function releaseStatusToText(status?: ReleaseStatus): string {
  if (!status) {
    return '';
  }

  return RELEASE_STATUS_LABEL[status];
}

/**
 * Возвращает тип компонента Chip, для сопоставления его со статусом.
 */
export function releaseStatusToChipType(status?: ReleaseStatus): ChipType {
  if (!status) {
    return 'default';
  }

  switch (status) {
    case ReleaseStatus.approved:
      return 'success';
    case ReleaseStatus.created:
      return 'default';
    case ReleaseStatus.banned:
      return 'error';
    case ReleaseStatus.onReview:
      return 'info';
    case ReleaseStatus.sentForReview:
      return 'info';
    case ReleaseStatus.published:
      return 'success';
    case ReleaseStatus.rejected:
      return 'error';
    default:
      return 'default';
  }
}

/** Список возможных состояний релиза, который может использоваться для значений DropDown компонентов */
export const STATE_FILTER_ITEMS = [
  {
    key: ReleaseStatus.created,
    label: RELEASE_STATUS_LABEL[ReleaseStatus.created],
  },
  {
    key: ReleaseStatus.sentForReview,
    label: RELEASE_STATUS_LABEL[ReleaseStatus.sentForReview],
  },
  {
    key: ReleaseStatus.onReview,
    label: RELEASE_STATUS_LABEL[ReleaseStatus.onReview],
  },
  {
    key: ReleaseStatus.approved,
    label: RELEASE_STATUS_LABEL[ReleaseStatus.approved],
  },
  {
    key: ReleaseStatus.rejected,
    label: RELEASE_STATUS_LABEL[ReleaseStatus.rejected],
  },
  {
    key: ReleaseStatus.published,
    label: RELEASE_STATUS_LABEL[ReleaseStatus.published],
  },
  {
    key: ReleaseStatus.banned,
    label: RELEASE_STATUS_LABEL[ReleaseStatus.banned],
  },
  {
    key: 'all',
    label: 'Все',
  },
];

/** Список доступных для установки статусов, используются как значения для Dropdown */
export const STATE_ITEMS = STATE_FILTER_ITEMS.slice(0, -1);
