import { ServerError } from '@/features/api';

/**
 * Функция, возвращающая описание ошибки работы с APK
 * в соответствии с объектом error.
 * @param error
 */
export function getAPKErrorMessage(error: ServerError) {
  const errorMessages: Array<string> = [];

  const { non_field_errors, ...errors } = error.data;

  // Обрабатываем известные ошибки.
  if (non_field_errors?.length) {
    non_field_errors.forEach((error) => {
      const message =
        error === 'app_id and version not unique'
          ? 'APK файл данной версии уже загружен.'
          : error;
      errorMessages.push(message);
    });
  }

  return { errorMessages, unknownErrors: errors };
}
