import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApplicationState } from './application.types';

const initialState: ApplicationState = {
  id: null,
  name: null,
};

/**
 * Информация о просматриваемом \ редактируемом приложении.
 */
export const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    set(state, action: PayloadAction<ApplicationState>) {
      return { ...action.payload };
    },
    clear(state, action: PayloadAction<void>) {
      return initialState;
    },
  },
});

export default applicationSlice.reducer;
