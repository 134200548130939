/** Описание информации файла приложения. */
export type APK = {
  id: number;
  file?: string;
  release_name: string;
  release_date: string;
  permission: Array<string>;
  version: string;
  size: number;
  application: number;
};

export const RELEASE_NOTES_LENGTH = 500;
/** Максимально-допустимый размер файла 500MB */
export const APK_MAX_SIZE_MB = 500;
export const APK_MAX_SIZE_BYTE = 1024 * 1024 * APK_MAX_SIZE_MB;
