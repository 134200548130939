import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Chip,
  ContentCard,
  Dropdown,
  DropDownItemStringKey,
  PaginationTotal,
  Table,
  Typography,
} from '@aq_mobile/ui-kit';
import { ArrowDownToLineIcon } from '@aq_mobile/ui-kit/icons';
import {
  dateToLocaleString,
  goToURL,
  strftimeToJsDate,
} from '@aq_mobile/ui-kit/utils';
import styled from '@emotion/styled';
import { Col, Row, Space, Spin, TableColumnsType, TableColumnType } from 'antd';
import localeRU from 'antd/locale/ru_RU';

import { ReleaseStateModal } from '@/components';
import { useApplication } from '@/features/application';
import {
  Release,
  ReleaseStatus,
  releaseStatusToChipType,
  releaseStatusToText,
  STATE_FILTER_ITEMS,
  useModifyReleaseState,
  useReleases,
} from '@/features/release';
import { useUser } from '@/features/user';

const TableStyled = styled(Table)`
  .ant-table-row {
    cursor: pointer;
  }
` as unknown as typeof Table;

const columnsTemplate: TableColumnsType<Release> = [
  {
    key: 'updated_at',
    title: 'Дата изменения',
    dataIndex: ['releasestate', 'updated_at'],
    render: (dateStr?: string) => {
      if (!dateStr) {
        return null;
      }

      return dateToLocaleString(strftimeToJsDate(dateStr));
    },
    ellipsis: true,
  },
  {
    key: 'apk',
    title: 'APK',
    dataIndex: ['apk', 'file'],
    responsive: ['md'],
    render: (apkLink?: string) => {
      if (!apkLink) {
        return null;
      }

      return (
        <Button
          icon={<ArrowDownToLineIcon />}
          onClick={(e) => {
            e.stopPropagation();
            goToURL(apkLink);
          }}
        />
      );
    },
  },
  {
    key: 'developer',
    title: 'Разработчик',
    dataIndex: ['application', 'developer'],
  },
  {
    key: 'state',
    title: 'Статус',
    dataIndex: ['releasestate', 'state'],
    render: (status: ReleaseStatus) => {
      return (
        <Chip type={releaseStatusToChipType(status)}>
          {releaseStatusToText(status)}
        </Chip>
      );
    },
    ellipsis: true,
    width: 220,
  },
];

const PAGE_SIZE = 8;

/**
 * Раздел со списком релизов приложения, доступный администратору.
 */
export default function ReleasesAdmin() {
  const { id } = useParams();
  const [currentAppTablePage, setCurrentAppTablePage] = useState(1);
  const application_id = id!;
  const { isApplicationLoading, applicationData } = useApplication(id);
  const { releases, isLoading: isReleasesLoading } =
    useReleases(application_id);
  /** Максимальное количество релизов для одного приложения 2.
   * И только один релиз может быть на модерации.
   * Поэтому мы можем получить информацию по модератору для таблицы тут
   * иначе это лучше делать на сервере и сразу присылать обогащенные записи */
  const [moderator, setModerator] = useState<number | undefined>();
  const { user } = useUser(moderator);
  const { editReleaseState, isEditReleaseStateLoading } =
    useModifyReleaseState();
  const navigate = useNavigate();
  const [isStateModalOpen, setIsStateModalOpen] = useState(false);
  const [selectedState, setSelectedState] = useState<ReleaseStatus>(
    ReleaseStatus.created,
  );
  const [editingReleaseId, setEditingReleaseId] = useState<
    number | undefined
  >();
  const [stateFilter, setStateFilter] = useState(
    STATE_FILTER_ITEMS[STATE_FILTER_ITEMS.length - 1],
  );

  const tableData = useMemo(() => {
    if (!releases.length) {
      return [];
    }

    const filteredData = releases.filter((row) => {
      const isAllowAll = stateFilter.key === 'all';
      if (isAllowAll) {
        return true;
      }

      const releaseStateFilter = stateFilter.key as ReleaseStatus;

      return row.releasestate.state === releaseStateFilter;
    });

    return filteredData;
  }, [releases, stateFilter.key]);

  const openChangeStateModal = useCallback(
    (id: number, currentState: ReleaseStatus) => {
      setEditingReleaseId(id);
      setSelectedState(currentState);
      setIsStateModalOpen(true);
    },
    [],
  );

  const handleChangeState = useCallback(
    (state: ReleaseStatus) => {
      if (!editingReleaseId) {
        return;
      }

      editReleaseState({
        id: editingReleaseId,
        application_id,
        state: {
          state,
        },
      });
    },
    [application_id, editReleaseState, editingReleaseId],
  );

  const handleGoToRelease = useCallback(
    (id: number) => navigate(`${id}`),
    [navigate],
  );
  const tableColumns = useMemo(() => {
    const columns = [
      ...columnsTemplate,
      {
        key: 'state',
        title: 'Модератор',
        dataIndex: ['releasestate', 'user'],
        render: (_, record) => {
          if (record.releasestate.state !== ReleaseStatus.onReview) {
            return '';
          }

          if (!moderator) {
            setModerator(record.releasestate.user);
            return '';
          }

          if (!user) {
            return '';
          }

          return user.email;
        },
      } as TableColumnType<Release>,
      {
        key: 'id',
        title: 'Действия',
        render: (_, record) => {
          return (
            <Button
              type="link"
              onClick={(e) => {
                e.stopPropagation();
                openChangeStateModal(record.id, record.releasestate.state);
              }}
            >
              Управлять
            </Button>
          );
        },
      } as TableColumnType<Release>,
    ];

    return columns;
  }, [moderator, openChangeStateModal, user]);

  const isPageInProgress =
    isApplicationLoading || isReleasesLoading || isEditReleaseStateLoading;

  return (
    <>
      <ContentCard>
        <ContentCard.Header
          title="Релизы"
          chip={
            <Spin spinning={isApplicationLoading}>
              <Chip type="info">{applicationData?.name}</Chip>
            </Spin>
          }
        />
        <ContentCard.Body>
          <Row gutter={24} align={'middle'} style={{ marginBottom: 8 }}>
            <Col flex="auto"></Col>
            <Col>
              <Space>
                <Typography.TextS>Статус:</Typography.TextS>
                <Dropdown<DropDownItemStringKey>
                  items={STATE_FILTER_ITEMS}
                  selectedText={stateFilter.label}
                  onSelect={setStateFilter}
                />
              </Space>
            </Col>
            <Col>
              <PaginationTotal
                currentPage={currentAppTablePage}
                pageSize={PAGE_SIZE}
                total={tableData.length || 0}
              />
            </Col>
          </Row>

          <Spin spinning={isPageInProgress}>
            <TableStyled
              dataSource={tableData}
              columns={tableColumns}
              rowKey={'id'}
              pagination={{
                pageSize: PAGE_SIZE,
                hideOnSinglePage: false,
                onChange(page) {
                  setCurrentAppTablePage(page);
                },
              }}
              locale={localeRU.Table}
              onRow={(record) => {
                return {
                  onClick: () => handleGoToRelease(record.id),
                };
              }}
              scroll={{ x: 768 }}
            />
          </Spin>
        </ContentCard.Body>
      </ContentCard>

      <ReleaseStateModal
        selectedState={selectedState}
        open={isStateModalOpen}
        onOpenChange={setIsStateModalOpen}
        onOk={handleChangeState}
      />
    </>
  );
}
