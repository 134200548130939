import { Device } from '@/features/deviceGroup';

export type Screenshot = {
  id: number;
  file: string;
  device_type: Device;
};

export const SCREENSHOTS_MIN_COUNT = 2;
export const SCREENSHOTS_MAX_COUNT = 8;
export const SCREENSHOTS_MAX_RESOLUTION: [number, number] = [3840, 38240];
export const SCREENSHOTS_MAX_SIZE = 1024 * 1024 * 8;

export const screenshotMinCountRule = {
  validator: (_: any, value: any) => {
    if (Array.isArray(value) && value.length < SCREENSHOTS_MIN_COUNT) {
      return Promise.reject();
    }

    return Promise.resolve();
  },
  message: `Минимальное количество скриншотов ${SCREENSHOTS_MIN_COUNT}`,
};

export const screenshotMaxCountRule = {
  validator: (_: any, value: any) => {
    if (Array.isArray(value) && value.length > SCREENSHOTS_MAX_COUNT) {
      return Promise.reject();
    }

    return Promise.resolve();
  },
  message: `Максимальное количество скриншотов ${SCREENSHOTS_MAX_COUNT}`,
};
