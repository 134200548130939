import { useMemo } from 'react';
import React from 'react';
import { Button, Chip, ChipType, Typography } from '@aq_mobile/ui-kit';
import { BreakPoints, dateToLocaleString } from '@aq_mobile/ui-kit/utils';
import styled from '@emotion/styled';
import { Avatar, Popconfirm } from 'antd';

import { NO_ICON } from '@/constants';
import {
  ReleaseStatus,
  releaseStatusToChipType,
  releaseStatusToText,
} from '@/features/release';
import useAppToken from '@/themes/useAppToken';

import { ReleaseCardProps } from './ReleaseCard.types';

const CardDivStyled = styled.div<{
  backgroundColor: string;
}>`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 24px;
  background: ${(props) => props.backgroundColor};
  box-sizing: border-box;
  position: relative;
`;

const ContentWrapperDivStyled = styled.div`
  padding: 16px;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-content: stretch;
  flex-wrap: wrap;
`;

const InfoDivStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1 1 auto;

  @media ${BreakPoints.sm} {
    flex-direction: row;
  }
`;

const ActionsDivStyled = styled.div`
  margin-right: 0;
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 0 1 auto;
`;

const IconDivStyled = styled.div`
  margin: 0 auto;
  width: 32px;

  @media ${BreakPoints.sm} {
    margin: 0;
  }
`;

const StatusChipStyled = styled(Chip)`
  position: absolute;
  top: 10px;
  right: 10px;
  margin: 0;
`;

const LinkButton = styled(Button)`
  padding-right: 0;
  padding-left: 0;
`;

/**
 * Карточка релиза для раздела релизов.
 */
function ReleaseCard({
  title,
  status,
  icon,
  date,
  description,
  onChange,
  onOpen,
  onSend,
  onUnpublish,
  onPublish,
  style,
  className,
}: ReleaseCardProps) {
  const { token } = useAppToken();
  const cardBackground = token.AppReleaseCard.backgroundColor;

  const releaseDate = useMemo(() => dateToLocaleString(date), [date]);
  const statusTitle = useMemo(() => releaseStatusToText(status), [status]);
  const statusChipType: ChipType = useMemo(
    () => releaseStatusToChipType(status),
    [status],
  );
  const actions = useMemo(() => {
    switch (status) {
      case ReleaseStatus.approved:
        return (
          <>
            <LinkButton onClick={onChange} type="link">
              Изменить
            </LinkButton>
            <LinkButton onClick={onPublish} type="link">
              Опубликовать
            </LinkButton>
          </>
        );
      case ReleaseStatus.created:
        return (
          <>
            <LinkButton onClick={onChange} type="link">
              Изменить
            </LinkButton>
            <LinkButton onClick={onSend} type="link">
              Отправить на модерацию
            </LinkButton>
          </>
        );
      case ReleaseStatus.banned:
        return (
          <>
            <LinkButton onClick={onOpen} type="link">
              Открыть
            </LinkButton>
          </>
        );
      case ReleaseStatus.onReview:
        return (
          <>
            <LinkButton onClick={onOpen} type="link">
              Открыть
            </LinkButton>
          </>
        );
      case ReleaseStatus.sentForReview:
        return (
          <>
            <Button onClick={onChange} type="link">
              Изменить
            </Button>
          </>
        );
      case ReleaseStatus.published:
        return (
          <Popconfirm
            title="Подтвердите снятие с публикации"
            description={
              <>
                <Typography.ParagraphS>
                  Приложение будет снято с публикации и не будет доступно в
                  поиске.
                </Typography.ParagraphS>
                <Typography.ParagraphS>
                  Для возвращения в публикацию, добавьте новый релиз приложения.
                </Typography.ParagraphS>
              </>
            }
            okText="Подтвердить"
            cancelText="Отмена"
            onConfirm={onUnpublish}
          >
            <LinkButton type="link">Снять с публикации</LinkButton>
          </Popconfirm>
        );
      case ReleaseStatus.rejected:
        return (
          <>
            <LinkButton onClick={onChange} type="link">
              Изменить
            </LinkButton>
          </>
        );
      default:
        return <></>;
    }
  }, [onChange, onOpen, onPublish, onSend, onUnpublish, status]);
  const cardIcon = icon ?? NO_ICON;

  return (
    <CardDivStyled
      style={style}
      className={className}
      backgroundColor={cardBackground}
    >
      <StatusChipStyled type={statusChipType}>{statusTitle}</StatusChipStyled>
      <ContentWrapperDivStyled>
        <InfoDivStyled>
          <IconDivStyled>
            <Avatar
              size="default"
              src={cardIcon}
              shape="square"
              style={{ borderWidth: 0 }}
            />
          </IconDivStyled>
          <div>
            <Typography.Heading5>{title}</Typography.Heading5>
            <div>
              <Typography.TextS type="secondary">
                Дата изменения:&nbsp;
              </Typography.TextS>
              <Typography.TextS>{releaseDate}</Typography.TextS>
            </div>
            <div
              style={{
                wordBreak: 'break-word',
              }}
            >
              <Typography.TextS type="secondary">
                Описание изменений:&nbsp;
              </Typography.TextS>
              <Typography.TextS>{description}</Typography.TextS>
            </div>
          </div>
        </InfoDivStyled>
        <ActionsDivStyled>{actions}</ActionsDivStyled>
      </ContentWrapperDivStyled>
    </CardDivStyled>
  );
}

export default React.memo(ReleaseCard);
