import { useMemo } from 'react';
import { Form, Input, Select } from 'antd';

import { ageRating } from '@/features/ageRating';

import { DescriptionProps, ReleaseForm } from './ReleaseNew.types';

/**
 * Раздел описания приложения
 */
function Description({ deviceGroups }: DescriptionProps) {
  const groupOptions = useMemo(() => {
    if (!deviceGroups) {
      return [];
    }

    return deviceGroups.map((group) => ({
      label: group.name,
      value: group.id,
    }));
  }, [deviceGroups]);

  return (
    <>
      <Form.Item<ReleaseForm>
        label="Описание"
        name={['description', 'description']}
        rules={[
          {
            required: true,
            message: 'Пожалуйста, укажите описание приложения',
          },
        ]}
      >
        <Input.TextArea autoSize={{ minRows: 2 }} />
      </Form.Item>
      <Form.Item<ReleaseForm>
        label="Возрастной рейтинг приложения"
        name={['description', 'rating']}
        rules={[
          {
            required: true,
            message: 'Пожалуйста, укажите возрастной рейтинг приложения',
          },
        ]}
      >
        <Select options={ageRating} />
      </Form.Item>
      <Form.Item<ReleaseForm>
        label="Группы устройств"
        name={['description', 'device_group']}
      >
        <Select options={groupOptions} mode="multiple" />
      </Form.Item>
    </>
  );
}

export default Description;
