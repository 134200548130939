import { useContext, useEffect } from 'react';

import { useStoreListQuery } from '@/features/api';
import { NotificationContext } from '@/utils/notification-context';
/**
 * Предоставляет функционал для получения списка APK файлов магазина приложений.
 */
function useStoreAPKs() {
  const {
    isLoading: isStoreAPKsLoading,
    data,
    error: getStoreAPKsError,
  } = useStoreListQuery();
  const notificationContext = useContext(NotificationContext);
  const storeAPKs = data?.results || [];

  useEffect(() => {
    if (!getStoreAPKsError) {
      return;
    }

    notificationContext.showError(
      'Ошибка получения релизов',
      'Произошла ошибка во время получения списка релизов магазина приложений.',
    );
    console.error(
      'Ошибка получения списка релизов магазина приложений: ',
      getStoreAPKsError,
    );
  }, [getStoreAPKsError, notificationContext]);

  return {
    isStoreAPKsLoading,
    storeAPKs,
    getStoreAPKsError,
  };
}

export default useStoreAPKs;
