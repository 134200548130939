import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  CommentModal,
  ContentCard,
  ImagePreview,
  NoImages,
  NoRelease,
  Typography,
} from '@aq_mobile/ui-kit';
import {
  dateToLocaleString,
  fileSizeFormatter,
  MARGINS,
  strftimeToJsDate,
} from '@aq_mobile/ui-kit/utils';
import styled from '@emotion/styled';
import { Col, Row, Spin, SpinProps } from 'antd';

import { APKInfo, ApplicationCard } from '@/components';
import { NO_VALUE_SYMBOL } from '@/constants';
import { useApplication } from '@/features/application';
import { useCategories } from '@/features/category';
import {
  useModifyReleaseState,
  useReleases,
  useReleaseState,
} from '@/features/release';
import useAppToken from '@/themes/useAppToken';
import getDeviceScreenshots from '@/utils/getDeviceScreenshots';

const DescriptionStyled = styled.pre`
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: Golos;
  margin: 0;
`;

const SpinStyled = styled(Spin, {
  shouldForwardProp: (props) => props !== 'backgroundColor',
})<SpinProps & { backgroundColor: string }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${(props) => (props.spinning ? 1 : -1)};
  background-color: ${(props) => props.backgroundColor};
`;

/**
 * Страница для модерации релиза приложения.
 */
function ReleaseReview() {
  const { id } = useParams();
  const application_id = String(id!);
  const { categories, isCategoriesLoading, categoriesError } = useCategories();
  const navigate = useNavigate();
  const {
    reviewRelease,
    isLoading: isReleaseLoading,
    error: releaseError,
    isSuccess: isReleaseLoadingSuccess,
  } = useReleases(application_id);
  const { approve, isApproveLoading, reject, isRejectLoading } =
    useModifyReleaseState();
  const {
    id: releaseId,
    apk,
    description,
    icon,
    release_notes,
    screenshots,
    category,
  } = reviewRelease || {};
  const { isApplicationLoading, applicationData, applicationError } =
    useApplication(application_id);
  const {
    data: releaseState,
    error: releaseStateError,
    isLoading: releaseStateIsLoading,
  } = useReleaseState(application_id, releaseId);
  const { app_id, name: appName } = applicationData || {};
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  /** Отсутствует релиз для проверки. Модератор может открыть страницу приложения из адресной строки браузера */
  const isNoReviewRelease = !reviewRelease && isReleaseLoadingSuccess;
  const { token } = useAppToken();
  const spinBg = token.AppCard.colorBgContainer;

  const categoriesDescription = useMemo(() => {
    if (!category || !category.length) {
      return 'Не указана';
    }

    if (!categories) {
      return NO_VALUE_SYMBOL;
    }

    const description = categories
      .filter((c) => category.includes(c.id))
      .map((c) => c.name)
      .join(', ');

    return description;
  }, [categories, category]);

  const appCardCategories = useMemo(() => {
    if (!category || !categories) {
      return [];
    }

    return categories.filter((c) => category.includes(c.id));
  }, [categories, category]);

  const descriptionParagraphs = useMemo(() => {
    if (!description) {
      return '';
    }

    const descriptionText = description.description;

    return descriptionText ? (
      <DescriptionStyled>{descriptionText}</DescriptionStyled>
    ) : (
      ''
    );
  }, [description]);

  const handleDisapproveClick = useCallback(() => {
    setIsMessageModalOpen(true);
  }, []);

  const handleApproveClick = useCallback(async () => {
    if (!releaseId) {
      return;
    }

    await approve({ application_id, id: releaseId });
    navigate(`/review`);
  }, [application_id, approve, navigate, releaseId]);

  const handleDisapproveRelease = useCallback(
    async (message: string | undefined) => {
      if (!releaseId) {
        return;
      }

      // Сообщение обязательно при указании required параметра.
      await reject({
        application_id,
        id: releaseId,
        text: message!.trim(),
      }).unwrap();
      navigate(`/review`);
    },
    [application_id, navigate, reject, releaseId],
  );

  const handleMessageModalCancel = useCallback(() => {
    setIsMessageModalOpen(false);
  }, []);

  const phoneScreenshotsTemplate = useMemo(() => {
    const phoneScreenshots = getDeviceScreenshots(screenshots, 'phone');

    if (!phoneScreenshots.length) {
      return (
        <Row style={{ marginBlockStart: MARGINS.section }}>
          <Col span={24}>
            <NoImages title="Скриншоты для смартфона не предоставлены" />
          </Col>
        </Row>
      );
    }

    return phoneScreenshots.map((screenshot, index) => {
      return (
        <Row style={{ marginBlockStart: MARGINS.section }} key={screenshot.id}>
          <Col xs={24} md={6}>
            <Typography.TextS style={{ marginBlockEnd: MARGINS.subtitle }}>
              Скриншот №{index + 1}
            </Typography.TextS>
          </Col>
          <Col xs={24} md={18}>
            <ImagePreview image={screenshot.file} />
          </Col>
        </Row>
      );
    });
  }, [screenshots]);

  const tabletScreenshotsTemplate = useMemo(() => {
    const tabletScreenshots = getDeviceScreenshots(screenshots, 'tablet');

    if (!tabletScreenshots.length) {
      return (
        <Row style={{ marginBlockStart: MARGINS.section }}>
          <Col span={24}>
            <NoImages title="Скриншоты для планшета не предоставлены" />
          </Col>
        </Row>
      );
    }

    return tabletScreenshots.map((screenshot, index) => {
      return (
        <Row style={{ marginBlockStart: MARGINS.section }} key={screenshot.id}>
          <Col xs={24} md={6}>
            <Typography.TextS style={{ marginBlockEnd: MARGINS.subtitle }}>
              Скриншот №{index + 1}
            </Typography.TextS>
          </Col>
          <Col xs={24} md={18}>
            <ImagePreview image={screenshot.file} />
          </Col>
        </Row>
      );
    });
  }, [screenshots]);

  const deviceGroups = useMemo(() => {
    if (
      !description ||
      !description.device_group ||
      description.device_group.length === 0
    ) {
      return NO_VALUE_SYMBOL;
    }

    return description.device_group.map((group) => group.name).join(', ');
  }, [description]);

  const releaseTemplate = useMemo(() => {
    if (!apk) {
      return (
        <Typography.Heading5 type="secondary" style={{ textAlign: 'center' }}>
          На данный момент релиз APK отсутствует.
        </Typography.Heading5>
      );
    }

    const releaseDate = dateToLocaleString(strftimeToJsDate(apk.release_date));
    const releaseSize = fileSizeFormatter(apk.size);

    return (
      <APKInfo
        applicationId={app_id}
        file={apk.file}
        version={apk.release_name}
        date={releaseDate}
        changes={release_notes}
        versionCode={apk.version}
        size={releaseSize}
      />
    );
  }, [apk, app_id, release_notes]);

  const isPageLoading =
    isApplicationLoading ||
    isReleaseLoading ||
    isCategoriesLoading ||
    releaseStateIsLoading ||
    isApproveLoading ||
    isRejectLoading;

  const isReviewDisabled =
    Boolean(applicationError) ||
    Boolean(releaseStateError) ||
    Boolean(categoriesError) ||
    Boolean(releaseError);

  const infoTemplate = useMemo(
    () => (
      <>
        <Row style={{ marginBlockStart: MARGINS.section }}>
          <Col xs={24} md={6}>
            <Typography.TextS style={{ marginBlockEnd: MARGINS.subtitle }}>
              Описание
            </Typography.TextS>
          </Col>
          <Col md={18}>{descriptionParagraphs}</Col>
        </Row>

        <Row style={{ marginBlockStart: MARGINS.section }}>
          <Col xs={24} md={6}>
            <Typography.TextS style={{ marginBlockEnd: MARGINS.subtitle }}>
              Возрастной рейтинг
            </Typography.TextS>
          </Col>
          <Col md={18}>{description?.rating}</Col>
        </Row>

        <Row style={{ marginBlockStart: MARGINS.section }}>
          <Col xs={24} md={6}>
            <Typography.TextS style={{ marginBlockEnd: MARGINS.subtitle }}>
              Категория
            </Typography.TextS>
          </Col>
          <Col md={18}>{categoriesDescription}</Col>
        </Row>

        <Row style={{ marginBlockStart: MARGINS.section }}>
          <Col xs={24} md={6}>
            <Typography.TextS style={{ marginBlockEnd: MARGINS.subtitle }}>
              Группы устройств
            </Typography.TextS>
          </Col>
          <Col md={18}>
            <Typography.TextS>{deviceGroups}</Typography.TextS>
          </Col>
        </Row>

        <Row style={{ marginBlockStart: MARGINS.section }}>
          <Col>
            <Typography.Heading5 style={{ marginBlockEnd: MARGINS.subtitle }}>
              Графика
            </Typography.Heading5>
          </Col>
        </Row>

        <Row style={{ marginBlockStart: MARGINS.section }}>
          <Col xs={24} md={6}>
            <Typography.TextS style={{ marginBlockEnd: MARGINS.subtitle }}>
              Иконка
            </Typography.TextS>
          </Col>
          <Col md={18}>
            {icon ? (
              <ImagePreview image={icon.file} />
            ) : (
              <NoImages title="Иконка не предоставлена" />
            )}
          </Col>
        </Row>

        <Row style={{ marginBlockStart: MARGINS.section }}>
          <Col xs={24}>
            <Typography.Heading5>Скриншоты для смартфона</Typography.Heading5>
          </Col>
        </Row>

        {phoneScreenshotsTemplate}

        <Row style={{ marginBlockStart: MARGINS.section }}>
          <Col xs={24}>
            <Typography.Heading5>Скриншоты для планшета</Typography.Heading5>
          </Col>
        </Row>

        {tabletScreenshotsTemplate}

        <Row style={{ marginBlockStart: MARGINS.section }}>
          <Col xs={24}>
            <Typography.Heading5>Файл релиза</Typography.Heading5>
          </Col>
        </Row>

        <Row style={{ marginBlockStart: MARGINS.section }}>
          <Col span={24}>{releaseTemplate}</Col>
        </Row>

        <Row style={{ marginBlockStart: MARGINS.section }}>
          <Col xs={24} md={6}>
            <Typography.Heading5 style={{ marginBlockEnd: MARGINS.subtitle }}>
              Комментарий для модератора
            </Typography.Heading5>
          </Col>
          <Col md={18}>
            <Typography.TextS>
              {releaseState?.text || NO_VALUE_SYMBOL}
            </Typography.TextS>
          </Col>
        </Row>
      </>
    ),
    [
      categoriesDescription,
      description?.rating,
      descriptionParagraphs,
      deviceGroups,
      icon,
      phoneScreenshotsTemplate,
      releaseState?.text,
      releaseTemplate,
      tabletScreenshotsTemplate,
    ],
  );

  const noReleaseTemplate = useMemo(
    () => (
      <NoRelease title="Отсутствует релиз для модерации" isAllowAdd={false} />
    ),
    [],
  );

  const bodyTemplate = useMemo(() => {
    if (isApplicationLoading || isReleaseLoading) {
      return null;
    }

    return isNoReviewRelease ? noReleaseTemplate : infoTemplate;
  }, [
    infoTemplate,
    isApplicationLoading,
    isNoReviewRelease,
    isReleaseLoading,
    noReleaseTemplate,
  ]);

  const isButtonsDisabled =
    isPageLoading || isNoReviewRelease || isReviewDisabled;

  return (
    <>
      <ContentCard>
        <ContentCard.Header title="Релиз" />
        <ContentCard.Body
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            gap: 24,
          }}
        >
          <SpinStyled spinning={isPageLoading} backgroundColor={spinBg} />
          <ApplicationCard
            icon={icon?.file}
            name={appName}
            categories={appCardCategories}
            rating={description?.rating}
          />
          {bodyTemplate}
        </ContentCard.Body>
        <ContentCard.Footer
          style={{
            marginBlockStart: MARGINS.section,
          }}
          buttons={
            <>
              <Button
                type="primary"
                onClick={handleApproveClick}
                disabled={isButtonsDisabled}
              >
                Одобрено
              </Button>
              <Button
                type="default"
                onClick={handleDisapproveClick}
                disabled={isButtonsDisabled}
              >
                Не одобрено
              </Button>
            </>
          }
        ></ContentCard.Footer>
      </ContentCard>

      <CommentModal
        title="Причина отказа"
        label="Комментарий для разработчика"
        open={isMessageModalOpen}
        onOk={handleDisapproveRelease}
        onCancel={handleMessageModalCancel}
        required
        requiredText="Комментарий для разработчика обязателен"
        limit={2047}
      />
    </>
  );
}

export default ReleaseReview;
