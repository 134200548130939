import { AppDescription, AppIcon } from '@/features/api';
import { APK } from '@/features/apk';
import { ApplicationInfo } from '@/features/application';
import { Screenshot } from '@/features/screenshots';

export enum ReleaseStatus {
  created = 'created',
  sentForReview = 'sent for review',
  onReview = 'on review',
  approved = 'approved',
  rejected = 'rejected',
  published = 'published',
  banned = 'banned',
}

/** Текстовое сопоставление статусов релиза */
export const RELEASE_STATUS_LABEL = {
  [ReleaseStatus.created]: 'Создан',
  [ReleaseStatus.sentForReview]: 'Отправлен на модерацию',
  [ReleaseStatus.onReview]: 'На модерации',
  [ReleaseStatus.approved]: 'Одобрен к публикации',
  [ReleaseStatus.rejected]: 'Отклонен',
  [ReleaseStatus.published]: 'Опубликован',
  [ReleaseStatus.banned]: 'Запрещен',
};

/** Состояние релиза */
export type ReleaseState = {
  release: number;
  created_at: string;
  updated_at: string;
  published: boolean;
  approved: boolean;
  state: ReleaseStatus;
  text: string;
  /** Пользователь, поменявший состояние релиза */
  user: number;
};

/**
 * Релиз приложения.
 * Состоит из release_notes и ссылок на:
 * - описание приложения
 * - иконки
 * - скриншоты
 * - информации об APK файле
 * - категории
 */
export type Release = {
  id: number;
  apk: APK;
  description: AppDescription;
  icon: AppIcon;
  release_notes: string;
  application: ApplicationInfo;
  screenshots: Array<Screenshot>;
  approved?: boolean;
  releasestate: ReleaseState;
  category: Array<number>;
};

/**
 * Имя настройки, в которой хранится колонка сортировки таблицы истории изменения статусов релиза
 */
export const RELEASE_HISTORY_SORT_COLUMN_SETTING = 'releaseHistorySortColumn';
