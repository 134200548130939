import { settings } from '@aq_mobile/ui-kit/utils';
import { SortOrder } from 'antd/es/table/interface';

const NAME_ORDER_SEPARATOR = '=';

/**
 * Вспомогательная функция, возвращающая направление для сортировки колонки таблицы.
 * Если для этой колонки значение было сохранено.
 *
 * В настройках значение колонки сортировки хранится так { "settingPath": "columnName=order" }
 *
 * Если для определенной колонки указать параметр defaultSortOrder, то в случае отсутствия сохраненных
 * настроек направления сортировки для таблицы, будет использоваться указанное направление
 * для колонки columnName.
 *
 * @param settingPath путь к настройки, которая хранит имя колонки сортировки и ее значение
 * @param columnName  имя колонки.
 * @param defaultSortOrder  указывает направление сортировки по умолчанию для текущей колонки.
 */
export function getSortColumnSettings(
  settingPath: string,
  columnName: string,
  defaultSortOrder?: 'ascend' | 'descend',
): SortOrder {
  const sortColumn = settings.get(settingPath);

  // Если для колонки нет сохраненных настроек
  // возвращаем либо сортировку по умолчанию, если для этой колонки она должна быть применена
  // либо null.
  if (!sortColumn) {
    return Boolean(defaultSortOrder) ? (defaultSortOrder as SortOrder) : null;
  }

  const [name, direction] = (sortColumn as string).split(
    NAME_ORDER_SEPARATOR,
  ) as [string, string];

  // Если настройки сортировки таблицы сохранены, проверяем
  // для текущей ли колонки сохраненные настройки.
  if (name === columnName) {
    return direction === 'null' ? null : (direction as SortOrder);
  }

  return null;
}

/**
 * Сохраняет имя колонки и  направление сортировки для таблицы.
 * В настройках значение колонки сортировки хранится так { "settingPath": "columnName=order" }
 *
 * @param settingPath имя настройки, в которой хранится направление сортировки
 * @param columnName имя колонки
 * @param order направление сортировки
 */
export function setSortColumnSettings(
  settingPath: string,
  columnName: string,
  order: SortOrder,
) {
  settings.set(settingPath, `${columnName}=${order}`);
}
