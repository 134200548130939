import React from 'react';
import styled from '@emotion/styled';
import { App as AntApp, type AppProps } from 'antd';

import useAppToken from '@/themes/useAppToken';

const AppStyled = styled(AntApp)<{ bgColor: string }>`
  background-color: ${(props) => props.bgColor};
  height: 100vh;
  height: 100svh;
  display: flex;
  justify-content: center;
`;

/**
 * Стилизованный компонент контейнера приложения
 */
function App(props: AppProps) {
  const { token } = useAppToken();

  return <AppStyled bgColor={token.colorBgLayout} {...props} />;
}

export default React.memo(App);
