import { useCallback, useMemo, useState } from 'react';
import { Button, SortDirection, Typography } from '@aq_mobile/ui-kit';
import { MagnifyingGlassIcon, PlusIcon } from '@aq_mobile/ui-kit/icons';
import { settings, sortByName } from '@aq_mobile/ui-kit/utils';
import styled from '@emotion/styled';
import { Col, Form, Input, Modal, Row, Space, Spin } from 'antd';

import { CategoryCard } from '@/components';
import { CATEGORY_SORT_DIRECTION_SETTING } from '@/components/CategoryCard/CategoryCard.types';
import {
  Category,
  CATEGORY_NAME_MAX_LENGTH,
  useCategories,
  useEditCategory,
} from '@/features/category';
import filterByTerm from '@/utils/filterByTerm';

const DivContainerStyled = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const DivCardsContainerStyled = styled.div`
  display: flex;
  flex: 1 1 100%;
  gap: 24px;
  flex-wrap: wrap;
`;

type AddCategoryForm = {
  name: string;
};

type EditCategoryForm = Category;

/**
 * Страница для работы со списком категорий.
 */
function Categories() {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [addForm] = Form.useForm<AddCategoryForm>();
  const [editForm] = Form.useForm<EditCategoryForm>();
  const [editingCategory, setEditingCategory] = useState<
    Category | undefined
  >();
  const [sortDirection, setSortDirection] = useState<'ascend' | 'descend'>(
    () =>
      settings.get(CATEGORY_SORT_DIRECTION_SETTING, 'ascend') as
        | 'ascend'
        | 'descend',
  );
  const [searchTerm, setSearchTerm] = useState('');
  const { isCategoriesLoading, categories } = useCategories();
  const {
    addCategory,
    isCategoryAdding,
    deleteCategory,
    isCategoryDeleting,
    updateCategory,
    isCategoryUpdating,
  } = useEditCategory();

  const handleAddCategory = () => setIsAddModalOpen(true);

  const handleAdd = useCallback(
    (formValues: AddCategoryForm) => {
      addCategory({
        ...formValues,
        is_active: true,
      });
      addForm.resetFields();
      setIsAddModalOpen(false);
    },
    [addCategory, addForm],
  );

  const handleAddCancel = () => {
    addForm.resetFields();
    setIsAddModalOpen(false);
  };

  const handleEditCancel = () => {
    setIsEditModalOpen(false);
    setEditingCategory(undefined);
  };

  const handleEditCategory = useCallback(
    (formValues: EditCategoryForm) => {
      if (!editingCategory) {
        return;
      }

      updateCategory({
        id: editingCategory.id,
        name: formValues.name,
      });

      editForm.resetFields();
      setIsEditModalOpen(false);
      setEditingCategory(undefined);
    },
    [editForm, editingCategory, updateCategory],
  );

  const handleOpenEditCategoryModal = useCallback(
    (category: Category) => {
      editForm.setFieldValue('name', category.name);
      setIsEditModalOpen(true);
      setEditingCategory(category);
    },
    [editForm],
  );

  const handleCategoryActiveChange = useCallback(
    (id: number, isActive: boolean) => {
      updateCategory({
        id,
        is_active: isActive,
      });
    },
    [updateCategory],
  );

  const handleDelete = useCallback(
    (id: number) => {
      deleteCategory(id);
    },
    [deleteCategory],
  );

  const isCategoriesInProgress =
    isCategoriesLoading ||
    isCategoryAdding ||
    isCategoryDeleting ||
    isCategoryUpdating;

  const isAscendSort = sortDirection === 'ascend';

  const categoriesFiltered = useMemo(() => {
    if (!categories) {
      return [];
    }

    const filteredData = filterByTerm(categories, searchTerm);
    const sortedData = sortByName(filteredData, isAscendSort);

    return sortedData;
  }, [categories, searchTerm, isAscendSort]);

  const handleSortDirectionChange = useCallback((isAscending: boolean) => {
    const direction = isAscending ? 'ascend' : 'descend';
    settings.set(CATEGORY_SORT_DIRECTION_SETTING, direction);
    setSortDirection(direction);
  }, []);

  return (
    <>
      <DivContainerStyled>
        <Row align="middle" gutter={24} style={{ marginBlockEnd: 32 }}>
          <Col>
            <Space wrap>
              <Typography.Heading3>Категории</Typography.Heading3>
              <SortDirection
                isAscending={isAscendSort}
                onChange={handleSortDirectionChange}
              />
            </Space>
          </Col>
          <Col flex="auto"></Col>
          <Col>
            <Space wrap>
              <Input
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Поиск по категориям"
                suffix={<MagnifyingGlassIcon />}
                allowClear
              />
              <Button
                type="primary"
                icon={<PlusIcon />}
                onClick={handleAddCategory}
              >
                Добавить категорию
              </Button>
            </Space>
          </Col>
        </Row>
        <Spin spinning={isCategoriesInProgress}>
          {categoriesFiltered.length ? (
            <DivCardsContainerStyled>
              {categoriesFiltered.map((category) => (
                <CategoryCard
                  key={category.id}
                  category={category}
                  style={{
                    flex: '0 1 252px',
                  }}
                  onDelete={handleDelete}
                  onEdit={handleOpenEditCategoryModal}
                  onActiveChange={handleCategoryActiveChange}
                />
              ))}
            </DivCardsContainerStyled>
          ) : (
            <Typography.Heading5
              type="secondary"
              style={{
                display: 'inline-block',
                width: '100%',
                textAlign: 'center',
              }}
            >
              Категории отсутствуют
            </Typography.Heading5>
          )}
        </Spin>
      </DivContainerStyled>
      <Modal
        title="Добавить категорию"
        open={isAddModalOpen}
        onCancel={handleAddCancel}
        onOk={() => addForm.submit()}
        okText="Ok"
        cancelText="Отмена"
      >
        <Form
          form={addForm}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 14 }}
          onFinish={handleAdd}
          labelWrap
          requiredMark={false}
          labelAlign="left"
          colon={false}
        >
          <Form.Item<AddCategoryForm>
            label="Название категории"
            name="name"
            rules={[
              {
                required: true,
                message: 'Пожалуйста, укажите название категории',
              },
              {
                validator: (_, value) => {
                  if (value && value.length && value.trim().length === 0) {
                    return Promise.reject();
                  }

                  return Promise.resolve();
                },
                message: 'Название не может состоять из одних пробелов',
              },
            ]}
          >
            <Input maxLength={CATEGORY_NAME_MAX_LENGTH} />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Редактировать категорию"
        open={isEditModalOpen}
        onCancel={handleEditCancel}
        onOk={() => editForm.submit()}
        okText="Ok"
        cancelText="Отмена"
      >
        <Form
          form={editForm}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 14 }}
          onFinish={handleEditCategory}
          labelWrap
          requiredMark={false}
          labelAlign="left"
          colon={false}
        >
          <Form.Item<AddCategoryForm>
            label="Название категории"
            name="name"
            rules={[
              {
                required: true,
                message: 'Пожалуйста, укажите название категории',
              },
              {
                validator: (_, value) => {
                  if (value && value.length && value.trim().length === 0) {
                    return Promise.reject();
                  }

                  return Promise.resolve();
                },
                message: 'Название не может состоять из одних пробелов',
              },
            ]}
          >
            <Input maxLength={CATEGORY_NAME_MAX_LENGTH} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default Categories;
