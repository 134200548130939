import {
  NotificationDropdownIcon,
  NotificationsDropdownItem,
} from '@aq_mobile/ui-kit';

import { ChangelogRecord } from '@/features/changelog';
import { ReleaseStatus } from '@/features/release';

const notificationStates = [
  ReleaseStatus.approved,
  ReleaseStatus.banned,
  ReleaseStatus.onReview,
  ReleaseStatus.rejected,
];

/**
 * Возвращает объект, содержащий сформированное уведомление,
 * относительно переданной записи об изменении состояния.
 */
function mapNotificationToMessage(
  changeRecords: Array<ChangelogRecord>,
): Array<NotificationsDropdownItem> {
  const filteredRecords = changeRecords.filter((record) =>
    notificationStates.includes(record.state),
  );

  const notifications = filteredRecords.map((record) => {
    switch (record.state) {
      case ReleaseStatus.approved:
        return {
          key: record.id,
          icon: NotificationDropdownIcon.success,
          label: `Релиз ${record.application.name} одобрен`,
        };
      case ReleaseStatus.rejected:
        return {
          key: record.id,
          icon: NotificationDropdownIcon.error,
          label: `Релиз ${record.application.name} не одобрен`,
        };
      case ReleaseStatus.banned:
        return {
          key: record.id,
          icon: NotificationDropdownIcon.warning,
          label: `Релиз ${record.application.name} снят с публикации`,
        };
      case ReleaseStatus.onReview:
        return {
          key: record.id,
          icon: NotificationDropdownIcon.info,
          label: `Релиз ${record.application.name} взят на модерацию`,
        };
      default: {
        return {
          key: record.id,
          icon: NotificationDropdownIcon.info,
          label: `Релиз ${record.application.name}: ${record.text}`,
        };
      }
    }
  });

  return notifications;
}

export { mapNotificationToMessage };
