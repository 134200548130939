import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Chip, ContentCard, PaginationTotal, Table } from '@aq_mobile/ui-kit';
import { MagnifyingGlassIcon } from '@aq_mobile/ui-kit/icons';
import { dateToLocaleString, strftimeToJsDate } from '@aq_mobile/ui-kit/utils';
import { Col, Input, Row, Space, Spin, TableColumnsType } from 'antd';
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from 'antd/es/table/interface';
import localeRU from 'antd/locale/ru_RU';

import { ServerError, useGetChangelogQuery } from '@/features/api';
import { getServerErrorDescriptions } from '@/features/api/api.utils';
import { useApplication } from '@/features/application';
import { ChangelogRecord } from '@/features/changelog';
import {
  RELEASE_HISTORY_SORT_COLUMN_SETTING,
  ReleaseStatus,
  releaseStatusToText,
} from '@/features/release';
import filterByTerm from '@/utils/filterByTerm';
import { NotificationContext } from '@/utils/notification-context';
import {
  getSortColumnSettings,
  setSortColumnSettings,
} from '@/utils/sortColumnSettings';

const PAGE_SIZE = 7;

const columnsTemplate: TableColumnsType<ChangelogRecord> = [
  {
    title: 'Дата',
    key: 'updated_at',
    dataIndex: 'updated_at',
    width: 180,
    defaultSortOrder: getSortColumnSettings(
      RELEASE_HISTORY_SORT_COLUMN_SETTING,
      'updated_at',
      'descend',
    ),
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: (a: ChangelogRecord, b: ChangelogRecord) => {
      const dateA = Number(a.updated_at);
      const dateB = Number(b.updated_at);

      return dateA - dateB;
    },
    render: (dateStr: string) => {
      if (!dateStr.length) {
        return '';
      }

      const date = strftimeToJsDate(dateStr);
      return dateToLocaleString(date);
    },
  },
  {
    title: 'Пользователь',
    key: 'user',
    dataIndex: 'user',
    defaultSortOrder: getSortColumnSettings(
      RELEASE_HISTORY_SORT_COLUMN_SETTING,
      'user',
    ),
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: (a, b) => a.user.localeCompare(b.user),
    width: 140,
  },
  {
    title: 'Версия APK',
    key: 'apk_version',
    dataIndex: 'apk_version',
    width: 120,
  },
  {
    title: 'Статус',
    key: 'state',
    dataIndex: 'state',
    width: 190,
    render: (status: ReleaseStatus) => releaseStatusToText(status),
  },
  {
    title: 'Комментарий',
    key: 'text',
    dataIndex: 'text',
  },
];

export default function ApplicationHistory() {
  const notificationContext = useContext(NotificationContext);
  const { id } = useParams();
  const application_id = id!;
  const { applicationData, isApplicationLoading } =
    useApplication(application_id);
  const {
    data: changelog,
    isLoading: isChangelogLoading,
    error: changelogError,
  } = useGetChangelogQuery({
    application: application_id,
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (!changelogError) {
      return;
    }

    const messages = getServerErrorDescriptions(
      changelogError as unknown as ServerError,
    );

    notificationContext.showError(
      'Ошибка при получении истории приложения',
      messages,
    );
  }, [changelogError, notificationContext]);

  // Поддержка смены направления и колонки сортировки
  const handleTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<ChangelogRecord> | SorterResult<ChangelogRecord>[],
    ) => {
      if (Array.isArray(sorter) || !sorter.columnKey) {
        return;
      }

      // Сохраняем настройку сортировки таблицы
      if (['updated_at', 'user'].includes(sorter.columnKey.toString())) {
        setSortColumnSettings(
          RELEASE_HISTORY_SORT_COLUMN_SETTING,
          sorter.columnKey.toString(),
          sorter.order ?? null,
        );
      }
    },
    [],
  );

  const tableData = useMemo(() => {
    if (!changelog) {
      return [];
    }

    const filteredData = filterByTerm(changelog.results, searchTerm, 'text');
    return filteredData;
  }, [changelog, searchTerm]);

  return (
    <ContentCard>
      <ContentCard.Header
        title="История"
        chip={
          <Spin spinning={isApplicationLoading}>
            <Chip type="info">{applicationData?.name}</Chip>
          </Spin>
        }
      ></ContentCard.Header>

      <ContentCard.Body>
        <Row gutter={24} align={'middle'} style={{ marginBottom: 8 }}>
          <Col flex="auto"></Col>
          <Col>
            <Space>
              <Input
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Поиск по комментарию"
                suffix={<MagnifyingGlassIcon />}
                allowClear
              />
            </Space>
          </Col>
          <Col>
            <PaginationTotal
              currentPage={currentPage}
              pageSize={PAGE_SIZE}
              total={tableData.length}
            />
          </Col>
        </Row>

        <Spin spinning={isChangelogLoading}>
          <Table
            dataSource={tableData}
            columns={columnsTemplate}
            rowKey={'id'}
            pagination={{
              pageSize: PAGE_SIZE,
              hideOnSinglePage: false,
              onChange(page) {
                setCurrentPage(page);
              },
            }}
            onChange={handleTableChange}
            locale={localeRU.Table}
            scroll={{ x: 768 }}
          ></Table>
        </Spin>
      </ContentCard.Body>
    </ContentCard>
  );
}
