import { Navigate, Outlet } from 'react-router-dom';

import { useAppSelector } from '@/features/hooks';
import {
  isUserLoggedInSelector,
  userRoleSelector,
} from '@/features/user/user.selectors';

import { ProtectedRouteProps } from './ProtectedRoute.types';

/**
 * Родительский компонент, для роутов, защищенных доступом по роли
 * Если роли не указаны, то роут доступен всем авторизованным пользователям.
 */
export default function ProtectedRoute({
  roles,
  redirectTo = '/',
}: ProtectedRouteProps) {
  const role = useAppSelector(userRoleSelector);
  const isLoggedIn = useAppSelector(isUserLoggedInSelector);

  // Возвращаем пользователя, если он не авторизован
  // или его роль не содержится в переданных, разрешенных.
  if (!isLoggedIn || !role || (roles.length !== 0 && !roles.includes(role))) {
    return <Navigate to={redirectTo} replace={true} />;
  }

  return <Outlet></Outlet>;
}
