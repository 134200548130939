import applicationReducer, { applicationSlice } from './application.reducer';
import {
  applicationSelector,
  isApplicationSelectedSelector,
} from './application.selectors';
export * from './application.types';
export { default as useApplication } from './hooks/useApplication';
export { default as useApplicationEdit } from './hooks/useApplicationEdit';
export { default as useApplications } from './hooks/useApplications';

const applicationActions = {
  ...applicationSlice.actions,
};

const applicationSelectors = {
  isApplicationSelectedSelector,
  applicationSelector,
};

export { applicationActions, applicationReducer, applicationSelectors };
