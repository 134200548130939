import useUser from './hooks/useUser';
import useUsers from './hooks/useUsers';
import userReducer, { userSlice } from './user.reducer';
import {
  isUserLoggedInSelector,
  userMailSelector,
  userRoleSelector,
} from './user.selectors';
export * from './user.types';

const userActions = {
  ...userSlice.actions,
};

const userSelectors = {
  isUserLoggedInSelector,
  userMailSelector,
  userRoleSelector,
};

export { userActions, userReducer, userSelectors, useUser, useUsers };
