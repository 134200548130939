import { theme } from 'antd';

import { AppToken } from './theme.types';

/**
 * Возвращает токены стилизации, включающие токены компонентов проекта.
 */
function useAppToken() {
  const { token, ...restProps } = theme.useToken();

  return {
    token: token as AppToken,
    ...restProps,
  };
}

export default useAppToken;
