import { AppDescription } from '@/features/api';

import { DescriptionForm } from './ReleaseNew.types';

/**
 * Проверяет, что содержание переданных объектов описания совпадает.
 * Идентификатор не учитывается.
 */
function checkIsDescriptionChanged(
  oldDescription: Omit<AppDescription, 'id'> | undefined,
  newDescription: DescriptionForm,
) {
  if (!oldDescription) {
    return true;
  }

  const sameGroupNumber =
    oldDescription.device_group?.length === newDescription.device_group?.length;
  const sameGroups = oldDescription.device_group?.every((group, index) => {
    const newGroupId = newDescription.device_group?.[index];
    return newGroupId === group.id;
  });

  const areSame =
    oldDescription.description === newDescription.description &&
    oldDescription.rating === newDescription.rating &&
    sameGroupNumber &&
    sameGroups;

  return !areSame;
}

export { checkIsDescriptionChanged };
