import { useContext, useEffect } from 'react';

import { useGetAppsQuery } from '@/features/api';
import { NotificationContext } from '@/utils/notification-context';

/**
 * Хук для работы со списком приложений.
 */
function useApplications() {
  const notificationContext = useContext(NotificationContext);
  const {
    isLoading: isApplicationsLoading,
    data,
    error: getApplicationsError,
  } = useGetAppsQuery();

  const applications = data?.results || [];

  useEffect(() => {
    if (!getApplicationsError) {
      return;
    }

    notificationContext.showError(
      'Ошибка',
      'Произошла ошибка при получении списка приложений с сервера.',
    );
  }, [getApplicationsError, notificationContext]);

  return {
    isApplicationsLoading,
    applications,
    getApplicationsError,
  };
}

export default useApplications;
