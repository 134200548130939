import { Typography } from '@aq_mobile/ui-kit';

import { ApkFieldProps } from '@/components/APKInfo/ApkInfo.types';

/**
 * Поле заголовок и значение для отображения данных по APK
 */
function ApkField({ title, value, isBigValue }: ApkFieldProps) {
  return (
    <>
      {title && (
        <div style={{ marginBlockEnd: 8 }}>
          <Typography.TextXS>{title}</Typography.TextXS>
        </div>
      )}
      {value && (
        <div>
          {isBigValue ? (
            <Typography.ParagraphM>{value}</Typography.ParagraphM>
          ) : (
            <Typography.TextM>{value}</Typography.TextM>
          )}
        </div>
      )}
    </>
  );
}

export default ApkField;
