import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Chip,
  ContentCard,
  Dropdown,
  type DropDownItemStringKey,
  PaginationTotal,
  Table,
  Typography,
} from '@aq_mobile/ui-kit';
import {
  MagnifyingGlassIcon,
  PlusIcon,
  TrashIcon,
} from '@aq_mobile/ui-kit/icons';
import { dateToLocaleString, strftimeToJsDate } from '@aq_mobile/ui-kit/utils';
import styled from '@emotion/styled';
import {
  Avatar,
  Col,
  Input,
  Popconfirm,
  Row,
  Space,
  Spin,
  TableColumnsType,
} from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import localeRU from 'antd/locale/ru_RU';

import { NO_ICON } from '@/constants';
import { ApplicationsRecord } from '@/features/api';
import {
  ApplicationInfo,
  APPLICATIONS_SORT_COLUMN_SETTING,
  useApplicationEdit,
  useApplications,
} from '@/features/application';
import { useAppDispatch, useAppSelector } from '@/features/hooks';
import usePermission from '@/features/permissions/hooks/usePermission';
import {
  ReleaseStatus,
  releaseStatusToChipType,
  releaseStatusToText,
  STATE_FILTER_ITEMS,
} from '@/features/release';
import { settingsActions, settingsSelectors } from '@/features/settings';
import { rootSelectors } from '@/features/store';
import filterByTerm from '@/utils/filterByTerm';
import {
  getSortColumnSettings,
  setSortColumnSettings,
} from '@/utils/sortColumnSettings';

const TableStyled = styled(Table)`
  .ant-table-row {
    cursor: pointer;
  }
` as unknown as typeof Table;

/** Элемент фильтра для приложений с отсутствующим релизом */
const noReleaseFilterItem = {
  key: 'no-release',
  label: 'Релиз отсутствует',
};

const columnsTemplate: TableColumnsType<ApplicationsRecord> = [
  {
    key: 'icon',
    dataIndex: ['published_release', 'icon'],
    render: (iconUrl) => {
      const icon = iconUrl ?? NO_ICON;
      return (
        <Avatar
          size="small"
          src={icon}
          shape="square"
          style={{ borderWidth: 0 }}
        />
      );
    },
    width: 50,
  },
  {
    key: 'name',
    title: 'Название',
    dataIndex: 'name',
    defaultSortOrder: getSortColumnSettings(
      APPLICATIONS_SORT_COLUMN_SETTING,
      'name',
      'ascend',
    ),
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    key: 'version',
    title: 'Версия',
    dataIndex: ['published_release', 'release_name'],
    responsive: ['md'],
  },
  {
    key: 'developer',
    title: 'Разработчик',
    dataIndex: 'developer',
  },
  {
    key: 'release_date',
    title: 'Дата релиза',
    dataIndex: ['published_release', 'release_date'],
    render: (date?: string) => {
      if (!date) {
        return null;
      }

      return dateToLocaleString(strftimeToJsDate(date));
    },
    defaultSortOrder: getSortColumnSettings(
      APPLICATIONS_SORT_COLUMN_SETTING,
      'release_date',
    ),
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: (a, b) => {
      const dateA = Boolean(a.published_release?.release_date)
        ? Number(a.published_release?.release_date)
        : 0;
      const dateB = Boolean(b.published_release?.release_date)
        ? Number(b.published_release?.release_date)
        : 0;

      return dateA - dateB;
    },
  },
  {
    key: 'latest_release_state',
    title: 'Статус последнего релиза',
    dataIndex: 'latest_release_state',
    render: (status: ReleaseStatus) => {
      if (!status) {
        return <Chip type="default">Релиз отсутствует</Chip>;
      }

      return (
        <Chip type={releaseStatusToChipType(status)}>
          {releaseStatusToText(status)}
        </Chip>
      );
    },
    ellipsis: true,
    width: 220,
  },
];

const developerColumns = [
  'icon',
  'name',
  'version',
  'release_date',
  'latest_release_state',
];

const adminColumns = [
  'icon',
  'name',
  'version',
  'developer',
  'latest_release_state',
];

const PAGE_SIZE = 8;

/**
 * Страница со списком приложений
 */
export default function Applications() {
  const [currentTablePage, setCurrentTablePage] = useState(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const { isApplicationsLoading, applications } = useApplications();
  const { deleteApplication, isApplicationDeleting } = useApplicationEdit();
  const userRole = useAppSelector(rootSelectors.users.userRoleSelector);

  const navigate = useNavigate();
  const isDeleteAllowed = usePermission('applicationDelete');
  const isAddAllowed = usePermission('applicationAdd');

  const [searchTerm, setSearchTerm] = useState<string>('');

  const statusFilter = useAppSelector(
    settingsSelectors.selectApplicationsStatusFilter,
  );

  const dispatch = useAppDispatch();

  const rowSelection = useMemo(() => {
    // Если rowSelection = undefined скрывается возможность удалять записи.
    if (!isDeleteAllowed) {
      return undefined;
    }

    return { selectedRowKes: selectedRowKeys, onChange: setSelectedRowKeys };
  }, [isDeleteAllowed, selectedRowKeys]);

  const handleDelete = useCallback(() => {
    selectedRowKeys.forEach((appId) => {
      deleteApplication(appId.toString());
    });

    setSelectedRowKeys([]);
  }, [deleteApplication, selectedRowKeys]);

  const handleGoToApp = useCallback(
    ({ id }: ApplicationInfo) => {
      navigate(`${id}`);
    },
    [navigate],
  );

  const handleNewApp = useCallback(() => {
    navigate('new');
  }, [navigate]);

  const setStatusFilter = useCallback(
    (value: { key: string; label: string }) => {
      dispatch(settingsActions.setApplicationsStatusFilter(value));
    },
    [dispatch],
  );

  const tableColumnsTemplate = useMemo(() => {
    const filteredColumns = columnsTemplate.filter((column) => {
      if (!column.key) {
        return false;
      }

      if (userRole === 'admin') {
        return adminColumns.includes(column.key.toString());
      }

      return developerColumns.includes(column.key.toString());
    });

    return filteredColumns;
  }, [userRole]);

  const tableData = useMemo(() => {
    if (!applications.length) {
      return [];
    }

    const filteredData = applications.filter(({ latest_release_state }) => {
      const isStatusFilterNotSet = statusFilter.key === 'all';
      if (isStatusFilterNotSet) {
        return true;
      }

      if (
        statusFilter.key === noReleaseFilterItem.key &&
        latest_release_state === null
      ) {
        return true;
      }

      const releaseStateFilter = statusFilter.key as ReleaseStatus;

      return latest_release_state === releaseStateFilter;
    });

    const nameFiltered = filterByTerm(filteredData, searchTerm, 'name');

    return nameFiltered;
  }, [applications, searchTerm, statusFilter.key]);

  // Поддержка смены направления и колонки сортировки
  const handleTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter:
        | SorterResult<ApplicationsRecord>
        | SorterResult<ApplicationsRecord>[],
    ) => {
      if (Array.isArray(sorter) || !sorter.columnKey) {
        return;
      }

      if (['name', 'release_date'].includes(sorter.columnKey.toString())) {
        setSortColumnSettings(
          APPLICATIONS_SORT_COLUMN_SETTING,
          sorter.columnKey.toString(),
          sorter.order ?? null,
        );
      }
    },
    [],
  );

  const isApplicationsInProgress =
    isApplicationsLoading || isApplicationDeleting;

  const AddButtonTemplate = useMemo(
    () =>
      isAddAllowed ? (
        <Button type="primary" icon={<PlusIcon />} onClick={handleNewApp}>
          Добавить
        </Button>
      ) : undefined,
    [handleNewApp, isAddAllowed],
  );

  const TableControlsTemplate = useMemo(() => {
    const SearchField = (
      <Input
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Поиск"
        suffix={<MagnifyingGlassIcon />}
        allowClear
      />
    );

    const DeleteButton =
      isDeleteAllowed && Boolean(selectedRowKeys.length) ? (
        <Popconfirm
          title="Подтвердите удаление"
          description={
            selectedRowKeys.length > 1
              ? 'Вы действительно хотите удалить выбранные приложения?'
              : 'Вы действительно хотите удалить выбранное приложение?'
          }
          okText="Удалить"
          cancelText="Отмена"
          onConfirm={handleDelete}
        >
          <Button
            icon={<TrashIcon />}
            type="primary"
            title="Удалить выбранные"
          />
        </Popconfirm>
      ) : undefined;

    return (
      <Space wrap>
        {DeleteButton}
        {SearchField}
      </Space>
    );
  }, [handleDelete, isDeleteAllowed, searchTerm, selectedRowKeys.length]);

  // Набор значений для фильтра, с дополнительным значением "Релиз отсутствует"
  const stateFilterItems = useMemo(() => {
    return [noReleaseFilterItem, ...STATE_FILTER_ITEMS];
  }, []);

  return (
    <ContentCard>
      <ContentCard.Header title="Приложения" buttons={AddButtonTemplate} />

      <ContentCard.Body>
        <Row gutter={24} align={'middle'} style={{ marginBottom: 8 }}>
          <Col>{TableControlsTemplate}</Col>
          <Col flex="auto"></Col>
          <Col>
            <Space>
              <Typography.TextS>Статус:</Typography.TextS>
              <Dropdown<DropDownItemStringKey>
                items={stateFilterItems}
                selectedText={statusFilter.label}
                onSelect={setStatusFilter}
              />
            </Space>
          </Col>
          <Col>
            <PaginationTotal
              currentPage={currentTablePage}
              pageSize={PAGE_SIZE}
              total={tableData.length || 0}
            />
          </Col>
        </Row>

        <Spin spinning={isApplicationsInProgress}>
          <TableStyled
            rowSelection={rowSelection}
            dataSource={tableData}
            columns={tableColumnsTemplate}
            rowKey={'id'}
            pagination={{
              pageSize: PAGE_SIZE,
              hideOnSinglePage: false,
              onChange(page) {
                setCurrentTablePage(page);
              },
            }}
            onChange={handleTableChange}
            locale={localeRU.Table}
            onRow={(record) => {
              return {
                onClick: (e) => {
                  handleGoToApp(record);
                },
              };
            }}
            scroll={{ x: 768 }}
          />
        </Spin>
      </ContentCard.Body>
    </ContentCard>
  );
}
