import { useMemo } from 'react';
import React from 'react';
import { Button, Typography } from '@aq_mobile/ui-kit';
import { BreakPoints, trimStringToEllipsis } from '@aq_mobile/ui-kit/utils';
import styled from '@emotion/styled';
import { Avatar, Image, Spin } from 'antd';

import { NO_ICON } from '@/constants';
import useAppToken from '@/themes/useAppToken';

import { ReviewCardProps } from './ReviewCard.types';

const CardDivStyled = styled.div<{
  backgroundColor: string;
}>`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 24px;
  background: ${(props) => props.backgroundColor};
  box-sizing: border-box;
`;

const TitleDivStyled = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  margin-block-end: 16px;
`;

const ContentWrapperDivStyled = styled.div`
  display: flex;
  gap: 16px;
  align-content: stretch;
  padding: 0;

  @media ${BreakPoints.sm} {
    padding: 16px 24px;
  }
`;

const IconDivStyled = styled.div`
  width: 32px;
`;

const ContentDivStyled = styled.div`
  flex: 1 1 auto;
`;

const InfoPreStyled = styled.pre`
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0;
`;

const NoContentWrapperDivStyled = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 24px;
`;

const ContentBodyDiv = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 312px;
`;

/**
 * Карточка взятия релиза на модерацию.
 */
function ReviewCard({
  data,
  onActionClick,
  onRefreshClick,
  isPending,
  isLoading,
  isErrored,
  style,
  className,
}: ReviewCardProps) {
  const { token } = useAppToken();
  const cardBackground = token.AppReviewCard.backgroundColor;
  const noDataImage = token.AppReviewCard.emptyImageSrc;
  const errorImage = token.AppReviewCard.errorImageSrc;

  const cardIcon = useMemo(() => data?.icon ?? NO_ICON, [data]);

  const noDataTemplate = useMemo(() => {
    return (
      <NoContentWrapperDivStyled>
        <Image height={128} preview={false} src={noDataImage} />
        <Typography.Heading6 type="secondary">
          Нет приложений для проверки
        </Typography.Heading6>
      </NoContentWrapperDivStyled>
    );
  }, [noDataImage]);

  const errorTemplate = useMemo(() => {
    return (
      <NoContentWrapperDivStyled>
        <Image height={46} preview={false} src={errorImage} />
        <Typography.Heading6 style={{ maxWidth: 256, textAlign: 'center' }}>
          Не удалось загрузить информацию. Попробуйте обновить страницу
        </Typography.Heading6>
      </NoContentWrapperDivStyled>
    );
  }, [errorImage]);

  const contentTemplate = useMemo(() => {
    return (
      <ContentWrapperDivStyled>
        <IconDivStyled>
          <Avatar
            size="default"
            src={cardIcon}
            shape="square"
            style={{ borderWidth: 0 }}
          />
        </IconDivStyled>
        <ContentDivStyled style={{ overflow: 'hidden' }}>
          <Typography.Heading5 style={{ marginBlockEnd: 4 }}>
            {data?.title}
          </Typography.Heading5>

          <Typography.ParagraphS>
            <InfoPreStyled>{data?.description}</InfoPreStyled>
          </Typography.ParagraphS>
        </ContentDivStyled>
      </ContentWrapperDivStyled>
    );
  }, [cardIcon, data?.description, data?.title]);

  const template = useMemo(() => {
    if (isErrored) {
      return errorTemplate;
    }

    if (!data && !isLoading) {
      return noDataTemplate;
    }

    if (!data && isLoading) {
      return null;
    }

    return contentTemplate;
  }, [
    contentTemplate,
    data,
    errorTemplate,
    isErrored,
    isLoading,
    noDataTemplate,
  ]);

  const buttonTemplate = useMemo(() => {
    if (isErrored || !data) {
      return (
        <Button type="primary" onClick={onRefreshClick} pending={isPending}>
          Обновить
        </Button>
      );
    }

    return (
      <Button
        disabled={isErrored}
        type="primary"
        onClick={onActionClick}
        pending={isPending}
      >
        В работу
      </Button>
    );
  }, [data, isErrored, isPending, onActionClick, onRefreshClick]);

  return (
    <CardDivStyled
      style={style}
      className={className}
      backgroundColor={cardBackground}
    >
      <Spin spinning={isLoading} tip="Загрузка...">
        <ContentBodyDiv>
          <TitleDivStyled>
            <Typography.Heading5>Ожидает проверки</Typography.Heading5>
            {buttonTemplate}
          </TitleDivStyled>

          {template}
        </ContentBodyDiv>
      </Spin>
    </CardDivStyled>
  );
}

export default React.memo(ReviewCard);
