import { BreakPoints } from '@aq_mobile/ui-kit/utils';
import styled from '@emotion/styled';
import { Card, CardProps } from 'antd';

const CardStyled = styled(Card)`
  @media ${BreakPoints.sm} and ${BreakPoints.smMax} {
    padding: 18px 40px;
  }
  @media ${BreakPoints.md} {
    padding: 18px 81px;
  }
`;

export function LoginCard(props: CardProps) {
  return <CardStyled {...props} />;
}

export default LoginCard;
