import { BreakPoints } from '@aq_mobile/ui-kit/utils';
import styled from '@emotion/styled';
import { Layout } from 'antd';

const ContentStyled = styled(Layout.Content)`
  padding: 8px;
  display: flex;
  flex: 1 1 0;

  @media ${BreakPoints.xs} {
    padding: 16px;
  }
`;

export default ContentStyled;
