import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';
import { Menu as AntMenu } from 'antd';

import useAppToken from '@/themes/useAppToken';

import { MenuProps } from './Menu.types';

const MenuStyled = styled(AntMenu)``;

const NavMainLinkStyled = styled(NavLink)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  flex: 1;
  color: inherit;
  font-weight: 500;

  &::before {
    width: 0;
  }
`;

const NavAuxLinkStyled = styled(NavLink)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: inherit;
  font-weight: 500;

  &::before {
    width: 0;
  }
`;

const MenuItemWrapperStyled = styled.div`
  cursor: default;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
`;

/**
 * Главное меню приложения.
 */
function Menu({ items, onClick }: MenuProps) {
  const { token } = useAppToken();
  const iconColor = token.colorPrimary;
  const selectedItem = items.find((item) => item.selected);

  const menuItems = items.map((item) => ({
    key: item.key,
    label: (
      <MenuItemWrapperStyled>
        <NavMainLinkStyled to={item.path} onClick={onClick}>
          <span style={{ fontSize: '24px', color: iconColor }}>
            {item.icon}
          </span>
          <span>{item.label}</span>
        </NavMainLinkStyled>

        {item.subPath && (
          <NavAuxLinkStyled to={item.subPath} onClick={onClick}>
            {item.subIcon}
          </NavAuxLinkStyled>
        )}
      </MenuItemWrapperStyled>
    ),
  }));

  return (
    <MenuStyled
      mode="vertical"
      selectedKeys={selectedItem ? [selectedItem.key] : []}
      items={menuItems}
    />
  );
}

export default Menu;
