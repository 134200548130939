import { MenuItem } from '@/components';

/**
 * Заменяет идентификатор в пути, указанным значением.
 * @example
 * '/app/:id/edit' => '/app/42/edit'
 *
 * @param item элемент меню
 * @param id идентификатор, который будет подставляться вместо ':id'
 */
export function replaceIdPathParam(item: MenuItem, id: number) {
  return {
    ...item,
    path: item.path.replace(':id', id.toString()),
  };
}

/**
 * Функция, отмечающая элемент меню выделенным.
 * Для установки элемента выделенным проверяется совпадение текущей локации.
 * @param items элементы меню
 * @param location текущий URL
 */
export function setSelectedItem(items: MenuItem[], location: string) {
  let selectedItem: MenuItem | null = null;

  items.forEach((item) => {
    if (!location.startsWith(item.path)) {
      return;
    }

    /** Результат поиска может подходить под несколько выбранных элементов меню
     * Например /apps/42 и /apps/42/versions.
     * Выбранным будет считаться элемент меню с самым точным (длинным) путем.
     */
    if (selectedItem && item.path.length > selectedItem.path.length) {
      selectedItem = item;
      return;
    }

    selectedItem = item;
  });

  return items.map((i) => ({
    ...i,
    selected: i.key === selectedItem?.key,
  }));
}

export function resetSelectedItem(items: MenuItem[]) {
  return items.map((item) => ({ ...item, selected: false }));
}
