import { ThemePalette } from '@/themes';

/**
 * Возвращает линк на страницу восстановления пароля.
 */
function getRestorePasswordLink(theme: ThemePalette) {
  const url = new URL(__AUTH_ADDRESS__);
  url.searchParams.append('client_id', __CLIENT_ID__);
  url.searchParams.append('response_type', 'code');
  url.searchParams.append('scope', 'read');
  url.searchParams.append('state', __APP_NAME__);
  url.searchParams.append('redirect_uri', __REDIRECT_URI__);
  url.searchParams.append('mode', 'restore_password');
  url.searchParams.append('theme', theme);
  url.searchParams.append('display_section', 'restore');

  return url.toString();
}

export default getRestorePasswordLink;
