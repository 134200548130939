import { useMemo, useState } from 'react';
import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Layout } from 'antd';

import { Content, Header, Sider, SiderCollapseType } from '@/components';
import { applicationSelector } from '@/features/application/application.selectors';
import { useAppSelector } from '@/features/hooks';
import { APP_MENU_ITEMS, MENU_ITEMS } from '@/features/menuItems';
import { useReleases } from '@/features/release';
import { rootSelectors } from '@/features/store';

import {
  replaceIdPathParam,
  resetSelectedItem,
  setSelectedItem,
} from './DashboardRoot.utils';

/**
 * Компонент общий компонент, соответствующий пути "/", после логина пользователя.
 */
function DashboardRoot() {
  const [collapsedType, setCollapsedType] =
    useState<SiderCollapseType>('manual');
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const { id: appId, name: appName } = useAppSelector(applicationSelector);
  const isAppSelected = appId !== null;
  const { publishedRelease } = useReleases(appId?.toString() ?? undefined);
  const { icon } = publishedRelease || {};
  const userRole = useAppSelector(rootSelectors.users.userRoleSelector);
  const { pathname: locationPath } = useLocation();

  const mainMenuItems = useMemo(() => {
    let items = MENU_ITEMS.filter((item) => {
      if (!userRole) {
        return false;
      }

      return !item.roles || item.roles?.includes(userRole);
    });

    if (!isAppSelected) {
      return setSelectedItem(items, locationPath);
    }

    return resetSelectedItem(items);
  }, [isAppSelected, locationPath, userRole]);

  const appMenuItems = useMemo(() => {
    if (!isAppSelected) {
      return [];
    }

    let items = APP_MENU_ITEMS.filter((item) => {
      if (!userRole) {
        return false;
      }

      return !item.roles || item.roles?.includes(userRole);
    }).map((item) => replaceIdPathParam(item, appId));

    items = setSelectedItem(items, locationPath);

    return items;
  }, [isAppSelected, locationPath, appId, userRole]);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
    setCollapsedType('manual');
  };

  const setCollapse = (collapsed: boolean, type: SiderCollapseType) => {
    setIsCollapsed(collapsed);
    setCollapsedType(type);
  };

  return (
    <Layout>
      <Sider
        collapsed={isCollapsed}
        collapsedType={collapsedType}
        onCollapse={setCollapse}
        items={mainMenuItems}
        appName={appName}
        appIconLink={icon?.file}
        subItems={appMenuItems}
      />
      <Layout>
        <Header
          isSiderCollapsed={isCollapsed}
          onCollapseClick={toggleCollapse}
        />
        <Content>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}

export default React.memo(DashboardRoot);
