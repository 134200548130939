import { useContext, useEffect } from 'react';

import {
  ServerError,
  useApproveReleaseMutation,
  useBanReleaseMutation,
  useEditReleaseStateMutation,
  usePublishReleaseMutation,
  useRejectReleaseMutation,
  useSendForReviewReleaseMutation,
  useTakeForReviewReleaseMutation,
} from '@/features/api';
import { getServerErrorDescriptions } from '@/features/api/api.utils';
import { NotificationContext } from '@/utils/notification-context';

/**
 * Хук, для изменения состояния (статуса) релиза.
 */
function useModifyReleaseState() {
  const notificationContext = useContext(NotificationContext);
  const [
    approve,
    {
      isLoading: isApproveLoading,
      isError: isApproveError,
      error: approveError,
    },
  ] = useApproveReleaseMutation();
  const [
    ban,
    { isLoading: isBanLoading, isError: isBanError, error: banError },
  ] = useBanReleaseMutation();
  const [
    publish,
    {
      isLoading: isPublishLoading,
      isError: isPublishError,
      error: publishError,
    },
  ] = usePublishReleaseMutation();
  const [
    reject,
    { isLoading: isRejectLoading, isError: isRejectError, error: rejectError },
  ] = useRejectReleaseMutation();
  const [
    sendForReview,
    {
      isLoading: isSendForReviewLoading,
      isError: isSendForReviewError,
      error: sendForReviewError,
    },
  ] = useSendForReviewReleaseMutation();
  const [
    takeForReview,
    {
      isLoading: isTakeForReviewLoading,
      isError: isTakeForReviewError,
      error: takeForReviewError,
    },
  ] = useTakeForReviewReleaseMutation();
  const [
    editReleaseState,
    {
      isLoading: isEditReleaseStateLoading,
      isError: isEditReleaseStateError,
      error: editReleaseStateError,
    },
  ] = useEditReleaseStateMutation();

  useEffect(() => {
    if (!approveError) {
      return;
    }

    const messages = getServerErrorDescriptions(
      approveError as unknown as ServerError,
    );

    notificationContext.showError('Ошибка во время одобрения релиза', messages);
    console.error('Ошибка во время одобрения релиза: ', approveError);
  }, [approveError, notificationContext]);

  useEffect(() => {
    if (!banError) {
      return;
    }

    const messages = getServerErrorDescriptions(
      banError as unknown as ServerError,
    );

    notificationContext.showError('Ошибка снятия релиза', messages);
    console.error('Ошибка снятия релиза: ', banError);
  }, [banError, notificationContext]);

  useEffect(() => {
    if (!publishError) {
      return;
    }

    const messages = getServerErrorDescriptions(
      publishError as unknown as ServerError,
    );

    notificationContext.showError('Ошибка публикации релиза', messages);
    console.error('Ошибка публикации релиза: ', publishError);
  }, [publishError, notificationContext]);

  useEffect(() => {
    if (!rejectError) {
      return;
    }

    const messages = getServerErrorDescriptions(
      rejectError as unknown as ServerError,
    );

    notificationContext.showError(
      'Ошибка запрета на публикацию релиза',
      messages,
    );
    console.error('Ошибка запрета на публикацию релиза: ', rejectError);
  }, [rejectError, notificationContext]);

  useEffect(() => {
    if (!sendForReviewError) {
      return;
    }

    const messages = getServerErrorDescriptions(
      sendForReviewError as unknown as ServerError,
    );

    notificationContext.showError(
      'Ошибка во время отправки на модерацию',
      messages,
    );
    console.error('Ошибка отправки на модерацию: ', sendForReviewError);
  }, [sendForReviewError, notificationContext]);

  useEffect(() => {
    if (!takeForReviewError) {
      return;
    }

    const messages = getServerErrorDescriptions(
      takeForReviewError as unknown as ServerError,
    );

    notificationContext.showError(
      'Ошибка во время взятия релиза на модерацию',
      messages,
    );
    console.error('Ошибка взятия релиза на модерацию: ', takeForReviewError);
  }, [takeForReviewError, notificationContext]);

  useEffect(() => {
    if (!editReleaseStateError) {
      return;
    }

    const messages = getServerErrorDescriptions(
      editReleaseStateError as unknown as ServerError,
    );

    notificationContext.showError(
      'Ошибка во время изменения статуса релиза',
      messages,
    );
    console.error(
      'Ошибка во время изменения статуса релиза: ',
      editReleaseStateError,
    );
  }, [editReleaseStateError, notificationContext]);

  return {
    approve,
    isApproveLoading,
    isApproveError,
    approveError,
    ban,
    isBanLoading,
    isBanError,
    banError,
    publish,
    isPublishLoading,
    isPublishError,
    publishError,
    reject,
    isRejectLoading,
    isRejectError,
    rejectError,
    sendForReview,
    isSendForReviewLoading,
    isSendForReviewError,
    sendForReviewError,
    takeForReview,
    isTakeForReviewLoading,
    isTakeForReviewError,
    takeForReviewError,
    editReleaseState,
    isEditReleaseStateLoading,
    isEditReleaseStateError,
    editReleaseStateError,
  };
}

export default useModifyReleaseState;
