import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '@/features/hooks';
import { rootActions } from '@/features/store';

export default function LogOut() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(rootActions.user.logOut());
    // Перезагружаем страницу целиком, чтобы сбросить хранилище и другие временные значения.
    window.location.href = '/';
  }, [dispatch, navigate]);

  return <></>;
}
