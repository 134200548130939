import { createContext } from 'react';

import type { ThemePalette } from '@/themes';

export const ThemeContext = createContext<{
  theme: ThemePalette;
  setTheme: (value: ThemePalette) => void;
}>({
  theme: 'light',
  setTheme: (value) => {},
});
