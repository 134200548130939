import { useAppSelector } from '@/features/hooks';
import { rootSelectors } from '@/features/store';
import { UserRole } from '@/features/user';

export type PermissionKey =
  | 'applicationAdd'
  | 'applicationDelete'
  | 'applicationEdit'
  | 'applicationReview'
  | 'storeAdd'
  | 'storeEdit'
  | 'storeDelete'
  | 'releaseAdd'
  | 'releaseDelete'
  | 'releaseReview'
  | 'releasePublish'
  | 'releaseUnpublish';

export const permissions: {
  [key in PermissionKey]: Array<UserRole>;
} = {
  /** Добавление приложения */
  applicationAdd: ['developer'],
  /** Удаление приложения */
  applicationDelete: ['admin', 'developer'],
  /** Редактирование приложения */
  applicationEdit: ['admin', 'developer'],
  applicationReview: ['moderator'],
  storeAdd: ['admin'],
  storeEdit: ['admin'],
  storeDelete: ['admin'],
  /** Работа с релизами */
  releaseAdd: ['developer'],
  releaseDelete: ['developer', 'admin'],
  releaseReview: ['moderator'],
  /** Публикация релиза */
  releasePublish: ['developer'],
  /** Снятие релиза с публикации */
  releaseUnpublish: ['admin', 'developer'],
};

/**
 * Хук, использующийся для проверки прав доступа конкретного пользователя до
 * различного функционала в приложении.
 * @param role роль пользователя
 * @param permission запрашиваемое значение права доступа.
 * @returns
 */
function usePermission(permission: PermissionKey) {
  const role = useAppSelector(rootSelectors.users.userRoleSelector);
  const permissionRoles = permissions[permission];

  if (!permissionRoles) {
    console.error(
      `Невозможно проверить право на ${permission}. Данное право отсутствует в списке прав.`,
    );
    return false;
  }

  if (!role) {
    console.error(
      `Невозможно проверить разрешения доступа для текущего пользователя. Роль текущего пользователя не установлена.`,
    );
    return false;
  }

  return permissionRoles.includes(role);
}

export default usePermission;
