import { useMemo } from 'react';
import styled from '@emotion/styled';
import { Col, Row } from 'antd';

import { NO_VALUE_SYMBOL } from '@/constants';

import ApkField from './ApkField';
import { APKInfoProps } from './ApkInfo.types';

const fileNameRegex = new RegExp(/.*\/(.+\.apk)$/);

const PreFormattedText = styled.pre`
  margin: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: Golos;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.375;
`;

/**
 * Компонент информации об APK файле
 */
function APKInfo({
  applicationId,
  changes,
  comment,
  date,
  file,
  size,
  version,
  versionCode,
  maxComponentWidth = 562,
}: APKInfoProps) {
  const fileName = useMemo(() => {
    if (!file) {
      return null;
    }

    const match = file.match(fileNameRegex);
    if (match && match.length >= 1) {
      return match[1];
    }

    return null;
  }, [file]);

  return (
    <div style={{ maxWidth: maxComponentWidth }}>
      <Row
        gutter={[{ xs: 16, sm: 32, md: 64 }, 64]}
        style={{ marginBlockEnd: 32 }}
      >
        <Col>
          Файл: {file ? <a href={file}>{fileName}</a> : NO_VALUE_SYMBOL}
        </Col>
      </Row>

      <Row
        gutter={[{ xs: 16, sm: 32, md: 64 }, 64]}
        style={{ marginBlockEnd: 64 }}
      >
        <Col xs={24} sm={12} md={8}>
          <ApkField
            title="Идентификатор приложения (android application ID):"
            value={applicationId}
          />
        </Col>
        <Col xs={24} sm={12} md={8}>
          <ApkField
            title="Название версии приложения (android application version name):"
            value={version}
          />
        </Col>
        <Col xs={24} sm={12} md={8}>
          <ApkField
            title="Дата выпуска текущей версии приложения:"
            value={date}
          />
        </Col>
      </Row>

      <Row
        gutter={[{ xs: 16, sm: 32, md: 64 }, 8]}
        style={{ marginBlockEnd: 64 }}
      >
        <Col xs={24} sm={12} md={8}>
          <ApkField title="Описание изменений в текущей версии приложения:" />
        </Col>
        <Col xs={24} sm={12} md={16}>
          <PreFormattedText>{changes}</PreFormattedText>
        </Col>
      </Row>

      <Row
        gutter={[{ xs: 16, sm: 32, md: 64 }, 64]}
        style={{ marginBlockEnd: 64 }}
      >
        <Col xs={24} sm={12} md={8}>
          <ApkField
            title="Код версии приложения (android application version code):"
            value={versionCode}
          />
        </Col>
        <Col xs={24} sm={12} md={8}>
          <ApkField
            title={'Размер бинарного файла приложения в байтах:'}
            value={size}
          />
        </Col>
      </Row>

      {comment && (
        <Row
          gutter={[{ xs: 16, sm: 32, md: 64 }, 64]}
          style={{ marginBlockEnd: 64 }}
        >
          <Col xs={8} sm={8} md={8}>
            <ApkField title="Комментарий для модератора:" />
          </Col>
          <Col xs={16} sm={16} md={16}>
            <ApkField value={comment} isBigValue />
          </Col>
        </Row>
      )}
    </div>
  );
}

export default APKInfo;
