import { useContext, useEffect } from 'react';
import { valueExists } from '@aq_mobile/ui-kit/utils';

import { ServerError, useGetReleaseStateQuery } from '@/features/api';
import { getServerErrorDescriptions } from '@/features/api/api.utils';
import { NotificationContext } from '@/utils/notification-context';

/**
 * Хук, для получения состояния (статуса) релиза.
 */
function useReleaseState(application_id: string, id?: number) {
  const notificationContext = useContext(NotificationContext);
  const { data, isLoading, error } = useGetReleaseStateQuery(
    { application_id, id: id || 0 },
    { skip: !valueExists(id) },
  );

  useEffect(() => {
    if (!error) {
      return;
    }

    const messages = getServerErrorDescriptions(
      error as unknown as ServerError,
    );

    notificationContext.showError(
      'Ошибка во время получения статуса релиза',
      messages,
    );
    console.error('Ошибка во время получения статуса релиза: ', error);
  }, [error, notificationContext]);

  return {
    data,
    isLoading,
    error,
  };
}

export default useReleaseState;
