import { useContext, useEffect } from 'react';

import { useGetCategoriesQuery } from '@/features/api';
import { Category } from '@/features/category';
import { NotificationContext } from '@/utils/notification-context';

/**
 * Хук для работы с категориями.
 */
function useCategories() {
  const notificationContext = useContext(NotificationContext);
  const {
    isLoading: isCategoriesLoading,
    data,
    error: categoriesError,
  } = useGetCategoriesQuery();

  const categories = (data?.results as Array<Category>) || [];

  useEffect(() => {
    if (!categoriesError) {
      return;
    }

    notificationContext.showError(
      'Ошибка',
      'Произошла ошибка при получении списка категорий с сервера.',
    );
  }, [categoriesError, notificationContext]);

  return {
    isCategoriesLoading,
    categories,
    categoriesError,
  };
}

export default useCategories;
