import { useCallback, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { ErrorBoundary } from '@aq_mobile/ui-kit';
import { settings } from '@aq_mobile/ui-kit/utils';
import { ConfigProvider, notification } from 'antd';
import localeRU from 'antd/locale/ru_RU';

import { App } from '@/components';
import { darkTheme, lightTheme, type ThemePalette } from '@/themes';
import {
  NotificationContext,
  NotificationFunction,
} from '@/utils/notification-context';
import { ThemeContext } from '@/utils/theme-context';

function Root() {
  const [theme, setTheme] = useState<ThemePalette>(getThemePalette);
  const [api, contextHolder] = notification.useNotification();
  const notificationContextValue = useMemo(() => {
    const showError: NotificationFunction = (message, description) => {
      let descriptionTemplate = Array.isArray(description)
        ? description.map((d, index) => <p key={index}>{d}</p>)
        : description;

      api.error({
        message,
        description: descriptionTemplate,
        duration: 20,
      });
    };

    const showSuccess: NotificationFunction = (message, description) => {
      let descriptionTemplate = Array.isArray(description)
        ? description.map((d, index) => <p key={index}>{d}</p>)
        : description;

      api.success({
        message,
        description: descriptionTemplate,
        duration: 20,
      });
    };

    const showWarning: NotificationFunction = (message, description) => {
      let descriptionTemplate = Array.isArray(description)
        ? description.map((d, index) => <p key={index}>{d}</p>)
        : description;

      api.warning({
        message,
        description: descriptionTemplate,
        duration: 15,
      });
    };

    return {
      notificationApi: api,
      showError,
      showSuccess,
      showWarning,
    };
  }, [api]);

  const goToMainPage = useCallback(() => {
    window.location.href = __APP_ROOT_URI__;
  }, []);

  const currentTheme = useMemo(
    () => (theme === 'light' ? lightTheme : darkTheme),
    [theme],
  );

  const themeContextValue = useMemo(() => {
    return {
      theme: theme,
      setTheme: (theme: ThemePalette) => {
        settings.set('theme', theme);
        setGlobalTheme(theme);
        setTheme(theme);
      },
    };
  }, [theme]);

  return (
    <>
      <ThemeContext.Provider value={themeContextValue}>
        <NotificationContext.Provider value={notificationContextValue}>
          <ConfigProvider theme={currentTheme} locale={localeRU}>
            <App>
              <ErrorBoundary
                buttonTitle="Вернуться на главную"
                onClick={goToMainPage}
              >
                <Outlet />
              </ErrorBoundary>
            </App>
          </ConfigProvider>
        </NotificationContext.Provider>
      </ThemeContext.Provider>

      {contextHolder}
    </>
  );
}

export default Root;
