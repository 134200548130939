import { createBrowserRouter, Outlet } from 'react-router-dom';

import {
  Application,
  ApplicationHistory,
  ApplicationNew,
  ApplicationRoot,
  Applications,
  Categories,
  Dashboard,
  DashboardRoot,
  Login,
  LoginRoot,
  LogOut,
  NotFound,
  Profile,
  ProtectedRoute,
  Release,
  ReleaseNew,
  Releases,
  ReleasesAdmin,
  Review,
  ReviewRelease,
  ReviewReleases,
  Root,
  StoreEdit,
  StoreNewVersion,
  StoreUpdates,
  Users,
} from '@/routes';

const notFoundRoute = {
  element: <NotFound />,
  path: '*',
};

const noLoginRoutes = {
  element: <LoginRoot />,
  children: [
    {
      index: true,
      element: <Login />,
    },
    {
      path: 'logout',
      element: <LogOut />,
    },
  ],
};

const authorizedOnlyRoutes = {
  element: <ProtectedRoute roles={[]} />,
  children: [
    {
      element: <DashboardRoot />,
      children: [
        {
          element: (
            <ProtectedRoute
              roles={['admin', 'developer']}
              redirectTo="/dashboard"
            />
          ),
          children: [
            {
              path: 'apps',
              element: <Applications />,
            },
            {
              path: '/apps/new',
              element: <ApplicationNew />,
            },
          ],
        },

        {
          element: (
            <ProtectedRoute
              roles={['admin', 'developer', 'moderator']}
              redirectTo="/dashboard"
            />
          ),
          children: [
            {
              path: '/apps/:id',
              element: <ApplicationRoot />,
              children: [
                {
                  index: true,
                  element: <Application />,
                },

                /** Адреса, доступные разработчику */
                {
                  element: (
                    <ProtectedRoute
                      roles={['developer']}
                      redirectTo="/dashboard"
                    />
                  ),
                  children: [
                    {
                      path: 'releases',
                      element: <Outlet />,
                      children: [
                        {
                          index: true,
                          element: <Releases />,
                        },
                        {
                          path: 'new',
                          element: <ReleaseNew />,
                        },
                        {
                          path: ':releaseId',
                          element: <Release />,
                        },
                      ],
                    },
                  ],
                },

                /** Адреса, доступные администратору */
                {
                  element: (
                    <ProtectedRoute roles={['admin']} redirectTo="/dashboard" />
                  ),
                  children: [
                    {
                      path: 'releases-admin',
                      element: <Outlet />,
                      children: [
                        {
                          index: true,
                          element: <ReleasesAdmin />,
                        },
                        {
                          path: ':releaseId',
                          element: <Release />,
                        },
                      ],
                    },
                  ],
                },

                /** Адреса, доступные модератору */
                {
                  element: (
                    <ProtectedRoute
                      roles={['moderator']}
                      redirectTo="/dashboard"
                    />
                  ),
                  children: [
                    {
                      path: 'release',
                      element: <Outlet />,
                      children: [
                        {
                          index: true,
                          element: <ReviewRelease />,
                        },
                      ],
                    },
                  ],
                },

                {
                  path: 'history',
                  element: <ApplicationHistory />,
                },
              ],
            },
          ],
        },

        {
          element: (
            <ProtectedRoute roles={['moderator']} redirectTo="/dashboard" />
          ),
          children: [
            {
              path: 'review',
              element: <Review />,
            },
            {
              path: 'review-releases',
              element: <ReviewReleases />,
            },
          ],
        },

        {
          path: 'dashboard',
          element: <Dashboard />,
        },

        {
          element: <ProtectedRoute roles={['admin']} redirectTo="/dashboard" />,
          children: [
            {
              path: 'store-updates',
              element: <StoreUpdates />,
            },
            {
              path: 'store-updates/new',
              element: <StoreNewVersion />,
            },
            {
              path: 'store-updates/:id',
              element: <StoreEdit />,
            },
            {
              path: 'users',
              element: <Users />,
            },
            {
              path: 'categories',
              element: <Categories />,
            },
          ],
        },
        {
          element: (
            <ProtectedRoute
              roles={['admin', 'developer', 'moderator', 'user']}
              redirectTo="/dashboard"
            />
          ),
          children: [
            {
              path: 'users/profile',
              element: <Profile />,
            },
          ],
        },
      ],
    },
  ],
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [notFoundRoute, noLoginRoutes, authorizedOnlyRoutes],
  },
]);

export default router;
