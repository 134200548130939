import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ContentCard, PaginationTotal, Table } from '@aq_mobile/ui-kit';
import {
  ArrowDownToLineIcon,
  MagnifyingGlassIcon,
} from '@aq_mobile/ui-kit/icons';
import {
  dateToLocaleString,
  goToURL,
  strftimeToJsDate,
} from '@aq_mobile/ui-kit/utils';
import styled from '@emotion/styled';
import { Col, Input, Row, Spin, TableColumnsType } from 'antd';
import localeRU from 'antd/locale/ru_RU';

import { useGetReviewReleasesQuery } from '@/features/api';
import { Release } from '@/features/release';
import filterByTerm from '@/utils/filterByTerm';

const APKButtonStyled = styled(Button)``;

const TableStyled = styled(Table)`
  .ant-table-row {
    cursor: pointer;
  }
` as unknown as typeof Table;

const columnsTemplate: TableColumnsType<Release> = [
  {
    key: 'name',
    title: 'Название',
    dataIndex: ['application', 'name'],
    defaultSortOrder: 'descend',
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: (a, b) => {
      const dateA = strftimeToJsDate(a.application.name);
      const dateB = strftimeToJsDate(b.application.name);

      if (dateA === null) {
        return 1;
      }

      if (dateB === null) {
        return -1;
      }

      return Number(dateA) - Number(dateB);
    },
  },
  {
    key: 'apk',
    title: 'APK',
    dataIndex: ['apk', 'file'],
    responsive: ['md'],
    render: (apkLink?: string) => {
      if (!apkLink) {
        return null;
      }

      return (
        <APKButtonStyled
          icon={<ArrowDownToLineIcon />}
          onClick={(e) => {
            e.stopPropagation();
            goToURL(apkLink);
          }}
        />
      );
    },
  },
  {
    key: 'updated_at',
    title: 'Дата изменения',
    dataIndex: ['releasestate', 'updated_at'],
    render: (dateStr?: string) => {
      if (!dateStr) {
        return null;
      }

      return dateToLocaleString(strftimeToJsDate(dateStr));
    },
    ellipsis: true,
  },
  {
    key: 'developer',
    title: 'Разработчик',
    dataIndex: ['application', 'developer'],
  },
];

const PAGE_SIZE = 8;

/**
 * Страница со списком приложений
 */
export default function Applications() {
  const [currentAppTablePage, setCurrentAppTablePage] = useState(1);
  const { data, isLoading } = useGetReviewReleasesQuery();

  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState<string>('');

  const handleGoToRelease = useCallback(
    ({ id, application: { id: application_id } }: Release) => {
      navigate(`/apps/${application_id}/release/`);
    },
    [navigate],
  );

  const tableData = useMemo(() => {
    const releases = data?.results || [];

    if (!releases.length) {
      return [];
    }

    const nameFiltered = filterByTerm(releases, searchTerm, [
      'application',
      'name',
    ]);

    return nameFiltered;
  }, [data?.results, searchTerm]);

  const TableControlsTemplate = useMemo(() => {
    const SearchField = (
      <Input
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Поиск"
        suffix={<MagnifyingGlassIcon />}
        allowClear
      />
    );
    return SearchField;
  }, [searchTerm]);

  return (
    <ContentCard>
      <ContentCard.Header title="Приложения на проверке" />

      <ContentCard.Body>
        <Row gutter={24} align={'middle'} style={{ marginBottom: 8 }}>
          <Col>{TableControlsTemplate}</Col>
          <Col flex="auto"></Col>
          <Col>
            <PaginationTotal
              currentPage={currentAppTablePage}
              pageSize={PAGE_SIZE}
              total={tableData.length || 0}
            />
          </Col>
        </Row>

        <Spin spinning={isLoading}>
          <TableStyled
            dataSource={tableData}
            columns={columnsTemplate}
            rowKey={'id'}
            pagination={{
              pageSize: PAGE_SIZE,
              hideOnSinglePage: false,
              onChange(page) {
                setCurrentAppTablePage(page);
              },
            }}
            locale={localeRU.Table}
            onRow={(record) => {
              return {
                onClick: (e) => {
                  handleGoToRelease(record);
                },
              };
            }}
            scroll={{ x: 768 }}
          />
        </Spin>
      </ContentCard.Body>
    </ContentCard>
  );
}
