import { ServerError, ServerErrorData } from '@/features/api';

/**
 * Возвращает текстовое описание ошибки
 * @param errorData объект, содержащий ошибки.
 */
export function getServerErrorMessage(errorData: ServerErrorData | undefined) {
  const errorMessages: Array<string> = errorData?.values || [];

  return errorMessages;
}

/**
 * Возвращает массив описаний ошибок.
 * Объект @param fieldMap содержит сопоставление ключей полей
 * ошибок и названий поля в интерфейсе.
 * Неизвестные ошибки попадают в массив без имени.
 *
 * Пример:
 * [
 * "Иконка: Некорректный формат изображения",
 * "Название: не должно начинаться с маленькой буквы",
 * "Форма не может быть сохранена"
 * ]
 *
 * @param serverError объект, содержащий серверные ошибки. Формат: {data: {FieldName: ErrorDescription[]}}
 * @param fieldMap объект, содержащий сопоставления имени поля и его текстового значения.
 */
export function getServerErrorDescriptions(
  serverError: ServerError,
  fieldsMap?: { [key: string]: string },
): Array<string> | undefined {
  const errorData = serverError?.data;

  if (!errorData) {
    return undefined;
  }

  // Если набор полей не передан, все ошибки собираем в один массив.
  if (fieldsMap === undefined) {
    return Object.values(errorData).flat();
  }

  // Если передан набор полей, собираем их в форматированном виде.
  const errorDescription: Array<string[]> = [];
  Object.entries(errorData).forEach(([key, errors]) => {
    if (!(key in fieldsMap)) {
      errorDescription.push(errors);
      return;
    }

    errorDescription.push(errors.map((error) => `${fieldsMap[key]}: ${error}`));
  });

  return errorDescription.flat();
}
