import { useStoreGetQuery } from '@/features/api';

/**
 * Хук, для получения информации о релизе магазина приложений
 * @param apkId идентификатор релиза
 */
function useStoreAPK(apkId: string | undefined) {
  const {
    isLoading: isStoreAPKLoading,
    data: storeAPK,
    error: getStoreAPKError,
  } = useStoreGetQuery(apkId, { skip: Boolean(!apkId) });

  return {
    isStoreAPKLoading,
    storeAPK,
    getStoreAPKError,
  };
}

export default useStoreAPK;
