import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Chip,
  ContentCard,
  Dropdown,
  DropDownItemStringKey,
  PaginationTotal,
  Table,
  Typography,
} from '@aq_mobile/ui-kit';
import { PlusIcon, TrashIcon } from '@aq_mobile/ui-kit/icons';
import { dateToLocaleString, strftimeToJsDate } from '@aq_mobile/ui-kit/utils';
import styled from '@emotion/styled';
import { Col, Popconfirm, Row, Space, Spin, TableColumnsType } from 'antd';
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from 'antd/es/table/interface';
import localeRU from 'antd/locale/ru_RU';

import usePermission from '@/features/permissions/hooks/usePermission';
import {
  APP_STORE_SORT_COLUMN_SETTING,
  StoreAPK,
  useStoreAPKEdit,
  useStoreAPKs,
} from '@/features/storeApk';
import {
  getSortColumnSettings,
  setSortColumnSettings,
} from '@/utils/sortColumnSettings';

const TableStyled = styled(Table)`
  .ant-table-row {
    cursor: pointer;
  }
` as unknown as typeof Table;

const columnsTemplate: TableColumnsType<StoreAPK> = [
  {
    key: 'release_name',
    title: 'Версия',
    dataIndex: 'release_name',
    defaultSortOrder: getSortColumnSettings(
      APP_STORE_SORT_COLUMN_SETTING,
      'release_name',
    ),
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: (a, b) => a.release_name.localeCompare(b.release_name),
  },
  {
    key: 'release_date',
    title: 'Добавлено',
    dataIndex: 'release_date',
    defaultSortOrder: getSortColumnSettings(
      APP_STORE_SORT_COLUMN_SETTING,
      'release_date',
      'descend',
    ),
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: (a: StoreAPK, b: StoreAPK) => {
      const dateA = Number(a.release_date);
      const dateB = Number(b.release_date);

      return dateA - dateB;
    },
    render: (dateStr: string) => {
      return dateToLocaleString(strftimeToJsDate(dateStr));
    },
  },
  {
    key: 'status',
    title: 'Статус',
    dataIndex: 'approved',
    render: (approved: boolean) =>
      approved ? (
        <Chip type="success">Одобрено</Chip>
      ) : (
        <Chip type="error">Не одобрено</Chip>
      ),
    onFilter: (value, record) => {
      if (value === 'all') {
        return true;
      }

      return record.approved === value;
    },
    ellipsis: true,
  },
];

const approvedFilterItems = [
  {
    key: 'approved',
    label: 'Одобрено',
  },
  {
    key: 'disapproved',
    label: 'Не одобрено',
  },
  {
    key: 'all',
    label: 'Все',
  },
];

const PAGE_SIZE = 8;

/**
 * Страница списка релизов магазина приложений.
 */
export default function StoreUpdates() {
  const [currentTablePage, setCurrentAppTablePage] = useState(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const { isStoreAPKsLoading, storeAPKs } = useStoreAPKs();
  const { deleteStoreAPK, isStoreAPKDeleting } = useStoreAPKEdit();

  const navigate = useNavigate();
  const isDeleteAllowed = usePermission('storeDelete');
  const isAddAllowed = usePermission('storeAdd');

  const [currentStatusFilter, setCurrentStatusFilter] = useState<{
    key: string;
    label: string;
  }>({
    key: 'all',
    label: 'Все',
  });

  const rowSelection = useMemo(() => {
    // Если rowSelection = undefined скрывается возможность удалять записи.
    if (!isDeleteAllowed) {
      return undefined;
    }

    return {
      selectedRowKes: selectedRowKeys,
      onChange: setSelectedRowKeys,
    };
  }, [isDeleteAllowed, selectedRowKeys]);

  const handleDelete = useCallback(() => {
    selectedRowKeys.forEach((id) => {
      deleteStoreAPK(id.toString());
    });

    setSelectedRowKeys([]);
  }, [deleteStoreAPK, selectedRowKeys]);

  const handleGoToApk = useCallback(
    ({ id }: StoreAPK) => {
      navigate(`${id}`);
    },
    [navigate],
  );

  const handleNewApk = useCallback(() => {
    navigate('new');
  }, [navigate]);

  // Поддержка смены направления и колонки сортировки
  const handleTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<StoreAPK> | SorterResult<StoreAPK>[],
    ) => {
      if (Array.isArray(sorter) || !sorter.columnKey) {
        return;
      }

      if (
        ['release_name', 'release_date'].includes(sorter.columnKey.toString())
      ) {
        setSortColumnSettings(
          APP_STORE_SORT_COLUMN_SETTING,
          sorter.columnKey.toString(),
          sorter.order ?? null,
        );
      }
    },
    [],
  );

  const tableData = useMemo(() => {
    if (!storeAPKs.length) {
      return [];
    }

    const filteredData = storeAPKs.filter((row) => {
      switch (currentStatusFilter.key) {
        case 'approved':
          return row.approved;
        case 'disapproved':
          return !row.approved;
        default:
          return true;
      }
    });
    return filteredData;
  }, [storeAPKs, currentStatusFilter.key]);

  const isPageInProgress = isStoreAPKsLoading || isStoreAPKDeleting;

  return (
    <ContentCard>
      <ContentCard.Header
        title="Обновления магазина приложений"
        buttons={
          isAddAllowed ? (
            <Button type="primary" icon={<PlusIcon />} onClick={handleNewApk}>
              Добавить
            </Button>
          ) : undefined
        }
      />

      <ContentCard.Body>
        <Row gutter={24} align={'middle'} style={{ marginBottom: 8 }}>
          <Col>
            {Boolean(selectedRowKeys.length) && (
              <Popconfirm
                title="Подтвердите удаление"
                description={
                  selectedRowKeys.length > 1
                    ? 'Вы действительно хотите удалить выбранные релизы?'
                    : 'Вы действительно хотите удалить выбранный релиз?'
                }
                okText="Удалить"
                cancelText="Отмена"
                onConfirm={handleDelete}
              >
                <Button
                  icon={<TrashIcon />}
                  type="primary"
                  title="Удалить выбранные"
                />
              </Popconfirm>
            )}
          </Col>
          <Col flex="auto"></Col>
          <Col>
            <Space>
              <Typography.TextS>Статус:</Typography.TextS>
              <Dropdown<DropDownItemStringKey>
                items={approvedFilterItems}
                selectedText={currentStatusFilter.label}
                onSelect={setCurrentStatusFilter}
              />
            </Space>
          </Col>
          <Col>
            <PaginationTotal
              currentPage={currentTablePage}
              pageSize={PAGE_SIZE}
              total={tableData.length || 0}
            />
          </Col>
        </Row>

        <Spin spinning={isPageInProgress}>
          <TableStyled
            rowSelection={rowSelection}
            dataSource={tableData}
            columns={columnsTemplate}
            rowKey={'id'}
            pagination={{
              pageSize: PAGE_SIZE,
              hideOnSinglePage: false,
              onChange(page) {
                setCurrentAppTablePage(page);
              },
            }}
            onChange={handleTableChange}
            locale={localeRU.Table}
            onRow={(record) => {
              return {
                onClick: (e) => {
                  handleGoToApk(record);
                },
              };
            }}
          />
        </Spin>
      </ContentCard.Body>
    </ContentCard>
  );
}
