import { useContext, useEffect } from 'react';

import {
  ServerError,
  useAddCategoryMutation,
  useDeleteCategoryMutation,
  useUpdateCategoryMutation,
} from '@/features/api';
import { getServerErrorDescriptions } from '@/features/api/api.utils';
import { NotificationContext } from '@/utils/notification-context';

function useEditCategory() {
  const notificationContext = useContext(NotificationContext);
  const [
    addCategory,
    {
      isLoading: isCategoryAdding,
      error: addCategoryError,
      isSuccess: isAddCategorySuccess,
    },
  ] = useAddCategoryMutation();

  const [
    deleteCategory,
    {
      isLoading: isCategoryDeleting,
      error: deleteCategoryError,
      isSuccess: isDeleteCategorySuccess,
    },
  ] = useDeleteCategoryMutation();

  const [
    updateCategory,
    {
      isLoading: isCategoryUpdating,
      error: updateCategoryError,
      isSuccess: isUpdateCategorySuccess,
    },
  ] = useUpdateCategoryMutation();

  useEffect(() => {
    if (!deleteCategoryError) {
      return;
    }

    const errors = getServerErrorDescriptions(
      deleteCategoryError as unknown as ServerError,
    );

    notificationContext.showError('Ошибка удаления категории', errors);
  }, [deleteCategoryError, notificationContext]);

  useEffect(() => {
    if (!addCategoryError) {
      return;
    }

    const errors = getServerErrorDescriptions(
      addCategoryError as unknown as ServerError,
    );

    notificationContext.showError('Ошибка при добавлении категории', errors);
  }, [addCategoryError, notificationContext]);

  useEffect(() => {
    if (!updateCategoryError) {
      return;
    }

    const errors = getServerErrorDescriptions(
      updateCategoryError as unknown as ServerError,
    );

    notificationContext.showError('Ошибка при обновлении категории', errors);
  }, [updateCategoryError, notificationContext]);

  return {
    addCategory,
    isCategoryAdding,
    addCategoryError,
    isAddCategorySuccess,
    deleteCategory,
    isCategoryDeleting,
    deleteCategoryError,
    isDeleteCategorySuccess,
    updateCategory,
    isCategoryUpdating,
    updateCategoryError,
    isUpdateCategorySuccess,
  };
}

export default useEditCategory;
